<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>
    <!-- 背景图 -->
    <div class="com-bj">
      <img
        v-lazy="imageUrl"
        class="com-bj"
        src="../../pc-imgs/com-bj.png"
        alt=""
        @click="consult"
        style="cursor: pointer"
      />
    </div>
    <!-- 2024国际竞赛班早知 -->
    <div class="com-js-zz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="com-js-zz-title">
            <img class="com-js-zz-title-img" src="../../pc-imgs/com-js-zz-title.png" alt="" />
          </div>
          <div class="com-js-zz-but">
            <div
              @click="comJsZzFun1"
              class="com-js-zz-but-small"
              :style="{
                backgroundColor: comJsZz1 ? '#408cfeFF' : '#fff',
                color: comJsZz1 ? '#fff' : '#000',
                cursor: comJsZz1 ? '' : 'pointer'
              }"
            >
              9/10/11年级
            </div>
            <div
              @click="comJsZzFun2"
              class="com-js-zz-but-small"
              :style="{
                backgroundColor: comJsZz2 ? '#408cfeFF' : '#fff',
                color: comJsZz2 ? '#fff' : '#000',
                cursor: comJsZz2 ? '' : 'pointer'
              }"
            >
              11年级
            </div>
            <div
              @click="comJsZzFun3"
              class="com-js-zz-but-small"
              :style="{
                backgroundColor: comJsZz3 ? '#408cfeFF' : '#fff',
                color: comJsZz3 ? '#fff' : '#000',
                cursor: comJsZz3 ? '' : 'pointer'
              }"
            >
              12年级
            </div>
            <div
              @click="comJsZzFun4"
              class="com-js-zz-but-small"
              :style="{
                backgroundColor: comJsZz4 ? '#408cfeFF' : '#fff',
                color: comJsZz4 ? '#fff' : '#000',
                cursor: comJsZz4 ? '' : 'pointer'
              }"
            >
              13年级
            </div>
          </div>
          <img class="com-js-zz-img" v-show="comJsZz1" src="../../pc-imgs/com-js-zz1.png" alt="" />
          <img class="com-js-zz-img" v-show="comJsZz2" src="../../pc-imgs/com-bh-why2.png" alt="" />
          <img class="com-js-zz-img" v-show="comJsZz3" src="../../pc-imgs/com-bh-why3.png" alt="" />
          <img class="com-js-zz-img" v-show="comJsZz4" src="../../pc-imgs/com-bh-why4.png" alt="" />
          <div class="com-js-zz-bottom">
            <img
              class="com-mobile-but"
              src="../../pc-imgs/com-mobile-but.png"
              alt=""
              @click="consult"
              style="cursor: pointer"
            />
            <img
              class="com-mobile-but"
              src="../../pc-imgs/com-zx-but.png"
              alt=""
              @click="consult"
              style="cursor: pointer"
            />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 为什么要参加国际竞赛 -->
    <div class="com-why-cj">
      <img class="com-why-cj" src="../../pc-imgs/com-why-cj.png" alt="" />
      <div class="com-why-cj-but1" style="cursor: pointer" @click="consult"></div>
      <div class="com-why-cj-but2" style="cursor: pointer" @click="consult"></div>
    </div>
    <!-- 不同专业方向竞赛推荐 -->
    <div class="com-bt-zy">
      <img class="com-bt-zy-img" src="../../pc-imgs/com-bt-zy.png" alt="" />
      <div class="com-bt-zy-but1" style="cursor: pointer" @click="consult"></div>
      <div class="com-bt-zy-but2" style="cursor: pointer" @click="consult"></div>
    </div>
    <!-- 国际竞赛考试日历 -->
    <div class="com-ks-rl">
      <img class="com-ks-rl" src="../../pc-imgs/com-ks-rl.png" alt="" />
      <div class="com-ks-rl-but1" style="cursor: pointer" @click="consult"></div>
      <div class="com-ks-rl-but2" style="cursor: pointer" @click="consult"></div>
    </div>
    <!-- 半海国际竞赛为什么专业 -->
    <el-row type="flex">
      <el-col :span="6"></el-col>
      <el-col :span="12">
        <div class="com-bh-why-zy">
          <div class="com-bh-why-zy-title">半海国际竞赛为什么专业？</div>
        </div>
        <div class="com-bh-why-zy-but">
          <div
            @click="comBhWhyFun1"
            class="com-bh-why-zy-but1"
            :style="{
              backgroundColor: comBhWhy1 ? '#408cfeFF' : '#fff',
              color: comBhWhy1 ? '#fff' : '#333',
              cursor: comBhWhy1 ? '' : 'pointer'
            }"
          >
            长周期规划
          </div>
          <div
            @click="comBhWhyFun2"
            class="com-bh-why-zy-but2"
            :style="{
              backgroundColor: comBhWhy2 ? '#408cfeFF' : '#fff',
              color: comBhWhy2 ? '#fff' : '#333',
              cursor: comBhWhy2 ? '' : 'pointer'
            }"
          >
            规划范服务
          </div>
        </div>
        <img class="com-bh-why" v-show="comBhWhy1" src="../../pc-imgs/com-bh-why1.png" alt="" />
        <img class="com-bh-why" v-show="comBhWhy2" src="../../pc-imgs/com-bh-why12.png" alt="" />

        <div class="com-bh-why-bottom">
          <img
            class="com-mobile-but"
            src="../../pc-imgs/com-mobile-but.png"
            alt=""
            @click="consult"
            style="cursor: pointer"
          />
          <img
            class="com-mobile-but"
            src="../../pc-imgs/com-zx-but.png"
            alt=""
            @click="consult"
            style="cursor: pointer"
          />
        </div>
      </el-col>
      <el-col :span="6"></el-col>
    </el-row>
    <!-- 半海国际竞赛课程设置 -->
    <div class="pc-com-kc-sz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-com-kc-sz-title">
            <div class="pc-com-kc-sz-title-top">半海国际竞赛课程设置</div>
            <div class="pc-com-kc-sz-title-bottom">竞赛课程设置齐全 涵盖英联邦及北美主流热门竞赛</div>
          </div>
          <div class="pc-com-kc-sz-but">
            <div
              @click="comKcSzFun1"
              class="pc-com-kc-sz-but-small"
              :style="{
                backgroundColor: comKcSz1 ? '#fff' : '',
                color: comKcSz1 ? '#4188f5FF' : '#fff',
                cursor: comKcSz1 ? '' : 'pointer'
              }"
            >
              英联邦双语竞赛
            </div>
            <div
              @click="comKcSzFun2"
              class="pc-com-kc-sz-but-small"
              :style="{
                backgroundColor: comKcSz2 ? '#fff' : '',
                color: comKcSz2 ? '#4188f5FF' : '#fff',
                cursor: comKcSz2 ? '' : 'pointer'
              }"
            >
              北美双语竞赛
            </div>
          </div>
          <div class="pc-com-design-router" v-show="comKcSz1">
            <div class="pc-com-design-router-title">
              <div
                @click="desRouterFun1"
                class="pc-com-design-router1"
                :style="{
                  color: des1Rout1 ? '#408CFEFF' : '#333333FF',
                  cursor: des1Rout1 ? ' ' : 'pointer',
                  marginLeft: '33px'
                }"
              >
                STEP
                <div class="pc-com-design-hx" v-show="des1Rout1"></div>
              </div>
              <div
                @click="desRouterFun2"
                class="pc-com-design-router1"
                :style="{color: des1Rout2 ? '#408CFEFF' : '#333333FF', cursor: des1Rout2 ? ' ' : 'pointer'}"
              >
                MAT
                <div class="pc-com-design-hx1" v-show="des1Rout2"></div>
              </div>
              <div
                @click="desRouterFun3"
                class="pc-com-design-router1"
                :style="{color: des1Rout3 ? '#408CFEFF' : '#333333FF', cursor: des1Rout3 ? ' ' : 'pointer'}"
              >
                PAT
                <div class="pc-com-design-hx1" v-show="des1Rout3"></div>
              </div>
              <div
                @click="desRouterFun4"
                class="pc-com-design-router1"
                :style="{color: des1Rout4 ? '#408CFEFF' : '#333333FF', cursor: des1Rout4 ? ' ' : 'pointer'}"
              >
                BBO
                <div class="pc-com-design-hx1" v-show="des1Rout4"></div>
              </div>
              <div
                @click="desRouterFun5"
                class="pc-com-design-router1"
                :style="{color: des1Rout5 ? '#408CFEFF' : '#333333FF', cursor: des1Rout5 ? ' ' : 'pointer'}"
              >
                BPHO
                <div class="pc-com-design-hx" v-show="des1Rout5"></div>
              </div>
              <div
                @click="desRouterFun6"
                class="pc-com-design-router1"
                :style="{color: des1Rout6 ? '#408CFEFF' : '#333333FF', cursor: des1Rout6 ? ' ' : 'pointer'}"
              >
                BMO
                <div class="pc-com-design-hx" v-show="des1Rout6"></div>
              </div>
              <div
                @click="desRouterFun7"
                class="pc-com-design-router1"
                :style="{
                  color: des1Rout7 ? '#408CFEFF' : '#333333FF',
                  cursor: des1Rout7 ? ' ' : 'pointer',
                  marginRight: '33px'
                }"
              >
                UKCHO
                <div class="pc-com-design-hx3" v-show="des1Rout7"></div>
              </div>
            </div>
            <img class="pc-des-rou-img" v-show="des1Rout1" src="../../pc-imgs/des-rou-img1.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout2" src="../../pc-imgs/des-rou-img2.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout3" src="../../pc-imgs/des-rou-img3.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout4" src="../../pc-imgs/des-rou-img4.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout5" src="../../pc-imgs/des-rou-img5.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout6" src="../../pc-imgs/des-rou-img6.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout7" src="../../pc-imgs/des-rou-img7.png" alt="" />
          </div>
          <div class="pc-com-design-router" v-show="comKcSz2">
            <div class="pc-com-design-router-title">
              <div
                @click="desRouterFun1"
                class="pc-com-design-router1"
                :style="{
                  color: des1Rout1 ? '#408CFEFF' : '#333333FF',
                  cursor: des1Rout1 ? ' ' : 'pointer',
                  marginLeft: '33px'
                }"
              >
                AMC
                <div class="pc-com-design-hx" v-show="des1Rout1"></div>
              </div>
              <div
                @click="desRouterFun2"
                class="pc-com-design-router2"
                :style="{color: des1Rout2 ? '#408CFEFF' : '#333333FF', cursor: des1Rout2 ? ' ' : 'pointer'}"
              >
                USABO
                <div class="pc-com-design-hx3" v-show="des1Rout2"></div>
              </div>
              <div
                @click="desRouterFun3"
                class="pc-com-design-router2"
                :style="{color: des1Rout3 ? '#408CFEFF' : '#333333FF', cursor: des1Rout3 ? ' ' : 'pointer'}"
              >
                USACO
                <div class="pc-com-design-hx3" v-show="des1Rout3"></div>
              </div>
              <div
                @click="desRouterFun4"
                class="pc-com-design-router2"
                :style="{color: des1Rout4 ? '#408CFEFF' : '#333333FF', cursor: des1Rout4 ? ' ' : 'pointer'}"
              >
                USNCO
                <div class="pc-com-design-hx3" v-show="des1Rout4"></div>
              </div>
              <div
                @click="desRouterFun5"
                class="pc-com-design-router2"
                :style="{color: des1Rout5 ? '#408CFEFF' : '#333333FF', cursor: des1Rout5 ? ' ' : 'pointer'}"
              >
                Physics Bowl
                <div class="pc-com-design-hx3" v-show="des1Rout5"></div>
              </div>
              <div
                @click="desRouterFun6"
                class="pc-com-design-router2"
                :style="{color: des1Rout6 ? '#408CFEFF' : '#333333FF', cursor: des1Rout6 ? ' ' : 'pointer'}"
              >
                GAUSS
                <div class="pc-com-design-hx3" v-show="des1Rout6"></div>
              </div>
              <div
                @click="desRouterFun7"
                class="pc-com-design-router2"
                :style="{
                  color: des1Rout7 ? '#408CFEFF' : '#333333FF',
                  cursor: des1Rout7 ? ' ' : 'pointer',
                  marginRight: '33px'
                }"
              >
                EUCLID
                <div class="pc-com-design-hx3" v-show="des1Rout7"></div>
              </div>
            </div>
            <img class="pc-des-rou-img" v-show="des1Rout1" src="../../pc-imgs/com-des2-img1.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout2" src="../../pc-imgs/com-des2-img2.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout3" src="../../pc-imgs/com-des2-img3.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout4" src="../../pc-imgs/com-des2-img4.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout5" src="../../pc-imgs/com-des2-img5.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout6" src="../../pc-imgs/com-des2-img6.png" alt="" />
            <img class="pc-des-rou-img" v-show="des1Rout7" src="../../pc-imgs/com-des2-img7.png" alt="" />
          </div>
          <div class="pc-com-design-bottom">
            <img
              class="pc-com-mobile-but"
              src="../../pc-imgs/com-mobile-but.png"
              alt=""
              @click="consult"
              style="cursor: pointer"
            />
            <img
              class="pc-com-mobile-but"
              src="../../pc-imgs/com-zx-but.png"
              alt=""
              @click="consult"
              style="cursor: pointer"
            />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 教学团队 -->
    <div class="com-teacher">
      <img class="com-teacher-img" src="../../pc-imgs/teacher.png" alt="" />
    </div>
    <!-- 半海国际竞赛课程设置 -->
    <div class="com-bh-js-sz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="com-bh-js-sz-title">
            <img class="com-bh-js-sz-title-img" src="../../pc-imgs/com-bh-js-sz-title-img.png" alt="" />
          </div>
          <div class="com-bh-js-sz-router">
            <div
              @click="ndtFun1"
              class="com-bh-js-sz-router-small"
              :style="{
                color: ndRout1 ? '#fff' : '#000',
                backgroundColor: ndRout1 ? '#408cfeFF' : '',
                cursor: ndRout1 ? '' : 'pointer'
              }"
            >
              国际数学竞赛
            </div>
            <div
              @click="ndtFun2"
              class="com-bh-js-sz-router-small"
              :style="{
                color: ndRout2 ? '#fff' : '#000',
                backgroundColor: ndRout2 ? '#408cfeFF' : '',
                cursor: ndRout2 ? '' : 'pointer'
              }"
            >
              国际物理竞赛
            </div>
            <div
              @click="ndtFun3"
              class="com-bh-js-sz-router-small"
              :style="{
                color: ndRout3 ? '#fff' : '#000',
                backgroundColor: ndRout3 ? '#408cfeFF' : '',
                cursor: ndRout3 ? '' : 'pointer'
              }"
            >
              国际化学竞赛
            </div>
            <div
              @click="ndtFun4"
              class="com-bh-js-sz-router-small"
              :style="{
                color: ndRout4 ? '#fff' : '#000',
                backgroundColor: ndRout4 ? '#408cfeFF' : '',
                cursor: ndRout4 ? '' : 'pointer'
              }"
            >
              国际生物竞赛
            </div>
            <div
              @click="ndtFun5"
              class="com-bh-js-sz-router-small"
              :style="{
                color: ndRout5 ? '#fff' : '#000',
                backgroundColor: ndRout5 ? '#408cfeFF' : '',
                cursor: ndRout5 ? '' : 'pointer'
              }"
            >
              国际经济竞赛
            </div>
          </div>
          <img class="com-bh-js-sz-nd" v-show="ndRout1" src="../../pc-imgs/com-bh-js-sz-nd1.png" alt="" />
          <img class="com-bh-js-sz-nd" v-show="ndRout2" src="../../pc-imgs/com-bh-js-sz-nd2.png" alt="" />
          <img class="com-bh-js-sz-nd" v-show="ndRout3" src="../../pc-imgs/com-bh-js-sz-nd3.png" alt="" />
          <img class="com-bh-js-sz-nd" v-show="ndRout4" src="../../pc-imgs/com-bh-js-sz-nd4.png" alt="" />
          <img class="com-bh-js-sz-nd" v-show="ndRout5" src="../../pc-imgs/com-bh-js-sz-nd5.png" alt="" />
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海国际竞赛服务流程 -->
    <div class="pc-com-bh-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <img class="pc-com-fu-img" src="../../pc-imgs/com-fu.png" alt="" />
          <div class="pc-com-fu-but1" @click="consult"></div>
          <div class="pc-com-fu-but2" @click="consult"></div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海竞赛怎么学 -->
    <img class="com-zmx" src="../../pc-imgs/com-zmx.png" alt="" />
    <!-- 半海国际校区环境 -->
    <div class="com-bh-hj">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <img class="com-bh-hj-img" src="../../pc-imgs/com-bh-hj.png" alt="" />
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
import Footer from '../../components/PcFooter.vue';

export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      comJsZz1: true,
      comJsZz2: false,
      comJsZz3: false,
      comJsZz4: false,
      comBhWhy1: true,
      comBhWhy2: false,
      comKcSz1: true,
      comKcSz2: false,
      des1Rout1: true,
      des1Rout2: false,
      des1Rout3: false,
      des1Rout4: false,
      des1Rout5: false,
      des1Rout6: false,
      des1Rout7: false,
      ndRout1: true,
      ndRout2: false,
      ndRout3: false,
      ndRout4: false,
      ndRout5: false
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    comJsZzFun1() {
      this.comJsZz1 = true;
      this.comJsZz2 = false;
      this.comJsZz3 = false;
      this.comJsZz4 = false;
    },
    comJsZzFun2() {
      this.comJsZz1 = false;
      this.comJsZz2 = true;
      this.comJsZz3 = false;
      this.comJsZz4 = false;
    },
    comJsZzFun3() {
      this.comJsZz1 = false;
      this.comJsZz2 = false;
      this.comJsZz3 = true;
      this.comJsZz4 = false;
    },
    comJsZzFun4() {
      this.comJsZz1 = false;
      this.comJsZz2 = false;
      this.comJsZz3 = false;
      this.comJsZz4 = true;
    },
    comBhWhyFun1() {
      this.comBhWhy1 = true;
      this.comBhWhy2 = false;
    },
    comBhWhyFun2() {
      this.comBhWhy1 = false;
      this.comBhWhy2 = true;
    },
    comKcSzFun1() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
      this.comKcSz1 = true;
      this.comKcSz2 = false;
    },
    comKcSzFun2() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
      this.comKcSz1 = false;
      this.comKcSz2 = true;
    },
    desRouterFun1() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun2() {
      this.des1Rout1 = false;
      this.des1Rout2 = true;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun3() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = true;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun4() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = true;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun5() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = true;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun6() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = true;
      this.des1Rout7 = false;
    },
    desRouterFun7() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = true;
    },
    ndtFun1() {
      this.ndRout1 = true;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },
    ndtFun2() {
      this.ndRout1 = false;
      this.ndRout2 = true;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },
    ndtFun3() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = true;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },

    ndtFun4() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = true;
      this.ndRout5 = false;
    },
    ndtFun5() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = true;
    }
  }
};
</script>

<style lang="less">
.com-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
}
.com-js-zz {
  width: 100%;
  margin-top: 0.352857rem;
  padding-top: 0.285714rem /* 40/140 */;
  padding-left: 0.285714rem /* 40/140 */;
  .com-js-zz-title {
    display: flex;
    width: 100%;
    height: 0.557143rem /* 78/140 */;
    align-items: center;
    justify-content: center;
    .com-js-zz-title-img {
      width: 2.692857rem /* 377/140 */;
      height: 0.557143rem /* 78/140 */;
      margin-bottom: 0.214286rem /* 30/140 */;
    }
  }
  .com-js-zz-but {
    height: 0.3rem /* 42/140 */;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.142857rem /* 20/140 */;
    .com-js-zz-but-small {
      width: 1.128571rem /* 172/140 */;
      height: 0.3rem /* 42/140 */;
      text-align: center;
      line-height: 0.3rem /* 42/140 */;
      border: 0.007143rem /* 1/140 */ solid #408cfeff;
      // font-size: 12px;
    }
  }
  .com-js-zz-img {
    width: 100%;
    margin-bottom: 0.142857rem /* 20/140 */;
  }
  .com-js-zz-bottom {
    width: 100%;
    height: 0.285714rem /* 40/140 */;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.285714rem /* 40/140 */;
    .com-mobile-but {
      width: 1.285714rem /* 180/140 */;
      height: 0.257143rem /* 36/140 */;
    }
  }
}
.com-why-cj {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .com-why-cj-but1 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91.2%;
    left: 34.1%;
  }
  .com-why-cj-but2 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91.2%;
    left: 53.5%;
  }
}
.com-bt-zy {
  padding-top: 0.142857rem /* 20/140 */;
  padding-bottom: 0.142857rem /* 20/140 */;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .com-bt-zy-img {
    height: 3.857143rem /* 540/140 */;
  }
  .com-bt-zy-but1 {
    position: absolute;
    height: 0.285714rem /* 40/140 */;
    width: 1.142857rem /* 160/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 90%;
    left: 37%;
  }
  .com-bt-zy-but2 {
    position: absolute;
    height: 0.285714rem /* 40/140 */;
    width: 1.142857rem /* 160/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 90%;
    left: 52.5%;
  }
}
.com-ks-rl {
  position: relative;
  width: 100%;
  height: auto;
  .com-ks-rl-but1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 88%;
    left: 34.1%;
  }
  .com-ks-rl-but2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 88%;

    left: 53.5%;
  }
}
.com-bh-why-zy {
  width: 100%;
  .com-bh-why-zy-title {
    width: 100%;
    height: 0.771429rem /* 108/140 */;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: HelloFont, HelloFont;
    font-weight: bold;
    font-size: 0.228571rem /* 32/140 */;
    color: #333333;
  }
}
.com-bh-why-zy-but {
  height: 0.485714rem /* 68/140 */;
  display: flex;
  width: 100%;
  margin-bottom: 0.142857rem /* 20/140 */;
  line-height: 0.485714rem /* 68/140 */;
  .com-bh-why-zy-but1 {
    flex: 1;
    box-shadow: 0.014286rem /* 2/140 */ 0.014286rem /* 2/140 */ 0.035714rem /* 5/140 */ 0.014286rem /* 2/140 */
      #e4ecffff;
    text-align: center;
    border-radius: 0.142857rem /* 20/140 */;
  }
  .com-bh-why-zy-but2 {
    flex: 1;
    box-shadow: 0.014286rem /* 2/140 */ 0.014286rem /* 2/140 */ 0.035714rem /* 5/140 */ 0.014286rem /* 2/140 */
      #e4ecffff;
    text-align: center;
    border-radius: 0.142857rem /* 20/140 */;
  }
}
.com-bh-why {
  width: 100%;
  margin-bottom: 0.142857rem /* 20/140 */;
}
.com-bh-why-bottom {
  width: 100%;
  height: 0.285714rem /* 40/140 */;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.285714rem /* 40/140 */;
  .com-mobile-but {
    width: 1.285714rem /* 180/140 */;

    height: 0.257143rem /* 36/140 */;
  }
}
.pc-com-kc-sz {
  width: 100%;
  padding-top: 0.214286rem /* 30/140 */;
  padding-bottom: 0.142857rem /* 20/140 */;
  background-color: #448effff;
  .pc-com-kc-sz-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pc-com-kc-sz-title-top {
      font-family: HelloFont, HelloFont;
      font-weight: normal;
      font-size: 0.228571rem /* 32/140 */;
      color: #ffffff;
      line-height: 0.285714rem /* 40/140 */;
      text-align: center;
      font-style: bold;
      margin-bottom: 0.071429rem /* 10/140 */;
    }
    .pc-com-kc-sz-title-bottom {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.142857rem /* 20/140 */;
      color: rgba(255, 255, 255, 0.8);
      line-height: 0.2rem /* 28/140 */;
      text-align: center;
      font-style: normal;
    }
  }

  .pc-com-kc-sz-but {
    width: 80%;
    height: 0.5rem /* 70/140 */;
    display: flex;
    margin-top: 0.142857rem /* 20/140 */;
    padding-left: 0.5rem /* 70/140 */;
    padding-right: 0.5rem /* 70/140 */;
    justify-content: space-evenly;
    font-size: 0.128571rem /* 18/140 */;
    .pc-com-kc-sz-but-small {
      width: 1.285714rem /* 180/140 */;
      height: 0.314286rem /* 44/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      border: 0.007143rem /* 1/140 */ solid #fff;
      text-align: center;
      line-height: 0.314286rem /* 44/140 */;
    }
  }
  .pc-com-design-router {
    width: 100%;
    font-size: 0.114286rem /* 16/140 */;
    .pc-com-design-router-title {
      width: 100%;
      height: 0.357143rem /* 50/140 */;
      display: flex;
      align-items: center;
      // padding-right: 33px;
      // padding-left: 33px;
      background-color: #f5f5f5ff;
      justify-content: space-between;
      border-radius: 0.071429rem /* 10/140 */ 0.071429rem /* 10/140 */ 0px 0px;
    }
    .pc-com-design-hx {
      height: 0.021429rem /* 3/140 */;
      width: 100%;
      margin-top: 0.021429rem /* 3/140 */;
      background-color: #408cfeff;
    }
    .pc-com-design-hx1 {
      height: 0.021429rem /* 3/140 */;
      width: 100%;
      margin-top: 0.021429rem /* 3/140 */;
      background-color: #408cfeff;
    }
    .pc-com-design-hx3 {
      height: 0.021429rem /* 3/140 */;
      width: 100%;
      margin-top: 0.021429rem /* 3/140 */;
      background-color: #408cfeff;
    }
    .pc-des-rou-img {
      width: 100%;
    }
  }
  .pc-com-design-bottom {
    width: 100%;
    height: 0.285714rem /* 40/140 */;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.071429rem /* 10/140 */;
    margin-top: 0.142857rem /* 20/140 */;
    .pc-com-mobile-but {
      width: 1.285714rem /* 180/140 */;
      height: 0.257143rem /* 36/140 */;
    }
  }
}
.com-teacher {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.207143rem /* 729/140 */;
  width: 100%;
  .com-teacher-img {
    height: 4.464286rem /* 625/140 */;
  }
}
.com-bh-js-sz {
  width: 100%;
  padding-top: 0.242857rem /* 34/140 */;
  padding-bottom: 0.171429rem /* 24/140 */;
  background-color: #ddeaffff;
  .com-bh-js-sz-title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.235714rem /* 33/140 */;
    .com-bh-js-sz-title-img {
      width: 2.264286rem /* 317/140 */;
    }
  }
  .com-bh-js-sz-router {
    width: 100%;
    height: 0.485714rem /* 68/140 */;
    background-color: #fff;
    border-radius: 0.142857rem /* 20/140 */;
    margin-bottom: 0.085714rem /* 12/140 */;
    line-height: 0.485714rem /* 68/140 */;
    display: flex;
    .com-bh-js-sz-router-small {
      flex: 1;
      text-align: center;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      font-size: 0.128571rem /* 18/140 */;
      color: #333333;
      border-radius: 0.142857rem /* 20/140 */;
    }
    .com-bh-js-sz-router-small1 {
      flex: 1;
      text-align: center;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      font-size: 0.128571rem /* 18/140 */;
      color: #333333;
      border-radius: 0.142857rem /* 20/140 */;
      background-image: url('../../pc-imgs/sjdiv.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .com-bh-js-sz-nd {
    width: 100%;
  }
}
.pc-com-bh-fw {
  position: relative;
  height: 2.335714rem /* 383/140 */;
  width: 100%;
  padding-top: 0.285714rem /* 40/140 */;
  margin-top: 0.285714rem /* 40/140 */;
  .pc-com-fu-img {
    width: 100%;
  }
  .pc-com-fu-but1 {
    position: absolute;
    height: 0.214286rem /* 30/140 */;
    width: 1.071429rem /* 150/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
    top: 88%;
    left: 37.5%;
  }
  .pc-com-fu-but2 {
    position: absolute;
    height: 0.214286rem /* 30/140 */;
    width: 1.071429rem /* 150/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
    top: 88%;
    left: 52.5%;
  }
}
.com-zmx {
  width: 100%;
}
.com-bh-hj {
  height: 2.214286rem /* 310/140 */;
  padding-top: 0.285714rem /* 40/140 */;
  .com-bh-hj-img {
    width: 100%;
  }
}
</style>
