import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/vant';
import 'vant/lib/index.css';
import './style/index.css';
import 'amfe-flexible';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload';
Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.use(ElementUI);
Vue.use(VueLazyload);
new Vue({router, store, render: h => h(App)}).$mount('#app');
