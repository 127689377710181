<template>
  <div>
    <van-popup v-model="show" position="right" :style="{height: '100%', width: '30%'}" @close="close">
      <div class="router">
        <router-link :to="{path: '/'}" class="router-link" :class="{'active-link': isActive('/')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>首页</div>
          </div>
        </router-link>

        <router-link :to="{path: '/al'}" class="router-link" :class="{'active-link': isActive('/al')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>A-Level</div>
          </div>
        </router-link>

        <router-link :to="{path: '/ig'}" class="router-link" :class="{'active-link': isActive('/ig')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>IG</div>
          </div>
        </router-link>

        <router-link :to="{path: '/g5'}" class="router-link" :class="{'active-link': isActive('/g5')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>G5</div>
          </div>
        </router-link>
        <router-link :to="{path: '/english'}" class="router-link" :class="{'active-link': isActive('/english')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>雅思</div>
          </div>
        </router-link>

        <router-link
          :to="{path: '/competition'}"
          class="router-link"
          :class="{'active-link': isActive('/competition')}"
        >
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>国际竞赛</div>
          </div>
        </router-link>

        <router-link :to="{path: '/about'}" class="router-link" :class="{'active-link': isActive('/about')}">
          <div class="router-div">
            <div class="router-icon"><van-icon name="wap-home-o" /></div>
            <div>关于我们</div>
          </div>
        </router-link>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      typeof: Boolean,
      default: null
    }
  },

  data() {
    return {};
  },
  methods: {
    isActive(routePath) {
      return this.$route.path === routePath;
    },
    close() {
      this.$emit('showFun', false);
    }
  }
};
</script>

<style>
.router-div {
  display: flex;
  padding-top: 0.540541rem /* 20/37 */;
}
.router {
  padding-top: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  color: #afd5feff;
  padding-left: 0.27027rem /* 10/37 */;
}
.router-icon {
  margin-right: 0.27027rem /* 10/37 */;
}

.active-link {
  /* 添加高亮样式，比如修改链接文本颜色、背景色等 */
  color: #163fe0; /* 举例: 将文本颜色为红色 */
  font-weight: bold;
}
a {
  color: #333333;
}
</style>
