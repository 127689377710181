<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景页面 -->
    <div class="new-ig-bj">
      <img v-lazy="imageUrl" class="new-ig-bj" src="../../pc-imgs/new-ig-bj.png" alt="" @click="consult" />
    </div>
    <!-- IG介绍 -->
    <div class="a">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-ig-js-div">
            <div class="new-ig-js-div-left">
              <div
                @click="alclassFun1"
                class="new-ig-js-div-left-small"
                :style="{
                  background: alclass1 ? '#0049e3FF' : '',
                  color: alclass1 ? '#fff' : '#333333',
                  cursor: alclass1 ? '' : 'pointer'
                }"
              >
                IGCSE介绍
              </div>
              <div
                @click="alclassFun2"
                class="new-ig-js-div-left-small"
                :style="{
                  background: alclass2 ? '#0049e3FF' : '',
                  color: alclass2 ? '#fff' : '#333333',
                  cursor: alclass2 ? '' : 'pointer'
                }"
              >
                IGCSE选课
              </div>
              <div
                @click="alclassFun3"
                class="new-ig-js-div-left-small"
                :style="{
                  background: alclass3 ? '#0049e3FF' : '',
                  color: alclass3 ? '#fff' : '#333333',
                  cursor: alclass3 ? '' : 'pointer'
                }"
              >
                IGCSE和GCSE的关系
              </div>
              <div
                @click="alclassFun4"
                class="new-ig-js-div-left-small"
                :style="{
                  background: alclass4 ? '#0049e3FF' : '',
                  color: alclass4 ? '#fff' : '#333333',
                  cursor: alclass4 ? '' : 'pointer'
                }"
              >
                IGCSE考试局
              </div>
            </div>
            <div class="new-ig-js-div-right">
              <img v-show="alclass1" class="ig-js-img" src="../../pc-imgs/ig-js-img1.png" alt="" />
              <img v-show="alclass2" class="ig-js-img" src="../../pc-imgs/ig-js-img2.png" alt="" />
              <img v-show="alclass3" class="ig-js-img" src="../../pc-imgs/ig-js-img3.png" alt="" />
              <img v-show="alclass4" class="ig-js-img" src="../../pc-imgs/ig-js-img4.png" alt="" />
              <div class="ig-js-img-but" @click="consult"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- IG课程的重要性 -->
    <div class="ig-class-import-div">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <img class="ig-class-import" src="../../pc-imgs/ig-class-import.png" alt="" />
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 英国大学对IG的成绩要求 -->
    <img class="ig-yg-dx-cj-yq" src="../../pc-imgs/ig-yg-dx-cj-yq.png" alt="" />
    <!-- 提交领取 -->
    <div class="ig-tj-lq-div">
      <img class="ig-tj-lq-zl1" src="../../pc-imgs/ig-tj-lq-zl1.png" alt="" />
      <img class="ig-tj-lq-zl2" src="../../pc-imgs/ig-tj-lq-zl2.png" alt="" />
      <img class="ig-tj-lq-zl3" src="../../pc-imgs/ig-tj-lq-zl3.png" alt="" />
      <img class="ig-tj-lq-zl4" src="../../pc-imgs/ig-tj-lq-zl4.png" alt="" />
      <img class="ig-tj-lq-zl5" src="../../pc-imgs/ig-tj-lq-zl5.png" alt="" />
      <img class="ig-tj-lq-zl6" src="../../pc-imgs/ig-tj-lq-zl6.png" alt="" />
      <img class="ig-tj-lq-zl7" src="../../pc-imgs/ig-tj-lq-zl7.png" alt="" />
      <img class="ig-tj-lq-zl8" src="../../pc-imgs/ig-tj-lq-zl8.png" alt="" />
      <input type="text" class="ig-tj-lq-inp1" placeholder="请输入姓名" v-model="form.name" />
      <input type="text" class="ig-tj-lq-inp2" placeholder="请输入电话" v-model="form.mobile" />
      <div class="ig-tj-lq-but" @click="submitForm">提交领取</div>
    </div>
    <!-- IGCSE学习中常见的问题 -->
    <div class="ig-study-question">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ig-study-question-title">
            <img class="ig-study-question-title-img" src="../../pc-imgs/ig-study-question-title.png" alt="" />
          </div>
          <div class="ig-study-question-but">
            <div
              @click="studyQuestionFun1"
              class="ig-study-question-but-samll"
              :style="{
                background: igStudyQuestion1 ? '#004ce0FF' : '#ebf4ffFF',
                color: igStudyQuestion1 ? '#fff' : '#333',
                cursor: igStudyQuestion1 ? '' : 'pointer'
              }"
            >
              关于选课
            </div>
            <div
              @click="studyQuestionFun2"
              class="ig-study-question-but-samll"
              :style="{
                background: igStudyQuestion2 ? '#004ce0FF' : '#ebf4ffFF',
                color: igStudyQuestion2 ? '#fff' : '#333',
                cursor: igStudyQuestion2 ? '' : 'pointer'
              }"
            >
              关于申请
            </div>
          </div>
          <div class="ig-study-question-context">
            <div class="ig-study-question-context-left">
              <img
                class="ig-study-question-context-left-img"
                src="../../pc-imgs/ig-study-question-context-left-img.png"
                alt=""
              />
              <div class="ig-study-question-context-left-but" @click="consult"></div>
            </div>
            <div class="ig-study-question-context-right" v-show="igStudyQuestion1">
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  IGCSE一般要选几门课？
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  IG的选课影响A-Level的选课吗？
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  哪些科目是必选的？哪些科目容易得分？
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  常见的选课组合是哪些？
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  英国G5大学对IG选课有无硬性要求？
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" @click="consult" />
              </div>
            </div>
            <div class="ig-study-question-context-right" v-show="igStudyQuestion2">
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  现在哪些科目比较薄弱?
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  是否需要参加语言培训班?参加哪些培训班?
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  留学专业选择及申请如何规划?
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  除A-Level课程学习外还需背景提升吗?
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
              <div class="ig-study-question-context-right-samll" @click="consult">
                <div>
                  <img class="ig-sjx" src="../../pc-imgs/ig-sjx.png" alt="" />
                  是否需参加夏令营/游学?哪些夏令营/游学?
                </div>
                <img class="ig-study-bue" src="../../pc-imgs/ig-study-bue.png" alt="" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- IGCSE课程安排 -->
    <img class="ig-kc-plan" src="../../pc-imgs/ig-kc-plan.png" alt="" />
    <!-- IGCSE科目设置 -->
    <div class="ig-km-sz">
      <img class="ig-km-sz-img" src="../../pc-imgs/ig-km-sz-img.png" alt="" />
    </div>
    <!-- IGCSE课程设置 -->
    <div class="pc-ig-kc-sz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-ig-kc-sz-title">
            <img class="pc-ig-kc-sz-title-img" src="../../pc-imgs/ig-kc-sz-title.png" alt="" />
          </div>
          <div class="pc-ig-kc-sz-but">
            <div
              @click="igKcSzFun1"
              class="pc-ig-kc-sz-but-small"
              :style="{
                background: igKcSz1 ? '#fcbe77FF' : '',
                cursor: igKcSz1 ? '' : 'pointer',
                marginLeft: '0.428571rem'
              }"
            >
              IGCSE 衔接班
            </div>
            <div
              @click="igKcSzFun2"
              class="pc-ig-kc-sz-but-small"
              :style="{background: igKcSz2 ? '#fcbe77FF' : '', cursor: igKcSz2 ? '' : 'pointer'}"
            >
              IGCSE 提升班
            </div>
            <div
              @click="igKcSzFun3"
              class="pc-ig-kc-sz-but-small"
              :style="{
                background: igKcSz3 ? '#fcbe77FF' : '',
                cursor: igKcSz3 ? '' : 'pointer',
                marginRight: '0.428571rem'
              }"
            >
              IGCSE 冲刺班
            </div>
          </div>
          <img v-show="igKcSz1" class="pc-ig-kc-sz-img" src="../../pc-imgs/igKcSz-img1.png" alt="" />
          <img v-show="igKcSz2" class="pc-ig-kc-sz-img" src="../../pc-imgs/igKcSz-img2.png" alt="" />
          <img v-show="igKcSz3" class="pc-ig-kc-sz-img" src="../../pc-imgs/igKcSz-img3.png" alt="" />
          <div class="pc-ig-kc-sz-bottom-but">
            <img
              class="pc-ig-kc-sz-bottom-but-img"
              src="../../pc-imgs/ig-kc-sz-bottom-but-img.png"
              alt=""
              @click="consult"
            />
            <img
              class="pc-ig-kc-sz-bottom-but-img"
              src="../../pc-imgs/ig-kc-sz-bottom-but-img1.png"
              alt=""
              @click="consult"
            />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 教学团队 -->
    <div class="ig-new-al-teacher">
      <img class="new-al-teacher-img" src="../../pc-imgs/new-al-teacher-img.png" alt="" @click="consult" />
      <div class="new-al-teacher-img-but1" @click="consult"></div>
      <div class="new-al-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 澜大半海优势 -->
    <img class="new-al-landa-bh-ys" src="../../pc-imgs/landa-bh-ys.png" alt="" />
    <!-- 全链路跟踪学习 -->
    <img class="new-ig-gz-study" src="../../pc-imgs/new-ig-gz-study.png" alt="" />
    <!-- 细致入微贴心服务 -->
    <div class="ig-new-al-xz-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ig-new-al-xz-fw-title">
            <img class="ig-new-al-xz-fw-title-img" src="../../pc-imgs/new-al-xz-fw-title.png" alt="" />
          </div>
          <div class="ig-new-al-xz-fw-div">
            <div class="ig-new-al-xz-fw-div1">
              <div class="ig-new-al-xz-fw-div1-small">
                <div class="ig-new-al-xz-fw-div1-small-text">到离校通知家长</div>
              </div>
              <div class="ig-new-al-xz-fw-div2-small">
                <div class="ig-new-al-xz-fw-div1-small-text">测试问题必反馈</div>
              </div>
              <div class="ig-new-al-xz-fw-div3-small">
                <div class="ig-new-al-xz-fw-div1-small-text">上课收手机专心学习</div>
              </div>
            </div>
            <div class="ig-new-al-xz-fw-div1">
              <div class="ig-new-al-xz-fw-div4-small">
                <div class="ig-new-al-xz-fw-div1-small-text">课堂内容详细反馈全面了解</div>
              </div>
              <div class="ig-new-al-xz-fw-div5-small">
                <div class="ig-new-al-xz-fw-div1-small-text">学习记录实时跟踪</div>
              </div>
              <div class="ig-new-al-xz-fw-div6-small">
                <div class="ig-new-al-xz-fw-div1-small-text">课后任务细化快乐学习</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';

export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      alclass1: true,
      alclass2: false,
      alclass3: false,
      alclass4: false,
      igStudyQuestion1: true,
      igStudyQuestion2: false,
      igKcSz1: true,
      igKcSz2: false,
      igKcSz3: false,
      form: {
        name: '',
        mobile: ''
      }
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    alclassFun1() {
      this.alclass1 = true;
      this.alclass2 = false;
      this.alclass3 = false;
      this.alclass4 = false;
    },
    alclassFun2() {
      this.alclass1 = false;
      this.alclass2 = true;
      this.alclass3 = false;
      this.alclass4 = false;
    },
    alclassFun3() {
      this.alclass1 = false;
      this.alclass2 = false;
      this.alclass3 = true;
      this.alclass4 = false;
    },
    alclassFun4() {
      this.alclass1 = false;
      this.alclass2 = false;
      this.alclass3 = false;
      this.alclass4 = true;
    },
    studyQuestionFun1() {
      this.igStudyQuestion1 = true;
      this.igStudyQuestion2 = false;
    },
    studyQuestionFun2() {
      this.igStudyQuestion1 = false;
      this.igStudyQuestion2 = true;
    },
    igKcSzFun1() {
      this.igKcSz1 = true;
      this.igKcSz2 = false;
      this.igKcSz3 = false;
    },
    igKcSzFun2() {
      this.igKcSz1 = false;
      this.igKcSz2 = true;
      this.igKcSz3 = false;
    },
    igKcSzFun3() {
      this.igKcSz1 = false;
      this.igKcSz2 = false;
      this.igKcSz3 = true;
    }
  }
};
</script>

<style lang="less">
.new-ig-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
  // margin-bottom: 38px;
}
.a {
  margin-top: 0.352857rem;
}
.new-ig-js-div {
  display: flex;
  height: 2.614286rem /* 366/140 */;
  margin-bottom: 0.242857rem /* 34/140 */;
  margin-top: 0.214286rem /* 30/140 */;
  .new-ig-js-div-left {
    display: flex;
    flex-direction: column;
    width: 28%;
    margin-right: 0.071429rem /* 10/140 */;
    background-color: #ebf4ffff;
    justify-content: space-evenly;
    .new-ig-js-div-left-small {
      width: 100%;
      height: 0.457143rem /* 64/140 */;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      font-size: 0.128571rem /* 18/140 */;
      color: #333333;
      line-height: 0.457143rem /* 64/140 */;
      text-align: center;
      border-radius: 0.142857rem /* 20/140 */;
    }
  }
  .new-ig-js-div-right {
    position: relative;
    display: flex;
    width: 60%;
    padding-top: 0.285714rem /* 40/140 */;
    .ig-js-img {
      height: 2.228571rem /* 312/140 */;
    }
    .ig-js-img-but {
      position: absolute;
      height: 0.25rem /* 35/140 */;
      width: 0.857143rem /* 120/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 75%;
      left: 47%;
      cursor: pointer;
    }
  }
}
.ig-class-import-div {
  height: auto;
  margin-bottom: 0.142857rem /* 20/140 */;
  .ig-class-import {
    width: 100%;
    height: auto;
  }
}
.ig-yg-dx-cj-yq {
  width: 100%;
  height: auto;
}
.ig-tj-lq-div {
  height: 2.378571rem /* 333/140 */;
  width: 100%;
  background-image: url(../../pc-imgs/ig-tj-lq-bj.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .ig-tj-lq-zl1 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 10%;
    left: 20%;
  }
  .ig-tj-lq-zl2 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 10%;
    left: 31.5%;
  }
  .ig-tj-lq-zl3 {
    position: absolute;
    height: 101px;
    top: 10%;
    left: 43%;
  }
  .ig-tj-lq-zl4 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 10%;
    left: 54.5%;
  }
  .ig-tj-lq-zl5 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 50%;
    left: 21.6%;
  }
  .ig-tj-lq-zl6 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 50%;
    left: 31.5%;
  }
  .ig-tj-lq-zl7 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 50%;
    left: 45%;
  }
  .ig-tj-lq-zl8 {
    position: absolute;
    height: 0.721429rem /* 101/140 */;
    top: 50%;
    left: 56.5%;
  }
  .ig-tj-lq-inp1 {
    position: absolute;
    height: 0.4rem /* 56/140 */;
    border-radius: 0.214286rem /* 30/140 */;
    width: 2.214286rem /* 310/140 */;
    border: 0.007143rem /* 1/140 */ solid #ccc;
    padding-left: 0.142857rem /* 20/140 */;
    top: 15%;
    left: 70%;
    font-size: 0.142857rem /* 20/140 */;
  }
  .ig-tj-lq-inp1:focus {
    border-color: #fff; /* 可以根据需要更改颜色 */
    outline: none; /* 阻止默认的聚焦边框样式 */
  }
  .ig-tj-lq-inp2 {
    position: absolute;
    height: 0.4rem /* 56/140 */;
    border-radius: 0.214286rem /* 30/140 */;
    width: 2.214286rem /* 310/140 */;
    border: 1px solid #ccc;
    padding-left: 0.142857rem /* 20/140 */;
    top: 40%;
    left: 70%;
    font-size: 0.142857rem /* 20/140 */;
  }
  .ig-tj-lq-inp2:focus {
    border-color: #fff; /* 可以根据需要更改颜色 */
    outline: none; /* 阻止默认的聚焦边框样式 */
  }
  .ig-tj-lq-but {
    width: 1.242857rem /* 174/140 */;
    height: 0.4rem /* 56/140 */;
    background: #0049e3;
    box-shadow: 0px 0px 0.028571rem /* 4/140 */ 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.214286rem /* 30/140 */;
    position: absolute;
    top: 64%;
    left: 75%;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 0.114286rem /* 16/140 */;
    color: #ffffff;
    line-height: 0.171429rem /* 24/140 */;
    text-align: left;
    font-style: normal;
    text-align: center;
    line-height: 0.4rem /* 56/140 */;
    cursor: pointer;
  }
}
.ig-study-question {
  height: 3.271429rem /* 458/140 */;
  width: 100%;
  .ig-study-question-title {
    display: flex;
    height: 0.785714rem /* 110/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ig-study-question-title-img {
      height: 0.214286rem /* 30/140 */;
    }
  }
  .ig-study-question-but {
    display: flex;
    justify-content: space-evenly;
    height: 0.428571rem /* 60/140 */;
    font-size: 0.114286rem /* 16/140 */;
    .ig-study-question-but-samll {
      height: 0.285714rem /* 40/140 */;
      width: 1.142857rem /* 160/140 */;
      background-color: #ebf4ffff;
      color: #333333;
      text-align: center;
      line-height: 0.285714rem /* 40/140 */;
      border-radius: 0.142857rem /* 20/140 */;
    }
  }
  .ig-study-question-context {
    width: 100%;
    background-color: #ebf4ffff;
    height: 1.771429rem /* 248/140 */;
    display: flex;
    .ig-study-question-context-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-left: 0.142857rem /* 20/140 */;
      .ig-study-question-context-left-img {
        height: 1.485714rem /* 208/140 */;
      }
      .ig-study-question-context-left-but {
        position: absolute;
        width: 0.657143rem /* 92/140 */;
        height: 0.178571rem /* 25/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        top: 70%;
        left: 10.2%;
        cursor: pointer;
      }
    }
    .ig-study-question-context-right {
      flex: 1.8;
      height: 22px;
      display: flex;
      flex-direction: column;
      padding-top: 0.157143rem /* 22/140 */;
      margin-left: 0.178571rem /* 25/140 */;
      padding-right: 0.107143rem /* 15/140 */;
      // padding-right: 0.214286rem /* 30/140 */;
      cursor: pointer;
      .ig-study-question-context-right-samll {
        display: flex;
        justify-content: space-between;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 0.1rem /* 14/140 */;
        color: #333333;
        line-height: 0.157143rem /* 22/140 */;
        margin-bottom: 0.171429rem /* 24/140 */;
        .ig-sjx {
          height: 0.071429rem /* 10/140 */;
          margin-right: 0.014286rem /* 2/140 */;
        }
        .ig-study-bue {
          height: 0.157143rem /* 22/140 */;
          cursor: pointer;
        }
      }
      .ig-study-question-context-right-samll:hover {
        color: #004ce0ff;
      }
    }
  }
}
.ig-kc-plan {
  height: 1.871429rem /* 262/140 */;
  width: 100%;
}
.ig-km-sz {
  height: 2.985714rem /* 418/140 */;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ig-km-sz-img {
    height: 2.457143rem /* 344/140 */;
  }
}
.pc-ig-kc-sz {
  width: 100%;
  height: auto;
  background-color: #1f62e8ff;
  padding-bottom: 0.142857rem /* 20/140 */;
  .pc-ig-kc-sz-title {
    height: 0.628571rem /* 88/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .pc-ig-kc-sz-title-img {
      height: 0.207143rem /* 29/140 */;
    }
  }
  .pc-ig-kc-sz-but {
    height: 0.485714rem /* 68/140 */;
    width: 5.208571rem /* 760/140 */;
    // padding-left: 0.428571rem /* 60/140 */;
    // padding-right: 0.428571rem /* 60/140 */;
    line-height: 0.485714rem /* 68/140 */;
    display: flex;
    background-color: #fff;
    border-radius: 0.142857rem /* 20/140 */ 0.142857rem /* 20/140 */ 0 0;
    .pc-ig-kc-sz-but-small {
      flex: 1;
      text-align: center;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 0.128571rem /* 18/140 */;
      color: #333333;
    }
  }
  .pc-ig-kc-sz-img {
    // height: auto;
    width: 5.208571rem /* 760/140 */;
    height: auto;
  }
  .pc-ig-kc-sz-bottom-but {
    height: 0.464286rem /* 65/140 */;
    width: 4.342857rem /* 608/140 */;
    display: flex;
    padding-left: 0.364286rem /* 149/140 */;

    justify-content: space-between;
    align-items: center;
    .pc-ig-kc-sz-bottom-but-img {
      height: 0.257143rem /* 36/140 */;
      cursor: pointer;
    }
  }
}
.new-ig-gz-study {
  width: 100%;
  height: auto;
}
.new-al-landa-bh-ys {
  height: auto;
  width: 100%;
}
.ig-new-al-teacher {
  position: relative;
  height: 5.607143rem /* 785/140 */;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .new-al-teacher-img {
    height: 5.107143rem /* 715/140 */;
  }
  .new-al-teacher-img-but1 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 33%;
    cursor: pointer;
  }
  .new-al-teacher-img-but2 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 53%;
    cursor: pointer;
  }
}
.ig-new-al-xz-fw {
  height: 4.471429rem /* 626/140 */;
  // height: auto;
  width: 100%;
  .ig-new-al-xz-fw-title {
    height: 0.642857rem /* 90/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ig-new-al-xz-fw-title-img {
    height: 0.221429rem /* 31/140 */;
  }
  .ig-new-al-xz-fw-div {
    height: 3.571429rem /* 500/140 */;
    width: 100%;
    font-size: 0.114286rem /* 16/140 */;
    .ig-new-al-xz-fw-div1 {
      display: flex;
      width: 100%;
      height: 1.75rem /* 245/140 */;
      margin-bottom: 0.057143rem /* 8/140 */;

      .ig-new-al-xz-fw-div1-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img1.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 25%;
          // transform: translateX(-50%);
        }
      }
      .ig-new-al-xz-fw-div1-small:hover {
        color: #57a0eeff;
      }
      .ig-new-al-xz-fw-div2-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img2.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 25%;
        }
      }
      .ig-new-al-xz-fw-div2-small:hover {
        color: #57a0eeff;
      }
      .ig-new-al-xz-fw-div3-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img3.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 20%;
        }
      }
      .ig-new-al-xz-fw-div3-small:hover {
        color: #57a0eeff;
      }
      .ig-new-al-xz-fw-div4-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img4.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 8%;
        }
      }
      .ig-new-al-xz-fw-div4-small:hover {
        color: #57a0eeff;
      }
      .ig-new-al-xz-fw-div5-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img5.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 22%;
        }
      }
      .ig-new-al-xz-fw-div5-small:hover {
        color: #57a0eeff;
      }
      .ig-new-al-xz-fw-div6-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img6.png);
        background-repeat: no-repeat;
        background-size: cover;
        .ig-new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 18%;
        }
      }
      .ig-new-al-xz-fw-div6-small:hover {
        color: #57a0eeff;
      }
    }
  }
}
</style>
