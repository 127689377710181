<template>
  <div class="pc-narbar-div">
    <div class="pc-narbar">
      <el-row type="flex">
        <el-col :span="5"></el-col>
        <el-col :span="14">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <router-link :to="{path: '/pc'}" class="router-link" :class="{'active-link': isActive('/pc')}">
                首页
                <div class="active-hengxian" v-if="homeDis"></div>
              </router-link>
            </el-col>
            <el-col :span="6"
              ><router-link :to="{path: '/pc/al'}" class="router-link" :class="{'active-link': isActive('/pc/al')}">
                A-Level
                <div class="active-hengxian1" v-if="alDis"></div> </router-link
            ></el-col>
            <el-col :span="7">
              <router-link
                :to="{path: '/pc/allday'}"
                class="router-link"
                :class="{'active-link': isActive('/pc/allday')}"
              >
                AL全日制
                <div class="active-hengxian1" v-if="alldayDis"></div> </router-link
            ></el-col>
            <el-col :span="6">
              <router-link :to="{path: '/pc/ig'}" class="router-link" :class="{'active-link': isActive('/pc/ig')}">
                IGCSE
                <div class="active-hengxian2" v-if="igDis"></div> </router-link
            ></el-col>
            <el-col :span="4"
              ><router-link :to="{path: '/pc/g5'}" class="router-link" :class="{'active-link': isActive('/pc/g5')}">
                G5
                <div class="active-hengxian3" v-if="g5Dis"></div> </router-link
            ></el-col>
            <el-col :span="4"
              ><router-link :to="{path: '/pc/ib'}" class="router-link" :class="{'active-link': isActive('/pc/ib')}">
                IB
                <div class="active-hengxian3" v-if="ibDis"></div> </router-link
            ></el-col>
            <el-col :span="4"
              ><router-link :to="{path: '/pc/ap'}" class="router-link" :class="{'active-link': isActive('/pc/ap')}">
                AP
                <div class="active-hengxian3" v-if="apDis"></div> </router-link
            ></el-col>
            <el-col :span="8"
              ><router-link
                :to="{path: '/pc/competition'}"
                class="router-link"
                :class="{'active-link': isActive('/pc/competition')}"
              >
                国际竞赛
                <div class="active-hengxian4" v-if="comDis"></div> </router-link
            ></el-col>
            <el-col :span="5"
              ><router-link :to="{path: '/pc/tf'}" class="router-link" :class="{'active-link': isActive('/pc/tf')}">
                托福
                <div class="active-hengxian" v-if="testDis"></div> </router-link
            ></el-col>
            <el-col :span="5"
              ><router-link :to="{path: '/pc/ys'}" class="router-link" :class="{'active-link': isActive('/pc/ys')}">
                雅思
                <div class="active-hengxian" v-if="yasiDis"></div> </router-link
            ></el-col>

            <el-col :span="10">
              <router-link
                :to="{path: '/pc/about'}"
                class="router-link"
                :class="{'active-link': isActive('/pc/about')}"
              >
                关于我们
                <div class="active-hengxian4" v-if="aboutDis"></div>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      homeDis: false,
      alDis: false,
      alldayDis: false,
      igDis: false,
      g5Dis: false,
      ibDis: false,
      apDis: false,
      comDis: false,
      testDis: false,
      yasiDis: false,
      aboutDis: false
    };
  },
  methods: {
    isActive(routePath) {
      if (this.$route.path === '/') {
        this.homeDis = true;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/al') {
        this.homeDis = false;
        this.alDis = true;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/allday') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = true;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/ig') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = true;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/g5') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = true;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/ib') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = true;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/ap') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = true;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/intertation') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = true;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/tuofu') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = true;
        this.yasiDis = false;
        this.aboutDis = false;
      } else if (this.$route.path === '/yasi') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = true;
        this.aboutDis = false;
      } else if (this.$route.path === '/about') {
        this.homeDis = false;
        this.alDis = false;
        this.alldayDis = false;
        this.igDis = false;
        this.g5Dis = false;
        this.ibDis = false;
        this.apDis = false;
        this.comDis = false;
        this.testDis = false;
        this.yasiDis = false;
        this.aboutDis = true;
      }
      return this.$route.path === routePath;
    }
  }
};
</script>

<style lang="less" scoped>
.pc-narbar-div {
  display: flex;
  justify-content: space-between;
  font-size: 0.114286rem /* 16/140 */;
  .pc-narbar {
    margin-top: 0.342857rem /* 48/140 */;
    position: fixed;
    z-index: 999;
    width: 100%; /* 导航栏宽度占满整个页面 */
    height: 0.271429rem /* 38/140 */;
    line-height: 0.285714rem /* 40/140 */;
    background-color: #fff;
  }
  .active-link {
    /* 添加高亮样式，比如修改链接文本颜色、背景色等 */
    color: #163fe0; /* 举例: 将文本颜色为红色 */
  }
  a {
    color: #333333;
    text-decoration: none;
  }
  .active-decoration {
    color: #163fe0;
    font-size: 0.142857rem /* 20/140 */;
    font-weight: 900;
  }
  .active-hengxian {
    margin-top: -0.071429rem /* 10/140 */;
    background-color: #163fe0;
    height: 0.014286rem /* 2/140 */;
    width: 0.214286rem /* 30/140 */;
  }
  .active-hengxian1 {
    margin-top: -0.071429rem /* 10/140 */;
    background-color: #163fe0;
    height: 0.014286rem /* 2/140 */;
    width: 0.428571rem /* 60/140 */;
  }
  .active-hengxian2 {
    margin-top: -0.071429rem /* 10/140 */;
    background-color: #163fe0;
    height: 0.014286rem /* 2/140 */;
    width: 0.357143rem /* 50/140 */;
  }
  .active-hengxian3 {
    margin-top: -0.071429rem /* 10/140 */;
    background-color: #163fe0;
    height: 0.014286rem /* 2/140 */;
    width: 0.142857rem /* 20/140 */;
  }
  .active-hengxian4 {
    margin-top: -0.071429rem /* 10/140 */;
    background-color: #163fe0;
    height: 0.014286rem /* 2/140 */;
    width: 0.464286rem /* 65/140 */;
  }
  .a {
    color: #333;
  }
}
</style>
