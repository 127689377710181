<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <!-- 背景图 -->
    <div class="allday-bg">
      <img class="allday-bg" src="../../assets/allday-bj.png" alt="" @click="consult" />
    </div>
    <!-- 脱产A-Level课程适合那些人 -->
    <div class="allday-kc">
      <img class="allday-kc-img" src="../../assets/allday-kc.png" alt="" />
      <div class="allday-kc-but" @click="consult"></div>
    </div>
    <!-- 课程认可度 -->
    <div class="allday-class-rk">
      <img class="allday-class-rk-logo" src="../../assets/allday-class-rk-logo.png" alt="" />
      <div class="allday-class-rk-bt">
        <div
          class="allday-class-rk-bt1"
          :style="{backgroundColor: ygBut ? '#585ffeFF' : '#fff', color: ygBut ? '#fff' : '#000'}"
          @click="toYg"
        >
          英国大学
        </div>
        <div
          class="allday-class-rk-bt1"
          :style="{backgroundColor: mgBut ? '#585ffeFF' : '#fff', color: mgBut ? '#fff' : '#000'}"
          @click="toMg"
        >
          美国大学
        </div>
        <div
          class="allday-class-rk-bt1"
          :style="{backgroundColor: xgBut ? '#585ffeFF' : '#fff', color: xgBut ? '#fff' : '#000'}"
          @click="toXg"
        >
          澳/新/香港
        </div>
      </div>
      <img v-show="ygBut" class="allday-class-rk-img" src="../../assets/al-kcrk-img1.png" alt="" />
      <img v-show="mgBut" class="allday-class-rk-img" src="../../assets/al-class-rk-img2.png" alt="" />
      <img v-show="xgBut" class="allday-class-rk-img" src="../../assets/al-class-rk-img3.png" alt="" />
    </div>
    <!-- 课程优势 -->
    <div class="allday-kc-ys">
      <img class="allday-kc-ys-img" src="../../assets/allday-kc-ys-img.png" alt="" />
      <div class="allday-kc-ys-but" @click="consult"></div>
    </div>
    <!-- 为何选择国内的A-Level脱产 -->
    <div class="allday-why">
      <img class="allday-why-img" src="../../assets/allday-why1.png" alt="" />
    </div>
    <!-- 半海A-Level脱产课程设置 -->
    <div class="allday-kc-sz">
      <img class="allday-kc-sz-title" src="../../assets/allday-kc-sz-title.png" alt="" />
      <div class="allday-kc-sz-but">
        <div
          @click="kcSzFun1"
          class="allday-kc-sz-but-small"
          :style="{backgroundColor: kcSz1 ? '#5768ecFF' : '#fff', color: kcSz1 ? '#fff' : '#333333FF'}"
        >
          1年脱产
        </div>
        <div
          @click="kcSzFun2"
          class="allday-kc-sz-but-small"
          :style="{backgroundColor: kcSz2 ? '#5768ecFF' : '#fff', color: kcSz2 ? '#fff' : '#333333FF'}"
        >
          1.5年脱产
        </div>
        <div
          @click="kcSzFun3"
          class="allday-kc-sz-but-small"
          :style="{backgroundColor: kcSz3 ? '#5768ecFF' : '#fff', color: kcSz3 ? '#fff' : '#333333FF'}"
        >
          2年脱产
        </div>
      </div>
      <img v-show="kcSz1" class="allday-kc-sz-img" src="../../assets/allday-kc-sz-img1.png" alt="" />
      <img v-show="kcSz2" class="allday-kc-sz-img" src="../../assets/allday-kc-sz-img2.png" alt="" />
      <img v-show="kcSz3" class="allday-kc-sz-img" src="../../assets/allday-kc-sz-img3.png" alt="" />
      <div class="allday-kc-sz-zs">注：以上内容仅供参考，详情咨询老师</div>
      <div class="allday-kc-sz-bottom-but">
        <img class="allday-kc-sz-mobile" src="../../assets/allday-mobile-but.png" alt="" @click="callMobile" />
        <img class="allday-kc-sz-mobile" src="../../assets/allday-zx-but.png" alt="" @click="consult" />
      </div>
    </div>
    <!-- A-Level脱产课程目标 -->
    <div class="allday-mb-div">
      <img class="allday-mb" src="../../assets/allday-mb-new.png" alt="" />
    </div>
    <!-- 英国本科留学所需条件 -->
    <div class="allday-yg-tj">
      <img class="allday-yg-tj-img" src="../../assets/allday-yg-tj.png" alt="" />
      <div class="allday-yg-tj-div1" @click="consult"></div>
      <div class="allday-yg-tj-div2" @click="consult"></div>
      <div class="allday-yg-tj-div3" @click="consult"></div>
      <div class="allday-yg-tj-div4" @click="consult"></div>
      <div class="allday-yg-tj-div5" @click="consult"></div>
      <div class="allday-yg-tj-but" @click="consult"></div>
    </div>
    <!-- 涵盖所有笔/面试/背景提升辅导 -->
    <div class="allday-ts-fd">
      <img class="allday-ts-fd-title" src="../../assets/allday-ts-fd-title.png" alt="" />
      <div class="allday-ts-fd-but">
        <div
          @click="tsFdFun1"
          class="allday-ts-fd-but-small"
          :style="{background: tsFd1 ? '#5768ecFF' : '#fff', color: tsFd1 ? '#fff' : '#393939FF'}"
        >
          目标院校
        </div>
        <div
          @click="tsFdFun2"
          class="allday-ts-fd-but-small"
          :style="{background: tsFd2 ? '#5768ecFF' : '#fff', color: tsFd2 ? '#fff' : '#393939FF'}"
        >
          笔试、面试
        </div>
        <div
          @click="tsFdFun3"
          class="allday-ts-fd-but-small"
          :style="{background: tsFd3 ? '#5768ecFF' : '#fff', color: tsFd3 ? '#fff' : '#393939FF'}"
        >
          学术竞赛
        </div>
      </div>
      <img v-show="tsFd1" class="allday-ts-fd-img" src="../../assets/allday-ts-fd-img1.png" alt="" @click="consult" />
      <img v-show="tsFd2" class="allday-ts-fd-img" src="../../assets/allday-ts-fd-img2.png" alt="" @click="consult" />
      <img v-show="tsFd3" class="allday-ts-fd-img" src="../../assets/allday-ts-fd-img3.png" alt="" @click="consult" />
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="allday-but">
      <img class="allday-mobile" src="../../assets/allday-mobile-but.png" alt="" @click="callMobile" />
      <img class="allday-mobile" src="../../assets/allday-zx-but.png" alt="" @click="consult" />
    </div>
    <!-- A-Level课程热门科目 -->
    <div class="allday-rm-km">
      <img class="allday-rm-km-img" src="../../assets/allday-rm-km-img.png" alt="" />
    </div>
    <!-- 热门科目体验试听 -->
    <div class="allday-st">
      <div class="allday-st-bg">
        <input placeholder="请输入电话" type="text" class="form-input-div1" v-model="form.mobile" />
        <input placeholder="请输入姓名" type="text" class="form-input-div2" v-model="form.name" />
        <img @click="submit" class="submit-but" src="../../assets/subbut.png" alt="" />
      </div>
    </div>
    <!-- A-Level科目搭配建议 -->
    <div class="allday-km-dp">
      <img class="allday-km-dp-title" src="../../assets/allday-km-dp-title.png" alt="" />
      <img class="allday-km-dp-img" src="../../assets/allday-km-dp-img1.png" alt="" />
      <img class="allday-km-dp-img" src="../../assets/allday-km-dp-img2.png" alt="" />
      <img class="allday-km-dp-img" src="../../assets/allday-km-dp-img3.png" alt="" />
      <div class="allday-km-dp-but1" @click="consult">了解详情</div>
      <div class="allday-km-dp-but2" @click="consult">了解详情</div>
      <div class="allday-km-dp-but3" @click="consult">了解详情</div>
    </div>
    <!-- 全链路跟踪学习 -->
    <div class="allday-gz">
      <img class="allday-gz-img" src="../../assets/allday-gz.png" alt="" />
    </div>
    <div class="allday-but-bot">
      <img class="allday-but-lj" src="../../assets/allday-lj-but.png" alt="" @click="consult" />
    </div>
    <!-- 半海辅导优势 -->
    <img class="allday-bh-ys" src="../../assets/allday-bh-ys.png" alt="" />

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>

    <Popup :show="show" @showFun="showFun"></Popup>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';
import {Toast} from 'vant';
import axios from 'axios';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      showRouter: false,
      show: false,
      numberToCopy: 19821616277,
      ygBut: true,
      mgBut: false,
      xgBut: false,
      kcSz1: true,
      kcSz2: false,
      kcSz3: false,
      tsFd1: true,
      tsFd2: false,
      tsFd3: false,
      form: {
        mobile: '',
        name: ''
      }
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    toYg() {
      this.ygBut = true;
      this.mgBut = false;
      this.xgBut = false;
    },
    toMg() {
      this.ygBut = false;
      this.mgBut = true;
      this.xgBut = false;
    },
    toXg() {
      this.ygBut = false;
      this.mgBut = false;
      this.xgBut = true;
    },
    kcSzFun1() {
      this.kcSz1 = true;
      this.kcSz2 = false;
      this.kcSz3 = false;
    },
    kcSzFun2() {
      this.kcSz1 = false;
      this.kcSz2 = true;
      this.kcSz3 = false;
    },
    kcSzFun3() {
      this.kcSz1 = false;
      this.kcSz2 = false;
      this.kcSz3 = true;
    },
    tsFdFun1() {
      this.tsFd1 = true;
      this.tsFd2 = false;
      this.tsFd3 = false;
    },
    tsFdFun2() {
      this.tsFd1 = false;
      this.tsFd2 = true;
      this.tsFd3 = false;
    },
    tsFdFun3() {
      this.tsFd1 = false;
      this.tsFd2 = false;
      this.tsFd3 = true;
    },
    submit() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name && !this.form.mobile) {
        Toast.fail('手机号必须填写');
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        Toast.fail('手机号格式错误');
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            Toast.success('提交成功');
          })
          .catch(() => {});
      }
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.allday-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.allday-kc {
  position: relative;
  height: 12.864865rem /* 476/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-kc-img {
    width: 9.324324rem /* 345/37 */;
    height: 11.243243rem /* 416/37 */;
  }
  .allday-kc-but {
    position: absolute;
    height: 0.864865rem /* 32/37 */;
    width: 5.459459rem /* 202/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 87%;
    left: 23%;
  }
}
.allday-class-rk {
  width: 10.135135rem /* 375/37 */;
  height: 8.378378rem /* 310/37 */;
  padding-top: 0.540541rem /* 20/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  background-color: rgb(237, 239, 243);
  text-align: center;
  .allday-class-rk-logo {
    width: 6.486486rem /* 240/37 */;
    height: 1.459459rem /* 54/37 */;
    margin-bottom: 0.486486rem /* 18/37 */;
  }
  .allday-class-rk-bt {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0.810811rem /* 30/37 */;
    .allday-class-rk-bt1 {
      height: 0.810811rem /* 30/37 */;
      width: 2.432432rem /* 90/37 */;
      color: #000;
      background-color: #fff;
      font-size: 0.432432rem /* 16/37 */;
      line-height: 0.810811rem /* 30/37 */;
    }
  }
  .allday-class-rk-img {
    width: 9.405405rem /* 348/37 */;
    height: 4.351351rem /* 161/37 */;
  }
}
.allday-kc-ys {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 16.216216rem /* 600/37 */;
  .allday-kc-ys-img {
    width: 10.135135rem /* 375/37 */;
    height: 16.216216rem /* 600/37 */;
  }
  .allday-kc-ys-but {
    position: absolute;
    height: 0.864865rem /* 32/37 */;
    width: 5.459459rem /* 202/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 91.6%;
    left: 23%;
  }
}
.allday-why {
  background-color: rgb(237, 239, 243);
  height: 10.918919rem /* 404/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-why-img {
    height: 9.297297rem /* 344/37 */;
    width: 9.324324rem /* 345/37 */;
  }
}
.allday-kc-sz {
  width: 10.135135rem /* 375/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(237, 239, 243);
  .allday-kc-sz-title {
    width: 5.945946rem /* 220/37 */;
    height: 0.837838rem /* 31/37 */;
  }
  .allday-kc-sz-but {
    width: 8.513514rem /* 315/37 */;
    padding-left: 0.810811rem /* 30/37 */;
    padding-right: 0.810811rem /* 30/37 */;
    height: 1.621622rem /* 60/37 */;
    display: flex;
    justify-content: space-between;
    padding-top: 0.540541rem /* 20/37 */;
    .allday-kc-sz-but-small {
      width: 2.459459rem /* 91/37 */;
      height: 0.702703rem /* 26/37 */;
      border-radius: 0.540541rem /* 20/37 */;
      background-color: #fff;
      font-size: 0.324324rem /* 12/37 */;
      text-align: center;
      line-height: 0.702703rem /* 26/37 */;
    }
  }
  .allday-kc-sz-img {
    width: 9.324324rem /* 345/37 */;
  }
  .allday-kc-sz-zs {
    height: 1.27027rem /* 47/37 */;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.324324rem /* 12/37 */;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
  }
  .allday-kc-sz-bottom-but {
    height: 1.081081rem /* 40/37 */;
    width: 10.135135rem /* 375/37 */;
    .allday-kc-sz-mobile {
      width: 4.054054rem /* 150/37 */;
      height: 0.972973rem /* 36/37 */;
      margin-left: 0.675676rem /* 25/37 */;
    }
  }
}
.allday-mb-div {
  background-color: rgb(237, 239, 243);
  height: 10.405405rem /* 385/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-mb {
    width: 9.324324rem /* 345/37 */;
  }
}
.allday-yg-tj {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 10.945946rem /* 405/37 */;
  .allday-yg-tj-div1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .allday-yg-tj-div2 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);
  }
  .allday-yg-tj-div3 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 44%;
    left: 50%;
    transform: translateX(-50%);
  }
  .allday-yg-tj-div4 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 55.7%;
    left: 50%;
    transform: translateX(-50%);
  }
  .allday-yg-tj-div5 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 67.6%;
    left: 50%;
    transform: translateX(-50%);
  }
  .allday-yg-tj-img {
    width: 10.135135rem /* 375/37 */;
    height: 10.945946rem /* 405/37 */;
  }
  .allday-yg-tj-but {
    position: absolute;
    width: 5.405405rem /* 200/37 */;
    height: 0.810811rem /* 30/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 87%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.allday-ts-fd {
  padding-top: 0.810811rem /* 30/37 */;
  padding-bottom: 0.810811rem /* 30/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(237, 239, 243);
  .allday-ts-fd-title {
    width: 6.864865rem /* 254/37 */;
    margin-bottom: 0.432432rem /* 16/37 */;
  }
  .allday-ts-fd-but {
    width: 10.135135rem /* 375/37 */;
    height: 1.081081rem /* 40/37 */;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.432432rem /* 16/37 */;
    .allday-ts-fd-but-small {
      width: 2.702703rem /* 100/37 */;
      height: 0.918919rem /* 34/37 */;
      border-radius: 0.540541rem /* 20/37 */;
      background-color: #fff;
      font-size: 0.324324rem /* 12/37 */;
      line-height: 0.918919rem /* 34/37 */;
      text-align: center;
    }
  }
  .allday-ts-fd-img {
    width: 9.324324rem /* 345/37 */;
  }
}
.allday-but {
  height: 2.162162rem /* 80/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .allday-mobile {
    width: 4.054054rem /* 150/37 */;
  }
}
.allday-rm-km {
  background-color: rgb(237, 239, 243);
  height: 10.810811rem /* 400/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-rm-km-img {
    width: 9.540541rem /* 353/37 */;
    height: 9.891892rem /* 366/37 */;
  }
}
.allday-st {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.135135rem /* 375/37 */;
  height: 6.054054rem /* 224/37 */;
  background-color: rgb(237, 239, 243);
  .allday-st-bg {
    position: relative;
    width: 9.324324rem /* 345/37 */;
    height: 5.135135rem /* 190/37 */;
    background-image: url('../../assets/allday-st.png');
    background-repeat: no-repeat;
    background-size: cover;
    .form-input-div1 {
      position: absolute;
      width: 6.216216rem /* 230/37 */ !important;
      height: 0.945946rem /* 35/37 */ !important;
      border-radius: 0.27027rem /* 10/37 */;
      border: 0.027027rem /* 1/37 */ solid #ccc;
      margin-bottom: 0.27027rem /* 10/37 */;
      padding-left: 0.540541rem /* 20/37 */;
      font-size: 0.432432rem /* 16/37 */;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
    }
    .form-input-div2 {
      position: absolute;
      width: 6.216216rem /* 230/37 */ !important;
      height: 0.945946rem /* 35/37 */ !important;
      border-radius: 0.27027rem /* 10/37 */;
      border: 0.027027rem /* 1/37 */ solid #ccc;
      margin-bottom: 0.27027rem /* 10/37 */;
      padding-left: 0.540541rem /* 20/37 */;
      font-size: 0.432432rem /* 16/37 */;
      top: 55%;
      left: 50%;
      transform: translateX(-50%);
    }
    .submit-but {
      position: absolute;
      width: 3.135135rem /* 116/37 */;
      height: 1.027027rem /* 38/37 */;
      top: 79%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.allday-km-dp {
  position: relative;
  height: 16.486486rem /* 610/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.810811rem /* 30/37 */;

  .allday-km-dp-title {
    height: 1.432432rem /* 53/37 */;
    width: 9.135135rem /* 338/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
  }
  .allday-km-dp-img {
    width: 9.324324rem /* 345/37 */;
    margin-bottom: 0.27027rem /* 10/37 */;
  }
  .allday-km-dp-but1 {
    position: absolute;
    width: 3.918919rem /* 145/37 */;
    height: 0.702703rem /* 26/37 */;
    background: #5768ec;
    border-radius: 0.405405rem /* 15/37 */;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.27027rem /* 10/37 */;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 0.702703rem /* 26/37 */;
  }
  .allday-km-dp-but2 {
    position: absolute;
    width: 3.918919rem /* 145/37 */;
    height: 0.702703rem /* 26/37 */;
    background: #5768ec;
    border-radius: 0.405405rem /* 15/37 */;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.27027rem /* 10/37 */;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 0.702703rem /* 26/37 */;
  }
  .allday-km-dp-but3 {
    position: absolute;
    width: 3.918919rem /* 145/37 */;
    height: 0.702703rem /* 26/37 */;
    background: #5768ec;
    border-radius: 0.405405rem /* 15/37 */;
    top: 86%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.27027rem /* 10/37 */;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 0.702703rem /* 26/37 */;
  }
}
.allday-gz {
  width: 10.135135rem /* 375/37 */;
  height: 19.918919rem /* 737/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-gz-img {
    width: 9.540541rem /* 353/37 */;
    height: 19.918919rem /* 737/37 */;
  }
}
.allday-but-bot {
  width: 10.135135rem /* 375/37 */;
  height: 1.891892rem /* 70/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .allday-but-lj {
    width: 5.405405rem /* 200/37 */;
    height: 0.810811rem /* 30/37 */;
  }
}
.allday-bh-ys {
  width: 10.135135rem /* 375/37 */;
  height: 15.324324rem /* 567/37 */;
}
</style>
