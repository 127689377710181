<template>
  <div class="pc-g5">
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景页面 -->
    <div class="g5-bj">
      <img v-lazy="imageUrl" class="g5-bj" src="../../pc-imgs/g5-bj.png" alt="" @click="consult" />
    </div>
    <!-- 英国大学排名 -->
    <div class="g5-yg-pm">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-yg-pm-title">
            <img class="g5-yg-pm-title-img" src="../../pc-imgs/g5-yg-pm-title-img.png" alt="" />
          </div>
          <div class="g5-yg-pm-but">
            <div
              @click="ygPmFun1"
              class="g5-yg-pm-but-left"
              :style="{
                background: ygPm1 ? '#144eedFF' : '#d7e6fdFF',
                color: ygPm1 ? '#fff' : '#333',
                cursor: ygPm1 ? '' : 'pointer'
              }"
            >
              <div class="g5-yg-pm-but-top-text">QS世界大学排名</div>
              <div class="g5-yg-pm-but-bottom-text">
                QS世界大学排名将学术声誉、雇主声誉、师生比例、研究引用率、国际化作为评分标准
              </div>
            </div>
            <div
              @click="ygPmFun2"
              class="g5-yg-pm-but-right"
              :style="{
                background: ygPm2 ? '#144eedFF' : '#d7e6fdFF',
                color: ygPm2 ? '#fff' : '#333',
                cursor: ygPm2 ? '' : 'pointer'
              }"
            >
              <div class="g5-yg-pm-but-top-text">TIMES英国大学排名</div>
              <div class="g5-yg-pm-but-bottom-text">
                "泰晤士报英国大学排名”的指标包括学生满意度、设施和设备、优秀学位获得率、毕业率等
              </div>
            </div>
          </div>
          <div class="g5-yg-pm-img" v-show="ygPm1">
            <img class="g5-yg-pm-img1" src="../../pc-imgs/g5-yg-pm-img1.jpg" alt="" />
            <div class="g5-yg-pm-img-but1" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but2" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but3" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but4" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but5" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but6" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but7" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but8" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but9" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but10" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but11" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but12" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but13" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but14" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but15" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but16" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but17" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but18" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but19" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but20" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but21" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but22" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but23" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but24" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but25" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but26" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but27" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but28" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but29" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but30" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but31" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but32" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but33" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but34" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but35" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but36" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but37" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but38" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but39" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but40" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but41" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but42" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but43" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but44" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but45" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but46" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but47" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but48" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but49" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but50" @click="consult">免费获取</div>
          </div>
          <div class="g5-yg-pm-img" v-show="ygPm2">
            <img class="g5-yg-pm-img1" src="../../pc-imgs/g5-yg-pm-img1.jpg" alt="" />
            <div class="g5-yg-pm-img-but1" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but2" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but3" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but4" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but5" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but6" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but7" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but8" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but9" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but10" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but11" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but12" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but13" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but14" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but15" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but16" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but17" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but18" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but19" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but20" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but21" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but22" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but23" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but24" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but25" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but26" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but27" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but28" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but29" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but30" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but31" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but32" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but33" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but34" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but35" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but36" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but37" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but38" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but39" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but40" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but41" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but42" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but43" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but44" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but45" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but46" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but47" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but48" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but49" @click="consult">免费获取</div>
            <div class="g5-yg-pm-img-but50" @click="consult">免费获取</div>
          </div>
          <div class="g5-yg-pm-bottom-but">
            <img class="g5-mobile-but" src="../../pc-imgs/g5-mobile-but.png" alt="" @click="consult" />
            <img class="g5-mobile-but1" src="../../pc-imgs/g5-zx-but.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <!-- 英国G5超级精英大学盘点 -->
    <div class="g5-jy-dx">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-jy-dx-title">
            <img class="g5-jy-dx-title-img" src="../../pc-imgs/g5-jy-dx-title-img.png" alt="" />
          </div>
          <div class="g5-jy-dx-div">
            <div class="g5-jy-dx-div-top">
              <img class="g5-jy-dx-div-small" src="../../pc-imgs/g5-jy-dx-div-small1.png" alt="" />
              <img class="g5-jy-dx-div-small" src="../../pc-imgs/g5-jy-dx-div-small2.png" alt="" />
              <img class="g5-jy-dx-div-small" src="../../pc-imgs/g5-jy-dx-div-small3.png" alt="" />
            </div>
            <div class="g5-jy-dx-div-bottom">
              <img class="g5-jy-dx-div-small" src="../../pc-imgs/g5-jy-dx-div-small4.png" alt="" />
              <img class="g5-jy-dx-div-small" src="../../pc-imgs/g5-jy-dx-div-small5.png" alt="" />
              <img class="g5-jy-dx-div-small6" src="../../pc-imgs/g5-jy-dx-div-small6.png" alt="" @click="consult" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!-- G5大学申请条件和优势专业 -->
    <div class="g5-dx-sq">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-dx-sq-title">
            <img class="g5-dx-sq-title-img" src="../../pc-imgs/g5-dx-sq-title-img.png" alt="" />
          </div>
          <div class="g5-dx-sq-but">
            <div
              @click="dxSqFun1"
              class="g5-dx-sq-but-samll"
              :style="{
                background: dxSq1 ? '#144eedFF' : '',
                color: dxSq1 ? '#fff' : '#333',
                cursor: dxSq1 ? '' : 'pointer'
              }"
            >
              剑桥大学
            </div>
            <div
              @click="dxSqFun2"
              class="g5-dx-sq-but-samll"
              :style="{
                background: dxSq2 ? '#144eedFF' : '',
                color: dxSq2 ? '#fff' : '#333',
                cursor: dxSq2 ? '' : 'pointer'
              }"
            >
              牛津大学
            </div>
            <div
              @click="dxSqFun3"
              class="g5-dx-sq-but-samll"
              :style="{
                background: dxSq3 ? '#144eedFF' : '',
                color: dxSq3 ? '#fff' : '#333',
                cursor: dxSq3 ? '' : 'pointer'
              }"
            >
              伦敦大学学院
            </div>
            <div
              @click="dxSqFun4"
              class="g5-dx-sq-but-samll"
              :style="{
                background: dxSq4 ? '#144eedFF' : '',
                color: dxSq4 ? '#fff' : '#333',
                cursor: dxSq4 ? '' : 'pointer'
              }"
            >
              帝国理工学院
            </div>
            <div
              @click="dxSqFun5"
              class="g5-dx-sq-but-samll"
              :style="{
                background: dxSq5 ? '#144eedFF' : '',
                color: dxSq5 ? '#fff' : '#333',
                cursor: dxSq5 ? '' : 'pointer'
              }"
            >
              伦敦政治经济学院
            </div>
          </div>
          <div v-show="dxSq1">
            <img class="g5-dx-sq-img" src="../../pc-imgs/g5-dx-sq-img1.png" alt="" />
            <div class="g5-dx-sq-img-but1" @click="consult"></div>
          </div>
          <div v-show="dxSq2">
            <img class="g5-dx-sq-img" src="../../pc-imgs/g5-dx-sq-img2.png" alt="" />
            <div class="g5-dx-sq-img-but2" @click="consult"></div>
          </div>
          <div v-show="dxSq3">
            <img class="g5-dx-sq-img" src="../../pc-imgs/g5-dx-sq-img3.png" alt="" />
            <div class="g5-dx-sq-img-but3" @click="consult"></div>
          </div>
          <div v-show="dxSq4">
            <img class="g5-dx-sq-img" src="../../pc-imgs/g5-dx-sq-img4.png" alt="" />
            <div class="g5-dx-sq-img-but4" @click="consult"></div>
          </div>
          <div v-show="dxSq5">
            <img class="g5-dx-sq-img" src="../../pc-imgs/g5-dx-sq-img5.png" alt="" />
            <div class="g5-dx-sq-img-but5" @click="consult"></div>
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!-- AL/IG备考时间规划 -->
    <div class="g5-bk-time">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-bk-time-title">
            <img class="g5-bk-time-title-img" src="../../pc-imgs/g5-bk-time-title-img.png" alt="" />
          </div>
          <div class="g5-bk-time-but">
            <div
              @click="bkTimeFun1"
              class="g5-bk-time-but-small1"
              :style="{
                background: bkTime1 ? '#144eedFF' : '',
                color: bkTime1 ? '#fff' : '#333',
                cursor: bkTime1 ? '' : 'pointer'
              }"
            >
              TOP20
            </div>
            <div
              @click="bkTimeFun2"
              class="g5-bk-time-but-small2"
              :style="{
                background: bkTime2 ? '#144eedFF' : '',
                color: bkTime2 ? '#fff' : '#333',
                cursor: bkTime2 ? '' : 'pointer'
              }"
            >
              G5
            </div>
            <div
              @click="bkTimeFun3"
              class="g5-bk-time-but-small3"
              :style="{
                background: bkTime3 ? '#144eedFF' : '',
                color: bkTime3 ? '#fff' : '#333',
                cursor: bkTime3 ? '' : 'pointer'
              }"
            >
              牛剑
            </div>
          </div>
          <img v-show="bkTime1" class="g5-bk-time-img" src="../../pc-imgs/g5-bk-time-img1.png" alt="" />
          <img v-show="bkTime2" class="g5-bk-time-img" src="../../pc-imgs/g5-bk-time-img2.png" alt="" />
          <img v-show="bkTime3" class="g5-bk-time-img" src="../../pc-imgs/g5-bk-time-img3.png" alt="" />
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 英国本科留学申请条件解析 -->
    <div class="g5-yg-bk-sq">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-yg-bk-sq-title">
            <img class="g5-yg-bk-sq-title-img" src="../../pc-imgs/g5-yg-bk-sq-title-img.png" alt="" />
          </div>
          <!-- 英国大学申请方案免费规划 -->
          <div class="g5-yg-bk-sq-gh">
            <div class="g5-yg-bk-sq-gh-title">英国大学申请方案免费规划</div>
            <div class="g5-yg-bk-sq-gh-div">
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">牛津大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">剑桥大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">帝国理工学院</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">伦敦政治学院</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">伦敦大学学院</div>
            </div>
            <div class="g5-yg-bk-sq-gh-div">
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">爱丁堡大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">伦敦国王学院</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">曼彻斯特大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">华威大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">格拉斯哥大学</div>
            </div>
            <div class="g5-yg-bk-sq-gh-div">
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">圣安德鲁斯大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">布里斯托大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">伯明翰大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">杜伦大学</div>
              <div class="g5-yg-bk-sq-gh-div-small" @click="consult">利兹大学</div>
            </div>
            <div class="g5-yg-bk-sq-gh-div-bottom">
              <div class="g5-yg-bk-sq-gh-div-bottom-small1" @click="consult">诺丁汉大学</div>
              <div class="g5-yg-bk-sq-gh-div-bottom-small2" @click="consult">拉夫堡大学</div>
            </div>
          </div>
          <!-- 英国G5大学笔试 面试辅导 -->
          <div class="g5-yg-bk-sq-ms">
            <div class="g5-yg-bk-sq-ms-title">英国G5大学笔试 面试辅导</div>
            <div class="g5-yg-bk-sq-ms-div">
              <div class="g5-yg-bk-sq-ms-small" @click="consult">申请文书辅导</div>
              <div class="g5-yg-bk-sq-ms-small" @click="consult">剑桥面试</div>
              <div class="g5-yg-bk-sq-ms-small" @click="consult">牛津面试</div>
              <div class="g5-yg-bk-sq-ms-small" @click="consult">STEP数学测试</div>
              <div class="g5-yg-bk-sq-ms-small" @click="consult">MAT数学测试</div>
            </div>
            <div class="g5-yg-bk-sq-ms-div-middle">
              <div class="g5-yg-bk-sq-ms-div-middle-small1">PAT物理测试</div>
              <div class="g5-yg-bk-sq-ms-div-middle-small2">TSA思维技巧考试</div>
              <div class="g5-yg-bk-sq-ms-div-middle-small3">ENGAA工程考试</div>
              <div class="g5-yg-bk-sq-ms-div-middle-small4">NSAA自然科学考试</div>
            </div>
            <div class="g5-yg-bk-sq-ms-div-bottom">
              <div class="g5-yg-bk-sq-ms-div-bottom-small">ECAA经济考试</div>
            </div>
          </div>
          <!-- 不同专业方向竞赛推荐 -->
          <div class="g5-bt-fx">
            <div class="g5-bt-fx-title">不同专业方向竞赛推荐</div>
            <div class="g5-bt-fx-but">
              <div
                @click="btZyFun1"
                class="g5-bt-fx-but-small1"
                :style="{
                  background: btzy1 ? '#144eedFF' : '',
                  color: btzy1 ? '#fff' : '#144eedFF',
                  cursor: btzy1 ? '' : 'pointer'
                }"
              >
                工科类/理工类专业
              </div>
              <div
                @click="btZyFun2"
                class="g5-bt-fx-but-small2"
                :style="{
                  background: btzy2 ? '#144eedFF' : '',
                  color: btzy2 ? '#fff' : '#144eedFF',
                  cursor: btzy2 ? '' : 'pointer'
                }"
              >
                经济 文科/商科类专业
              </div>
            </div>
            <img class="g5-bt-fx-img" v-show="btzy1" src="../../pc-imgs/g5-bt-fx-img1.png" alt="" />
            <img class="g5-bt-fx-img" v-show="btzy2" src="../../pc-imgs/g5-bt-fx-img2.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 大学申请必看四大法则 -->
    <div class="g5-sq-fz">
      <img class="g5-sq-fz-img" src="../../pc-imgs/g5-sq-fz.png" alt="" />
      <div class="g5-sq-fz-but" @click="consult"></div>
    </div>
    <!-- 牛津剑桥笔试课程辅导 -->
    <div class="g5-kc-fd">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-kc-fd-title">
            <img class="g5-kc-fd-title-img" src="../../pc-imgs/g5-fd-title.png" alt="" />
          </div>
          <div class="g5-kc-fd-div">
            <div class="g5-kc-fd-div-top">
              <img class="g5-kc-fd-div-top-small" src="../../pc-imgs/g5-kc-fd-img1.png" alt="" />
              <img class="g5-kc-fd-div-top-small" src="../../pc-imgs/g5-kc-fd-img2.png" alt="" />
              <img class="g5-kc-fd-div-top-small" src="../../pc-imgs/g5-kc-fd-img3.png" alt="" />
            </div>
            <div class="g5-kc-fd-div-middle">
              <img class="g5-kc-fd-div-middle-small" src="../../pc-imgs/g5-kc-fd-img4.png" alt="" />
              <img class="g5-kc-fd-div-middle-small" src="../../pc-imgs/g5-kc-fd-img5.png" alt="" />
              <img class="g5-kc-fd-div-middle-small" src="../../pc-imgs/g5-kc-fd-img6.png" alt="" />
            </div>
            <div class="g5-kc-fd-div-bottom">
              <img class="g5-kc-fd-div-bottom-small" src="../../pc-imgs/g5-kc-fd-img7.png" alt="" />
              <img class="g5-kc-fd-div-bottom-small" src="../../pc-imgs/g5-kc-fd-img8.png" alt="" />
              <img class="g5-kc-fd-div-bottom-small" src="../../pc-imgs/g5-kc-fd-img90.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!-- 教学团队 -->
    <div class="home-teacher">
      <img class="home-teacher-img" src="../../pc-imgs/home-teacher-img.png" alt="" @click="consult" />
      <div class="home-teacher-img-but1" @click="consult"></div>
      <div class="home-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 英国本科留学常见问题 -->
    <div class="g5-question">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-question-title">
            <img class="g5-question-title-img" src="../../pc-imgs/g5-question-title-img.png" alt="" />
          </div>
          <div class="g5-question-div">
            <div class="g5-question-div-left">
              <div class="g5-question-div-left-title">国际学校学生</div>
              <div class="g5-question-div-left-div" @click="consult">
                全新申请要求汇总/解析
                <div class="g5-question-div-left-but">立即咨询</div>
              </div>
              <div class="g5-question-div-left-div" @click="consult">
                背景提升如何准备？
                <div class="g5-question-div-left-but">立即咨询</div>
              </div>
              <div class="g5-question-div-left-div" @click="consult">
                如何准备文书/推荐信？
                <div class="g5-question-div-left-but">立即咨询</div>
              </div>
              <div class="g5-question-div-left-div" @click="consult">
                不同专业/院校雅思要求？
                <div class="g5-question-div-left-but">立即咨询</div>
              </div>
              <div class="g5-question-div-left-div" @click="consult">
                笔试面试如何准备？
                <div class="g5-question-div-left-but">立即咨询</div>
              </div>
            </div>
            <div class="g5-question-div-middle">
              <div class="g5-question-div-middle-title">普高学生</div>
              <div class="g5-question-div-middle-div" @click="consult">
                申请英国大学的主流方式？
                <div class="g5-question-div-middle-but">立即咨询</div>
              </div>
              <div class="g5-question-div-middle-div" @click="consult">
                通过严格AL课程如何申请?
                <div class="g5-question-div-middle-but">立即咨询</div>
              </div>
              <div class="g5-question-div-middle-div" @click="consult">
                AL成绩能申请英国大学吗?
                <div class="g5-question-div-middle-but">立即咨询</div>
              </div>
              <div class="g5-question-div-middle-div" @click="consult">
                如何选专业选学校？
                <div class="g5-question-div-middle-but">立即咨询</div>
              </div>
              <div class="g5-question-div-middle-div" @click="consult">
                申请规划如何准备？
                <div class="g5-question-div-middle-but">立即咨询</div>
              </div>
            </div>
            <div class="g5-question-div-right">
              <img class="g5-question-div-right-img" src="../../pc-imgs/g5-wx.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!-- 英国大学申请规划时间线 -->
    <div class="g5-yg-time">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-yg-time-title">
            <img class="g5-yg-time-title-img" src="../../pc-imgs/g5-yg-time-title.png" alt="" />
          </div>
          <div class="g5-yg-time-but">
            <div
              @click="ygSqFun1"
              class="g5-yg-time-but-left"
              :style="{
                background: ygSq1 ? '#144eedFF' : '',
                color: ygSq1 ? '#fff' : '#144eedFF',
                cursor: ygSq1 ? '' : 'pointer'
              }"
            >
              本科申请规划
            </div>
            <div
              @click="ygSqFun2"
              class="g5-yg-time-but-right"
              :style="{
                background: ygSq2 ? '#144eedFF' : '',
                color: ygSq2 ? '#fff' : '#144eedFF',
                cursor: ygSq2 ? '' : 'pointer'
              }"
            >
              研究生申请规划
            </div>
          </div>
          <img class="yg-sq-img" v-show="ygSq1" src="../../pc-imgs/yg-sq-img1.png" alt="" />
          <img class="yg-sq-img" v-show="ygSq2" src="../../pc-imgs/yg-sq-img2.png" alt="" />
          <div class="yg-sq-img-but">
            <img @click="consult" class="g5-but" src="../../pc-imgs/g5-but1.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 我们覆盖的专业 -->
    <div class="g5-fg-zy">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="g5-fg-zy-top">
            <img class="g5-fg-zy-top-small1" src="../../pc-imgs/g5-fg-zy-top-small1.png" alt="" />
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">数学</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">纯数学/数学与统计</div>
              <div class="g5-fg-zy-top-small-tex2">应用数学/数学与经济</div>
            </div>
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">自然科学</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">物理/化学/生物</div>
              <div class="g5-fg-zy-top-small-tex2">材料/地理/生化/计算机等</div>
            </div>
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">工程</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">所有类型的工程科目</div>
            </div>
          </div>
          <div class="g5-fg-zy-top">
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">金融</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">纯数学/数学与统计</div>
              <div class="g5-fg-zy-top-small-tex2">经济与管理等</div>
            </div>
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">社科哲学类</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">金融数学与会计</div>
              <div class="g5-fg-zy-top-small-tex2">金融/工商管理等</div>
            </div>
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">经济</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">艺术设计/法律/医学等</div>
            </div>
            <div class="g5-fg-zy-top-small">
              <div class="g5-fg-zy-top-small-title">其他</div>
              <div class="g5-fg-zy-top-small-hx"></div>
              <div class="g5-fg-zy-top-small-tex1">PBS/HSPS/PPE/哲学</div>
              <div class="g5-fg-zy-top-small-tex2">心理等</div>
            </div>
          </div>
          <div class="g5-fg-zy-but">
            <img @click="consult" class="g5-fg-zy-but-img" src="../../pc-imgs/g5-but2.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>

    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';

import axios from 'axios';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      ygPm1: true,
      ygPm2: false,
      dxSq1: true,
      dxSq2: false,
      dxSq3: false,
      dxSq4: false,
      dxSq5: false,
      bkTime1: true,
      bkTime2: false,
      bkTime3: false,
      btzy1: true,
      btzy2: false,
      ygSq1: true,
      ygSq2: false
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    ygPmFun1() {
      this.ygPm1 = true;
      this.ygPm2 = false;
    },
    ygPmFun2() {
      this.ygPm1 = false;
      this.ygPm2 = true;
    },
    dxSqFun1() {
      this.dxSq1 = true;
      this.dxSq2 = false;
      this.dxSq3 = false;
      this.dxSq4 = false;
      this.dxSq5 = false;
    },
    dxSqFun2() {
      this.dxSq1 = false;
      this.dxSq2 = true;
      this.dxSq3 = false;
      this.dxSq4 = false;
      this.dxSq5 = false;
    },
    dxSqFun3() {
      this.dxSq1 = false;
      this.dxSq2 = false;
      this.dxSq3 = true;
      this.dxSq4 = false;
      this.dxSq5 = false;
    },
    dxSqFun4() {
      this.dxSq1 = false;
      this.dxSq2 = false;
      this.dxSq3 = false;
      this.dxSq4 = true;
      this.dxSq5 = false;
    },
    dxSqFun5() {
      this.dxSq1 = false;
      this.dxSq2 = false;
      this.dxSq3 = false;
      this.dxSq4 = false;
      this.dxSq5 = true;
    },
    bkTimeFun1() {
      this.bkTime1 = true;
      this.bkTime2 = false;
      this.bkTime3 = false;
    },
    bkTimeFun2() {
      this.bkTime1 = false;
      this.bkTime2 = true;
      this.bkTime3 = false;
    },
    bkTimeFun3() {
      this.bkTime1 = false;
      this.bkTime2 = false;
      this.bkTime3 = true;
    },
    btZyFun1() {
      this.btzy1 = true;
      this.btzy2 = false;
    },
    btZyFun2() {
      this.btzy1 = false;
      this.btzy2 = true;
    },
    ygSqFun1() {
      this.ygSq1 = true;
      this.ygSq2 = false;
    },
    ygSqFun2() {
      this.ygSq1 = false;
      this.ygSq2 = true;
    }
  }
};
</script>

<style lang="less">
.pc-g5 {
  .g5-bj {
    padding-top: 0.352857rem;
    height: 3.714286rem /* 520/140 */;
    width: 10.285714rem /* 1440/140 */;
    cursor: pointer;
  }
  .g5-yg-pm {
    margin-top: 0.352857rem;
    width: 100%;
    padding-top: 0.25rem /* 35/140 */;
    .g5-yg-pm-title {
      width: 100%;
      height: 0.185714rem /* 26/140 */;
      display: flex;
      justify-content: center;
      margin-bottom: 0.178571rem /* 25/140 */;
      .g5-yg-pm-title-img {
        height: 0.185714rem /* 26/140 */;
      }
    }

    .g5-yg-pm-but {
      width: 100%;
      height: 0.642857rem /* 90/140 */;
      display: flex;
      margin-bottom: 0.092857rem /* 13/140 */;
      background-color: #d7e6fdff;
      .g5-yg-pm-but-left {
        flex: 1;
        padding-top: 0.092857rem /* 13/140 */;
        padding-bottom: 0.092857rem /* 13/140 */;
        padding-left: 0.092857rem /* 13/140 */;
        padding-right: 0.092857rem /* 13/140 */;
        .g5-yg-pm-but-top-text {
          height: 0.15rem /* 21/140 */;
          width: 100%;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 0.128571rem /* 18/140 */;
          line-height: 0.15rem /* 21/140 */;
          text-align: center;
          font-style: normal;
        }
        .g5-yg-pm-but-bottom-text {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.085714rem /* 12/140 */;
          text-align: center;
          font-style: normal;
          margin-top: 0.071429rem /* 10/140 */;
        }
      }
      .g5-yg-pm-but-right {
        padding-top: 0.092857rem /* 13/140 */;
        padding-bottom: 0.092857rem /* 13/140 */;
        padding-left: 0.092857rem /* 13/140 */;
        padding-right: 0.092857rem /* 13/140 */;
        flex: 1;
        .g5-yg-pm-but-top-text {
          height: 0.15rem /* 21/140 */;
          width: 100%;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 0.128571rem /* 18/140 */;
          line-height: 0.15rem /* 21/140 */;
          text-align: center;
          font-style: normal;
        }
        .g5-yg-pm-but-bottom-text {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.085714rem /* 12/140 */;
          text-align: center;
          font-style: normal;
          margin-top: 0.071429rem /* 10/140 */;
        }
      }
    }
    .g5-yg-pm-img {
      position: relative;
      width: 100%;
      height: 3.528571rem /* 494/140 */;
      overflow-y: auto; /* 设置垂直方向上出现滚动条 */

      .g5-yg-pm-img1 {
        width: 100%;
      }

      .g5-yg-pm-img-but1 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 9%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but1:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but2 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 18.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but2:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but3 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 26.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but3:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but4 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 34.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but4:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but5 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 42%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but5:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but6 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 51%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but6:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but7 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 60%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but7:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but8 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 69%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but8:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but9 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 80%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but9:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but10 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 89%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but10:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but11 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 96.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but11:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but12 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 105.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but12:hover {
        background: #144eedff;
        color: #fff;
      }

      .g5-yg-pm-img-but13 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 116%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but13:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but14 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 126.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but14:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but15 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 135.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but15:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but16 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 144.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but16:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but17 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 153.6%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but17:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but18 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 161.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but18:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but19 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 171%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but19:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but20 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 180%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but20:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but21 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 187.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but21:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but22 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 195.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but22:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but23 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 203.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but23:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but24 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 212.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but24:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but25 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 221.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but25:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but26 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 230%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but26:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but27 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 240%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but27:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but28 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 249%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but28:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but29 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 259%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but29:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but30 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 269.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but30:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but31 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 278.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but31:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but32 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 288%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but32:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but33 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 297%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but33:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but34 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 306%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but34:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but35 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 315%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but35:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but36 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 323%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but36:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but37 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 331%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but37:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but38 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 339%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but38:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but39 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 348%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but39:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but40 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 358%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but40:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but41 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 368.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but41:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but42 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 378.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but42:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but43 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 388%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but43:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but44 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 396.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but44:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but45 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 406%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but45:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but46 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 414%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but46:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but47 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 422%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but47:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but48 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 431%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but48:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but49 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 441%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but49:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but50 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 451%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but50:hover {
        background: #144eedff;
        color: #fff;
      }
    }
    .g5-yg-pm-img::-webkit-scrollbar {
      width: 0.021429rem /* 3/140 */; /* 设置滚动条的宽度 */
    }
    .g5-yg-pm-img::-webkit-scrollbar-thumb {
      background-color: #888; /* 设置滚动条的颜色 */
      border-radius: 0.035714rem /* 5/140 */; /* 设置滚动条的圆角 */
    }
    .g5-yg-pm-img2 {
      position: relative;
      width: 100%;
      height: 3.528571rem /* 494/140 */;
      overflow-y: auto; /* 设置垂直方向上出现滚动条 */
      .g5-yg-pm-img1 {
        width: 100%;
      }
      .g5-yg-pm-img-but1 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 7%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but1:hover {
        background: #144eedff;
      }
      .g5-yg-pm-img-but2 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 15%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but3 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 23%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but4 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 32%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but5 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 42%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but6 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 53%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but7 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 62%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but8 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 69.6%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but9 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 77.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but10 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 85.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but11 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 94.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but12 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 103.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but13 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 112%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but14 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 119%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but15 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 127%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but16 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 135%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but17 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 143%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but18 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 151%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but19 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 160%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but20 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 169%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but21 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 177%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but22 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 185%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but23 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 193%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but24 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 201%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but25 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 209.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but26 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 218%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but27 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 227.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but28 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 236%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but29 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 245%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but30 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 254%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but31 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 262%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but32 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 270%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but33 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 278%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but34 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 285.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but35 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 293.6%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but36 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 301.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but37 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 309%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but38 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 317%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but39 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 325%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but40 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 334%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but41 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 343%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but42 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 351.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but43 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 360%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but44 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 369%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but45 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 377%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but46 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 383.5%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but47 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 390%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but48 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 398%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but49 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 406%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but50 {
        position: absolute;
        width: 0.5rem /* 70/140 */;
        height: 0.142857rem /* 20/140 */;
        text-align: center;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.142857rem /* 20/140 */;
        color: #1952edff;
        font-size: 0.085714rem /* 12/140 */;
        top: 414%;
        left: 90%;
        background-color: #d7e6fdff;
        cursor: pointer;
      }
      .g5-yg-pm-img-but50:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but49:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but48:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but47:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but46:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but45:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but44:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but43:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but42:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but41:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but40:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but39:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but38:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but37:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but36:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but35:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but34:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but33:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but32:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but31:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but30:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but29:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but28:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but27:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but26:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but25:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but24:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but23:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but22:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but21:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but20:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but19:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but18:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but17:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but16:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but15:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but14:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but13:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but12:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but11:hover {
        background: #144eedff;
        color: #fff;
      }

      .g5-yg-pm-img-but10:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but9:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but8:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but7:hover {
        background: #144eedff;
        color: #fff;
      }

      .g5-yg-pm-img-but6:hover {
        background: #144eedff;
        color: #fff;
      }

      .g5-yg-pm-img-but5:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but4:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but3:hover {
        background: #144eedff;
        color: #fff;
      }
      .g5-yg-pm-img-but2:hover {
        background: #144eedff;
        color: #fff;
      }

      .g5-yg-pm-img-but1:hover {
        background: #144eedff;
        color: #fff;
      }
    }
    .g5-yg-pm-bottom-but {
      position: relative;
      height: 0.614286rem /* 86/140 */;
      width: 100%;
      display: flex;
      align-items: center;
      .g5-mobile-but {
        position: absolute;
        height: 0.257143rem /* 36/140 */;
        left: 16%;
        top: 30%;
        cursor: pointer;
      }
      .g5-mobile-but1 {
        position: absolute;
        height: 0.257143rem /* 36/140 */;
        left: 56%;
        top: 30%;
        cursor: pointer;
      }
    }
  }
  .g5-jy-dx {
    height: 4.064286rem /* 569/140 */;
    width: 100%;
    background-color: #f5f5f5ff;
    .g5-jy-dx-title {
      width: 100%;
      height: 0.571429rem /* 80/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-jy-dx-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-jy-dx-div {
      height: 3.357143rem /* 470/140 */;
      width: 100%;
      .g5-jy-dx-div-top {
        width: 100%;
        height: 1.65rem /* 231/140 */;
        margin-bottom: 0.021429rem /* 3/140 */;
        display: flex;
        justify-content: space-between;

        .g5-jy-dx-div-small {
          flex: 1;
          height: 1.65rem /* 231/140 */;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .g5-jy-dx-div-small:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
      .g5-jy-dx-div-bottom {
        width: 100%;
        height: 1.65rem /* 231/140 */;
        margin-bottom: 0.021429rem /* 3/140 */;
        display: flex;
        justify-content: space-between;

        .g5-jy-dx-div-small {
          flex: 1;
          height: 1.65rem /* 231/140 */;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .g5-jy-dx-div-small6 {
          flex: 1;
          height: 1.65rem /* 231/140 */;
          cursor: pointer;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }

        .g5-jy-dx-div-small6:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
        .g5-jy-dx-div-small:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
    }
  }
  .g5-dx-sq {
    width: 100%;
    position: relative;
    .g5-dx-sq-title {
      height: 0.585714rem /* 82/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-dx-sq-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-dx-sq-but {
      height: 0.285714rem /* 40/140 */;
      margin-bottom: 0.114286rem /* 16/140 */;
      display: flex;
      justify-content: space-evenly;
      .g5-dx-sq-but-samll {
        height: 0.271429rem /* 38/140 */;
        line-height: 0.271429rem /* 38/140 */;
        text-align: center;
        width: 18%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        border-radius: 0.142857rem /* 20/140 */;
        font-size: 0.1rem /* 14/140 */;
      }
    }
    .g5-dx-sq-img {
      width: 100%;
    }
    .g5-dx-sq-img-but1 {
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .g5-dx-sq-img-but2 {
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .g5-dx-sq-img-but3 {
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      position: absolute;
      top: 89%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .g5-dx-sq-img-but4 {
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      position: absolute;
      top: 89%;
      left: 50%;
      transform: translateX(-50%);

      cursor: pointer;
    }
    .g5-dx-sq-img-but5 {
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      position: absolute;
      top: 88.5%;
      left: 50%;
      transform: translateX(-50%);

      cursor: pointer;
    }
  }
  .g5-bk-time {
    width: 100%;
    padding-bottom: 0.214286rem /* 30/140 */;
    background-color: #f5f5f5ff;
    .g5-bk-time-title {
      width: 100%;
      height: 0.585714rem /* 82/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-bk-time-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-bk-time-but {
      position: relative;
      height: 0.428571rem /* 60/140 */;
      width: 100%;
      font-size: 0.114286rem /* 16/140 */;
      .g5-bk-time-but-small1 {
        position: absolute;
        height: 0.271429rem /* 38/140 */;
        width: 1.128571rem /* 158/140 */;
        top: 0;
        left: 15%;
        background-color: #fff;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.271429rem /* 38/140 */;
        text-align: center;
      }
      .g5-bk-time-but-small2 {
        position: absolute;
        height: 0.271429rem /* 38/140 */;
        width: 1.128571rem /* 158/140 */;
        top: 0;
        left: 40%;
        background-color: #fff;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.271429rem /* 38/140 */;
        text-align: center;
      }
      .g5-bk-time-but-small3 {
        position: absolute;
        height: 0.271429rem /* 38/140 */;
        width: 1.128571rem /* 158/140 */;
        top: 0;
        left: 65%;
        background-color: #fff;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.271429rem /* 38/140 */;
        text-align: center;
      }
    }
    .g5-bk-time-img {
      width: 100%;
    }
  }
  .g5-yg-bk-sq {
    width: 100%;
    padding-bottom: 0.214286rem /* 30/140 */;
    background-color: #f1f5ffff;
    .g5-yg-bk-sq-title {
      height: 0.614286rem /* 86/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-yg-bk-sq-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-yg-bk-sq-gh {
      height: 1.721429rem /* 241/140 */;
      background-color: #fff;
      width: 100%;
      .g5-yg-bk-sq-gh-title {
        height: 0.4rem /* 56/140 */;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        font-size: 0.114286rem /* 16/140 */;
        color: #333333;
      }
      .g5-yg-bk-sq-gh-div {
        height: 0.214286rem /* 30/140 */;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 0.107143rem /* 15/140 */;
        .g5-yg-bk-sq-gh-div-small {
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
        .g5-yg-bk-sq-gh-div-small:hover {
          background-color: #144eedff;
          color: #fff;
        }
      }
      .g5-yg-bk-sq-gh-div-bottom {
        position: relative;
        height: 0.214286rem /* 30/140 */;
        width: 100%;
        display: flex;
        .g5-yg-bk-sq-gh-div-bottom-small1 {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          top: 7%;
          left: 2.5%;
          cursor: pointer;
        }
        .g5-yg-bk-sq-gh-div-bottom-small1:hover {
          background-color: #144eedff;
          color: #fff;
        }
        .g5-yg-bk-sq-gh-div-bottom-small2 {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          top: 7%;
          left: 22.3%;
          cursor: pointer;
        }
        .g5-yg-bk-sq-gh-div-bottom-small2:hover {
          background-color: #144eedff;
          color: #fff;
        }
      }
    }
    .g5-yg-bk-sq-ms {
      height: 1.4rem /* 196/140 */;
      width: 100%;
      background-color: #fff;
      margin-top: 0.142857rem /* 20/140 */;
      margin-bottom: 0.142857rem /* 20/140 */;
      .g5-yg-bk-sq-ms-title {
        height: 0.4rem /* 56/140 */;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        font-size: 0.114286rem /* 16/140 */;
        color: #333333;
      }
      .g5-yg-bk-sq-ms-div {
        height: 0.214286rem /* 30/140 */;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        .g5-yg-bk-sq-ms-small {
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
        .g5-yg-bk-sq-ms-small:hover {
          background-color: #144eedff;
          color: #fff;
        }
      }
      .g5-yg-bk-sq-ms-div-middle {
        position: relative;
        height: 0.214286rem /* 30/140 */;
        width: 100%;
        display: inline-block;

        .g5-yg-bk-sq-ms-div-middle-small1 {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
          top: 60%;
          left: 3%;
        }
        .g5-yg-bk-sq-ms-div-middle-small1:hover {
          background-color: #144eedff;
          color: #fff;
        }
        .g5-yg-bk-sq-ms-div-middle-small2 {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
          top: 60%;
          left: 23%;
        }
        .g5-yg-bk-sq-ms-div-middle-small2:hover {
          background-color: #144eedff;
          color: #fff;
        }
        .g5-yg-bk-sq-ms-div-middle-small3 {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
          top: 60%;
          left: 42%;
        }
        .g5-yg-bk-sq-ms-div-middle-small3:hover {
          background-color: #144eedff;
          color: #fff;
        }
        .g5-yg-bk-sq-ms-div-middle-small4 {
          position: absolute;
          width: 23%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
          top: 60%;
          left: 62%;
        }
        .g5-yg-bk-sq-ms-div-middle-small4:hover {
          background-color: #144eedff;
          color: #fff;
        }
      }
      .g5-yg-bk-sq-ms-div-bottom {
        position: relative;
        height: 0.285714rem /* 40/140 */;
        width: 100%;
        display: flex;
        .g5-yg-bk-sq-ms-div-bottom-small {
          position: absolute;
          width: 17%;
          height: 0.214286rem /* 30/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          font-size: 0.1rem /* 14/140 */;
          border-radius: 0.142857rem /* 20/140 */;
          box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
          cursor: pointer;
          top: 90%;
          left: 2.6%;
        }
        .g5-yg-bk-sq-ms-div-bottom-small:hover {
          background-color: #144eedff;
          color: #fff;
        }
      }
    }
    .g5-bt-fx {
      width: 100%;
      padding-bottom: 0.1rem /* 14/140 */;
      background-color: #fff;
      .g5-bt-fx-title {
        height: 0.4rem /* 56/140 */;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        font-size: 0.114286rem /* 16/140 */;
        color: #333333;
      }
      .g5-bt-fx-but {
        height: 0.314286rem /* 44/140 */;
        width: 100%;
        position: relative;
        .g5-bt-fx-but-small1 {
          position: absolute;
          height: 0.214286rem /* 30/140 */;
          width: 1.142857rem /* 160/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          border: 0.007143rem /* 1/140 */ solid #144eedff;
          top: 0;
          left: 25%;
          font-size: 0.1rem /* 14/140 */;
        }
        .g5-bt-fx-but-small2 {
          position: absolute;
          height: 0.214286rem /* 30/140 */;
          width: 1.142857rem /* 160/140 */;
          line-height: 0.214286rem /* 30/140 */;
          text-align: center;
          border: 0.007143rem /* 1/140 */ solid #144eedff;
          top: 0;
          left: 52%;
          font-size: 0.1rem /* 14/140 */;
        }
      }
      .g5-bt-fx-img {
        width: 100%;
      }
    }
  }
  .g5-sq-fz {
    height: 5.542857rem /* 776/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .g5-sq-fz-img {
      height: 5.114286rem /* 716/140 */;
    }
    .g5-sq-fz-but {
      position: absolute;
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91.5%;
      left: 50%;
      cursor: pointer;
      transform: translateX(-50%);
    }
  }
  .g5-kc-fd {
    background: #f5f5f5ff;
    width: 100%;
    height: 5.114286rem /* 716/140 */;
    .g5-kc-fd-title {
      height: 0.614286rem /* 86/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-kc-fd-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-kc-fd-div {
      width: 100%;
      .g5-kc-fd-div-top {
        height: 1.557143rem /* 218/140 */;
        margin-bottom: 0.107143rem /* 15/140 */;
        display: flex;
        justify-content: space-between;
        .g5-kc-fd-div-top-small {
          width: 32%;
          height: 1.557143rem /* 218/140 */;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .g5-kc-fd-div-top-small:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
      .g5-kc-fd-div-middle {
        height: 1.257143rem /* 176/140 */;
        margin-bottom: 0.107143rem /* 15/140 */;
        display: flex;
        justify-content: space-between;
        .g5-kc-fd-div-middle-small {
          width: 32%;
          height: 1.257143rem /* 176/140 */;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .g5-kc-fd-div-middle-small:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
      .g5-kc-fd-div-bottom {
        height: 1.257143rem /* 176/140 */;
        display: flex;
        justify-content: space-between;
        .g5-kc-fd-div-bottom-small {
          width: 32%;
          height: 1.257143rem /* 176/140 */;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .g5-kc-fd-div-bottom-small:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
    }
  }
  .g5-question {
    height: 3.042857rem /* 426/140 */;
    width: 100%;
    .g5-question-title {
      width: 100%;
      height: 0.614286rem /* 86/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-question-title-img {
        height: 0.185714rem /* 26/140 */;
      }
    }
    .g5-question-div {
      height: 1.814286rem /* 254/140 */;
      width: 100%;
      background-color: #fdf9dfff;
      display: flex;
      padding-top: 0.142857rem /* 20/140 */;
      padding-bottom: 0.142857rem /* 20/140 */;
      .g5-question-div-left {
        flex: 1.4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0.071429rem /* 10/140 */;
        padding-right: 0.142857rem /* 20/140 */;
        .g5-question-div-left-title {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 0.114286rem /* 16/140 */;
          color: #333333;
          line-height: 0.171429rem /* 24/140 */;
          height: 0.171429rem /* 24/140 */;
        }
        .g5-question-div-left-div {
          display: flex;
          justify-content: space-between;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: #333333;
          line-height: 0.15rem /* 21/140 */;
          height: 0.15rem /* 21/140 */;
          cursor: pointer;
        }
        .g5-question-div-left-div:hover {
          color: #144eedff;
        }
        .g5-question-div-left-but {
          height: 0.157143rem /* 22/140 */;
          width: 0.5rem /* 70/140 */;
          text-align: center;
          line-height: 0.157143rem /* 22/140 */;
          color: #fff;
          font-size: 0.1rem /* 14/140 */;
          background-color: #fdc233ff;
          border-radius: 0.142857rem /* 20/140 */;
        }
      }
      .g5-question-div-middle {
        flex: 1.5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 0.071429rem /* 10/140 */;
        .g5-question-div-middle-title {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 0.114286rem /* 16/140 */;
          color: #333333;
          line-height: 0.171429rem /* 24/140 */;
          height: 0.171429rem /* 24/140 */;
        }
        .g5-question-div-middle-div {
          display: flex;
          justify-content: space-between;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: #333333;
          line-height: 0.15rem /* 21/140 */;
          height: 0.15rem /* 21/140 */;
          cursor: pointer;
        }
        .g5-question-div-middle-div:hover {
          color: #144eedff;
        }
        .g5-question-div-middle-but {
          height: 0.157143rem /* 22/140 */;
          width: 0.5rem /* 70/140 */;
          text-align: center;
          line-height: 0.157143rem /* 22/140 */;
          color: #fff;
          font-size: 0.1rem /* 14/140 */;
          background-color: #fdc233ff;
          border-radius: 0.142857rem /* 20/140 */;
        }
      }
      .g5-question-div-right {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 0.485714rem /* 40/140 */;
        .g5-question-div-right-img {
          height: 1.214286rem /* 170/140 */;
        }
      }
    }
  }
  .g5-yg-time {
    width: 100%;
    background-color: #f5f5f5ff;
    .g5-yg-time-title {
      height: 0.614286rem /* 86/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-yg-time-title-img {
        height: 0.192857rem /* 27/140 */;
      }
    }
    .g5-yg-time-but {
      position: relative;
      height: 0.357143rem /* 50/140 */;
      width: 100%;
      font-size: 0.1rem /* 14/140 */;
      .g5-yg-time-but-left {
        height: 0.214286rem /* 30/140 */;
        width: 1.142857rem /* 160/140 */;
        border: 0.007143rem /* 1/140 */ solid #144eedff;
        line-height: 0.214286rem /* 30/140 */;
        text-align: center;
        position: absolute;
        top: 0;
        left: 25%;
      }
      .g5-yg-time-but-right {
        height: 0.214286rem /* 30/140 */;
        width: 1.142857rem /* 160/140 */;
        border: 0.007143rem /* 1/140 */ solid #144eedff;
        line-height: 0.214286rem /* 30/140 */;
        text-align: center;
        position: absolute;
        top: 0;
        left: 53%;
      }
    }
    .yg-sq-img {
      width: 100%;
    }
    .yg-sq-img-but {
      height: 0.535714rem /* 75/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .g5-but {
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
    }
  }
  .g5-fg-zy {
    height: 2.385714rem /* 334/140 */;
    width: 100%;
    background-image: url(../../pc-imgs/g5-fg-zy.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0.214286rem /* 30/140 */;
    .g5-fg-zy-top {
      height: 0.764286rem /* 107/140 */;
      margin-bottom: 0.107143rem /* 15/140 */;
      display: flex;
      justify-content: space-between;
      .g5-fg-zy-top-small1 {
        width: 25.5%;
        height: 0.885714rem /* 124/140 */;
        margin-left: -0.0263rem;
      }
      .g5-fg-zy-top-small {
        width: 23.5%;
        margin-top: 0.035714rem /* 5/140 */;
        height: 0.7rem /* 98/140 */;
        background-color: #fff;
        padding-top: 0.107143rem /* 15/140 */;
        padding-left: 0.05rem /* 7/140 */;
        cursor: pointer;
        .g5-fg-zy-top-small-title {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 0.114286rem /* 16/140 */;
          line-height: 0.171429rem /* 24/140 */;
        }
        .g5-fg-zy-top-small-hx {
          width: 0.278571rem /* 39/140 */;
          height: 0.021429rem /* 3/140 */;
          background: #fdc233ff;
          margin-bottom: 0.071429rem /* 10/140 */;
        }
        .g5-fg-zy-top-small-tex1 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          line-height: 0.114286rem /* 16/140 */;
          color: #626262ff;
        }
        .g5-fg-zy-top-small-tex2 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          line-height: 0.114286rem /* 16/140 */;
          color: #626262ff;
        }
      }
      .g5-fg-zy-top-small:hover {
        background-color: #fdc233ff;
        color: #fff;
        .g5-fg-zy-top-small-hx {
          width: 0.278571rem /* 39/140 */;
          height: 0.021429rem /* 3/140 */;
          background: #fff;
          margin-bottom: 0.071429rem /* 10/140 */;
        }
      }
    }
    .g5-fg-zy-but {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.357143rem /* 50/140 */;
      width: 100%;
      .g5-fg-zy-but-img {
        margin-top: 0.285714rem /* 40/140 */;
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
    }
  }
  .home-teacher {
    position: relative;
    height: 5.607143rem /* 785/140 */;
    width: 100%;
    padding-top: 0.285714rem /* 40/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
    .home-teacher-img {
      height: 5.107143rem /* 715/140 */;
    }
    .home-teacher-img-but1 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91.2%;
      left: 33%;
      cursor: pointer;
    }
    .home-teacher-img-but2 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91.2%;
      left: 53.3%;
      cursor: pointer;
    }
  }
}
</style>
