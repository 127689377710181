<template>
  <div>
    <div class="material1-bg">
      <div class="material1">
        <input class="material1-input1" type="text" placeholder="请输入姓名" />
        <input class="material1-input2" type="text" placeholder="请输入号码" />
      </div>
    </div>
    <div class="material1-bg">
      <div class="material1">
        <input class="material1-input1" type="text" placeholder="请输入姓名" />
        <input class="material1-input2" type="text" placeholder="请输入号码" />
      </div>
    </div>
    <van-swipe class="item-a" autoplay="3000">
      <van-swipe-item>1</van-swipe-item>
      <van-swipe-item>2</van-swipe-item>
      <van-swipe-item>3</van-swipe-item>
      <van-swipe-item>4</van-swipe-item>
    </van-swipe>
    <div class="horizontal-scroll-container">
      <div class="scroll-content">
        <div class="scroll-item">Item 1</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.material1-bg {
  position: relative;
  width: 375px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.material1 {
  width: 345px;
  height: 180px;
  background-image: url('../../assets/material1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.material1-input1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 203px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #fff;
  top: 53%;
  font-size: 12px;
  padding-left: 10px;
}
.material1-input2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 203px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #fff;
  top: 71%;
  font-size: 12px;
  padding-left: 10px;
}
.item-a {
  position: relative;
  height: 500px;
  width: 375px;
}
.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* 使用原生滚动，启用滚动动画 */
}

.scroll-content {
  display: inline-block;
}
.scroll-item {
  display: inline-block;
  width: 500px;
  height: 300px;
  background-color: #ddd;
  margin-right: 10px;
}
</style>
