<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="ig-bg">
      <img class="ig-bg" src="../../assets/ig-bg.png" alt="" @click="consult" />
    </div>
    <!-- 课程介绍 -->
    <div class="ig-kc-js">
      <img class="ig-kc-text" src="../../assets/ig-kc-js.png" alt="" />
      <div class="ig-kc-js-button-div">
        <div class="ig-kc-js-button-top">
          <div
            @click="igJs1"
            class="ig-kc-js-button-top1"
            :style="{backgroundColor: classJs1 ? '#004ce0FF' : '#ecf5ffFF', color: classJs1 ? '#ffffffFF' : '#000'}"
          >
            IGCSE选课
          </div>
          <div
            @click="igJs2"
            class="ig-kc-js-button-top1"
            :style="{backgroundColor: classJs2 ? '#004ce0FF' : '#ecf5ffFF', color: classJs2 ? '#ffffffFF' : '#000'}"
          >
            IGCSE和GCSE
          </div>
          <div
            @click="igJs3"
            class="ig-kc-js-button-top1"
            :style="{backgroundColor: classJs3 ? '#004ce0FF' : '#ecf5ffFF', color: classJs3 ? '#ffffffFF' : '#000'}"
          >
            IGCSE考试局
          </div>
        </div>
      </div>
      <img class="ig-js-img" v-show="classJs1" src="../../assets/ig-js-img1.png" alt="" />
      <img class="ig-js-img2" v-show="classJs2" src="../../assets/ig-js-img2.png" alt="" />
      <img class="ig-js-img3" v-show="classJs3" src="../../assets/ig-js-img3.png" alt="" />
    </div>
    <!-- 课程的重要性 -->
    <img class="ig-class-imporant" src="../../assets/ig-class-imporant.png" alt="" />
    <!-- 英国大学多IGCSE成绩要求 -->
    <div class="ig-yg-cj">
      <img class="ig-yg-title" src="../../assets/ig-yg-title.png" alt="" />
      <div class="ig-yg-but">
        <div
          @click="dx1Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx1 ? '#004ce0FF' : '#ecf5ffFF', color: dx1 ? '#ffffffFF' : '#000'}"
        >
          牛津大学
        </div>
        <div
          @click="dx2Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx2 ? '#004ce0FF' : '#ecf5ffFF', color: dx2 ? '#ffffffFF' : '#000'}"
        >
          剑桥大学
        </div>
        <div
          @click="dx3Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx3 ? '#004ce0FF' : '#ecf5ffFF', color: dx3 ? '#ffffffFF' : '#000'}"
        >
          帝国理工学院
        </div>
        <div
          @click="dx4Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx4 ? '#004ce0FF' : '#ecf5ffFF', color: dx4 ? '#ffffffFF' : '#000'}"
        >
          伦敦大学学院
        </div>
        <div
          @click="dx5Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx5 ? '#004ce0FF' : '#ecf5ffFF', color: dx5 ? '#ffffffFF' : '#000'}"
        >
          伦敦政经学院
        </div>
        <div
          @click="dx6Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx6 ? '#004ce0FF' : '#ecf5ffFF', color: dx6 ? '#ffffffFF' : '#000'}"
        >
          爱丁堡大学
        </div>
        <div
          @click="dx7Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx7 ? '#004ce0FF' : '#ecf5ffFF', color: dx7 ? '#ffffffFF' : '#000'}"
        >
          曼彻斯特大学
        </div>
        <div
          @click="dx9Fun"
          class="ig-yg-but1"
          :style="{backgroundColor: dx9 ? '#004ce0FF' : '#ecf5ffFF', color: dx9 ? '#ffffffFF' : '#000'}"
        >
          华威大学
        </div>
      </div>
      <img v-show="dx1" class="ig-yg-img1" src="../../assets/ig-nj-dx.png" alt="" />
      <img v-show="dx2" class="ig-yg-img2" src="../../assets/ig-yg-img2.png" alt="" />
      <img v-show="dx3" class="ig-yg-img3" src="../../assets/ig-yg-img3.png" alt="" />
      <img v-show="dx4" class="ig-yg-img4" src="../../assets/ig-yg-img4.png" alt="" />
      <img v-show="dx5" class="ig-yg-img5" src="../../assets/ig-yg-img5.png" alt="" />
      <img v-show="dx6" class="ig-yg-img6" src="../../assets/ig-yg-img6.png" alt="" />
      <img v-show="dx7" class="ig-yg-img7" src="../../assets/ig-yg-img7.png" alt="" />
      <img v-show="dx9" class="ig-yg-img8" src="../../assets/ig-yg-img8.png" alt="" />
    </div>
    <!-- 课程安排 -->
    <img class="ig-cls-plan" src="../../assets/ig-cls-plan.png" alt="" />
    <!-- IGCSE科目/课程设置 -->
    <div class="ig-class-design">
      <img class="ig-class-design-img" src="../../assets/ig-design.png" alt="" />
      <img class="ig-but1" src="../../assets/ig-but1.png" alt="" @click="callMobile" />
      <img class="ig-but2" src="../../assets/ig-but2.png" alt="" @click="consult" />
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- IGCSE学习中的常见问题 -->
    <div class="ig-ques">
      <img class="ig-ques-title" src="../../assets/ig-ques-title.png" alt="" />
      <div class="ig-ques-but">
        <div
          @click="quesFun1"
          class="ig-ques-but1"
          :style="{backgroundColor: ques1 ? '#004ce0FF' : '#fff', color: ques1 ? '#ffffffFF' : '#000'}"
        >
          关于选课
        </div>
        <div
          @click="quesFun2"
          class="ig-ques-but2"
          :style="{backgroundColor: ques2 ? '#004ce0FF' : '#fff', color: ques2 ? '#ffffffFF' : '#000'}"
        >
          关于申请
        </div>
      </div>
      <img v-show="ques1" class="ig-ques-img" src="../../assets/ig-ques-img1.png" alt="" />
      <img v-show="ques2" class="ig-ques-img" src="../../assets/ig-ques-img2.png" alt="" />
      <div class="ques1-div1" @click="consult"></div>
      <div class="ques1-div2" @click="consult"></div>
      <div class="ques1-div3" @click="consult"></div>
      <div class="ques1-div4" @click="consult"></div>
      <div class="ques1-div5" @click="consult"></div>
    </div>
    <!-- 我们的优势 -->
    <div class="ig-ys">
      <img class="ig-ys-img" src="../../assets/ig-ys.png" alt="" />
    </div>
    <!-- 跟踪学习 -->
    <div class="ig-gz">
      <img class="ig-gz-img" src="../../assets/al-gz.png" alt="" />
    </div>
    <!-- 贴心服务 -->
    <img class="al-tx" src="../../assets/al-tx.png" alt="" />

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      numberToCopy: 19821616277,
      showRouter: false,
      show: false,
      classJs1: true,
      classJs2: false,
      classJs3: false,
      dx1: true,
      dx2: false,
      dx3: false,
      dx4: false,
      dx5: false,
      dx6: false,
      dx7: false,
      dx8: false,
      dx9: false,
      dx10: false,
      ques1: true,
      ques2: false
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    quesFun1() {
      this.ques1 = true;
      this.ques2 = false;
    },
    quesFun2() {
      this.ques1 = false;
      this.ques2 = true;
    },
    dx1Fun() {
      this.dx1 = true;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx2Fun() {
      this.dx1 = false;
      this.dx2 = true;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx3Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = true;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx4Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = true;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx5Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = true;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx6Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = true;
      this.dx7 = false;
      this.dx9 = false;
    },
    dx7Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = true;
      this.dx9 = false;
    },
    dx9Fun() {
      this.dx1 = false;
      this.dx2 = false;
      this.dx3 = false;
      this.dx4 = false;
      this.dx5 = false;
      this.dx6 = false;
      this.dx7 = false;
      this.dx9 = true;
    },

    igJs1() {
      this.classJs1 = true;
      this.classJs2 = false;
      this.classJs3 = false;
    },
    igJs2() {
      this.classJs1 = false;
      this.classJs2 = true;
      this.classJs3 = false;
    },
    igJs3() {
      this.classJs1 = false;
      this.classJs2 = false;
      this.classJs3 = true;
    }

    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.ig-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ig-kc-js {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.810811rem /* 30/37 */;
  .ig-kc-text {
    width: 9.324324rem /* 345/37 */;
    height: 5.675676rem /* 210/37 */;
    margin-bottom: 0.810811rem /* 30/37 */;
  }
  .ig-kc-js-button-div {
    padding-left: 0.405405rem /* 15/37 */;
    padding-right: 0.405405rem /* 15/37 */;
    width: 9.297297rem /* 344/37 */;
    height: 0.675676rem /* 25/37 */;
    .ig-kc-js-button-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.540541rem /* 20/37 */;
      .ig-kc-js-button-top1 {
        width: 2.891892rem /* 107/37 */;
        height: 0.72973rem /* 27/37 */;
        background-color: #ecf5ffff;
        border-radius: 0.540541rem /* 20/37 */;
        color: #000;
        font-size: 0.324324rem /* 12/37 */;
        text-align: center;
        line-height: 0.72973rem /* 27/37 */;
      }
    }
  }
  .ig-js-img {
    width: 9.324324rem /* 345/37 */;
    height: 12.405405rem /* 459/37 */;
    margin-top: 0.675676rem /* 25/37 */;
  }
  .ig-js-img2 {
    width: 9.324324rem /* 345/37 */;
    height: 10.513514rem /* 389/37 */;
    margin-top: 0.675676rem /* 25/37 */;
  }
  .ig-js-img3 {
    width: 9.324324rem /* 345/37 */;
    height: 11.702703rem /* 433/37 */;
    margin-top: 0.675676rem /* 25/37 */;
  }
}
.ig-class-imporant {
  width: 10.135135rem /* 375/37 */;
  height: 17.810811rem /* 659/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ig-yg-cj {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ig-yg-title {
    width: 6.675676rem /* 247/37 */;
    height: 0.648649rem /* 24/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
  }
  .ig-yg-but {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .ig-yg-but1 {
      height: 0.702703rem /* 26/37 */;
      width: 2.135135rem /* 79/37 */;
      background-color: #ecf5ffff;
      border-radius: 0.405405rem /* 15/37 */;
      margin-bottom: 0.540541rem /* 20/37 */;
      font-size: 0.27027rem /* 10/37 */;
      text-align: center;
      line-height: 0.702703rem /* 26/37 */;
    }
    .ig-yg-but2 {
      height: 0.702703rem /* 26/37 */;
      width: 2.135135rem /* 79/37 */;
      margin-bottom: 0.27027rem /* 10/37 */;
      font-size: 0.27027rem /* 10/37 */;
      text-align: center;
      line-height: 0.702703rem /* 26/37 */;
    }
  }
  .ig-yg-img1 {
    width: 9.324324rem /* 345/37 */;
    height: 3.513514rem /* 130/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img2 {
    width: 9.324324rem /* 345/37 */;
    height: 2.972973rem /* 110/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img3 {
    width: 9.324324rem /* 345/37 */;
    height: 2.378378rem /* 88/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img4 {
    width: 9.324324rem /* 345/37 */;
    height: 2.972973rem /* 110/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img5 {
    width: 9.324324rem /* 345/37 */;
    height: 2.972973rem /* 110/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img6 {
    width: 9.324324rem /* 345/37 */;
    height: 3.540541rem /* 131/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img7 {
    width: 9.324324rem /* 345/37 */;
    height: 2.972973rem /* 110/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
  .ig-yg-img8 {
    width: 9.324324rem /* 345/37 */;
    height: 2.972973rem /* 110/37 */;
    margin-bottom: 0.756757rem /* 28/37 */;
  }
}
.ig-cls-plan {
  width: 10.135135rem /* 375/37 */;
  height: 5rem /* 185/37 */;
  margin-bottom: 0.702703rem /* 26/37 */;
}
.ig-class-design {
  position: relative;
  height: 25.135135rem /* 930/37 */;
  width: 10.135135rem /* 375/37 */;
  margin-bottom: 0.810811rem /* 30/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .ig-class-design-img {
    width: 9.432432rem /* 349/37 */;
    height: 25.135135rem /* 930/37 */;
  }
  .ig-but1 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 94%;
    left: 6%;
  }
  .ig-but2 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 94%;
    left: 53%;
  }
}
.ig-teacher {
  margin-top: 0.810811rem /* 30/37 */;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.27027rem /* 10/37 */;
  .ig-teacher-logo {
    width: 5.675676rem /* 210/37 */;
    height: 1.27027rem /* 47/37 */;
    margin-bottom: 0.459459rem /* 17/37 */;
  }
  .ig-teacher-swp {
    height: 10.27027rem /* 380/37 */;
    width: 10.135135rem /* 375/37 */;
    .van-swipe__indicator {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(213, 214, 215) !important;
    }
    .van-swipe__indicator--active {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(96, 158, 273) !important;
    }
  }
  .teacher-swp-item-ig {
    width: 9.432432rem /* 349/37 */;
    height: 9.189189rem /* 340/37 */;
    margin-left: 0.378378rem /* 14/37 */;
  }
}
.ig-ques {
  position: relative;
  height: 9.756757rem /* 361/37 */;
  background-color: #ecf5ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ig-ques-title {
    width: 5.702703rem /* 211/37 */;
    height: 0.648649rem /* 24/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
    margin-top: 0.540541rem /* 20/37 */;
  }
  .ig-ques-but {
    display: flex;
    margin-bottom: 0.405405rem /* 15/37 */;
    .ig-ques-but1 {
      background-color: #fff;
      color: #000;
      width: 3.243243rem /* 120/37 */;
      height: 0.810811rem /* 30/37 */;
      border-radius: 0.540541rem /* 20/37 */;
      margin-right: 0.540541rem /* 20/37 */;
      text-align: center;
      line-height: 0.810811rem /* 30/37 */;
      font-size: 0.432432rem /* 16/37 */;
    }
    .ig-ques-but2 {
      background-color: #fff;
      color: #000;
      width: 3.243243rem /* 120/37 */;
      height: 0.810811rem /* 30/37 */;
      border-radius: 0.540541rem /* 20/37 */;
      text-align: center;
      line-height: 0.810811rem /* 30/37 */;
      font-size: 0.432432rem /* 16/37 */;
    }
  }
  .ig-ques-img {
    width: 9.324324rem /* 345/37 */;
    height: 6.27027rem /* 232/37 */;
  }
  .ques1-div1 {
    position: absolute;
    width: 9.324324rem /* 345/37 */;
    height: 1.081081rem /* 40/37 */;
    top: 30%;
    left: 4%;
  }
  .ques1-div2 {
    position: absolute;
    width: 9.324324rem /* 345/37 */;
    height: 1.081081rem /* 40/37 */;
    top: 44%;
    left: 4%;
  }
  .ques1-div3 {
    position: absolute;
    width: 9.324324rem /* 345/37 */;
    height: 1.081081rem /* 40/37 */;
    top: 57%;
    left: 4%;
  }
  .ques1-div4 {
    position: absolute;
    width: 9.324324rem /* 345/37 */;
    height: 1.081081rem /* 40/37 */;
    top: 70%;
    left: 4%;
  }
  .ques1-div5 {
    position: absolute;
    width: 9.324324rem /* 345/37 */;
    height: 1.081081rem /* 40/37 */;
    top: 83%;
    left: 4%;
  }
}
.ig-ys {
  height: 16.756757rem /* 620/37 */;
  width: 10.135135rem /* 375/37 */;
  margin-top: 0.540541rem /* 20/37 */;
  display: flex;
  align-items: center;
  justify-content: center;
  .ig-ys-img {
    width: 9.324324rem /* 345/37 */;
    height: 15.972973rem /* 591/37 */;
  }
}
.ig-gz {
  height: 20.783784rem /* 769/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  .ig-gz-img {
    height: 19.891892rem /* 736/37 */;
    width: 9.540541rem /* 353/37 */;
  }
}
</style>
