<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="ys-bg">
      <img class="ys-bg" src="../../assets/ys-bj.png" alt="" @click="consult" />
    </div>
    <!-- 雅思培训 -->
    <div class="ys-px">
      <img class="ys-px-img" src="../../assets/ys-px.png" alt="" />
      <img class="ys-mobile-but" src="../../assets/ys-mobile-but.png" alt="" @click="callMobile" />
      <img class="ys-zx-but" src="../../assets/ys-zx-but.png" alt="" @click="consult" />
    </div>
    <!-- 半海雅思课程设置 -->
    <div class="ys-kc-sz">
      <img class="ys-kc-sz-title" src="../../assets/ys-kc-sz-title.png" alt="" />
      <div class="ys-kc-sz-button">
        <div class="ys-kc-sz-button1">
          <div
            @click="classDesFun1"
            class="ys-kc-sz-button1-but"
            :style="{
              backgroundColor: classDes1 ? '#f5a823FF' : '#f1f9ffFF',
              color: classDes1 ? '#fff' : '#333333FF'
            }"
          >
            预备衔接班
          </div>
          <div
            @click="classDesFun2"
            class="ys-kc-sz-button1-but"
            :style="{
              backgroundColor: classDes2 ? '#f5a823FF' : '#f1f9ffFF',
              color: classDes2 ? '#fff' : '#333333FF'
            }"
          >
            5.5分提高班
          </div>
          <div
            @click="classDesFun3"
            class="ys-kc-sz-button1-but"
            :style="{
              backgroundColor: classDes3 ? '#f5a823FF' : '#f1f9ffFF',
              color: classDes3 ? '#fff' : '#333333FF'
            }"
          >
            6分精练班
          </div>
        </div>
        <div class="ys-kc-sz-button2">
          <div
            @click="classDesFun4"
            class="ys-kc-sz-button2-but"
            :style="{
              backgroundColor: classDes4 ? '#f5a823FF' : '#f1f9ffFF',
              color: classDes4 ? '#fff' : '#333333FF'
            }"
          >
            6.5分特训班
          </div>
          <div
            @click="classDesFun5"
            class="ys-kc-sz-button2-but"
            :style="{
              backgroundColor: classDes5 ? '#f5a823FF' : '#f1f9ffFF',
              color: classDes5 ? '#fff' : '#333333FF'
            }"
          >
            高分名师班
          </div>
        </div>
      </div>
      <img class="ys-kc-des1" v-show="classDes1" src="../../assets/ys-kc-des1.png" alt="" />
      <img class="ys-kc-des1" v-show="classDes2" src="../../assets/ys-kc-des2.png" alt="" />
      <img class="ys-kc-des1" v-show="classDes3" src="../../assets/ys-kc-des3.png" alt="" />
      <img class="ys-kc-des1" v-show="classDes4" src="../../assets/ys-kc-des4.png" alt="" />
      <img class="ys-kc-des1" v-show="classDes5" src="../../assets/ys-kc-des5.png" alt="" />
    </div>
    <!-- 雅思学习小技巧 -->
    <div class="ys-jq">
      <img class="ys-jq-img" src="../../assets/ys-jq.png" alt="" />
      <div class="ys-jq-but1" @click="callMobile"></div>
      <div class="ys-jq-but2" @click="consult"></div>
    </div>
    <!-- 英国G5院校雅思要求 -->
    <div class="app-ys-yg-yq">
      <img class="app-ys-yg-yq-title" src="../../assets/ys-yg-yq-title.png" alt="" />
      <div class="app-ys-yg-yq-dx">
        <div class="app-ys-yg-yq-dx-img">
          <img class="app-ys-dx" src="../../assets/ys-dx1.png" alt="" />
          <img class="app-ys-dx" src="../../assets/ys-dx2.png" alt="" />
          <img class="app-ys-dx" src="../../assets/ys-dx3.png" alt="" />
          <img class="app-ys-dx" src="../../assets/ys-dx4.png" alt="" />
          <img class="app-ys-dx" src="../../assets/ys-dx5.png" alt="" />
        </div>
      </div>
      <img class="app-ys-yg-yq-but1" src="../../assets/ys-mobile-but2.png" alt="" @click="callMobile" />
      <img class="app-ys-yg-yq-but2" src="../../assets/ys-zx-but.png" alt="" @click="consult" />
    </div>
    <!-- 半海雅思方案 -->
    <div class="app-ys-fa">
      <img class="app-ys-fangan" src="../../assets/ys-fangan.png" alt="" />
      <div class="app-ys-fangan-but1" @click="callMobile"></div>
      <div class="app-ys-fangan-but2" @click="consult"></div>
    </div>
    <!-- 半海语培服务流程 -->
    <img class="app-ys-yp-fw" src="../../assets/ys-yp-fw.png" alt="" />
    <!-- 按钮 -->
    <div class="ys-yp-but">
      <img class="ys-yp-but1" src="../../assets/ys-mobile-but2.png" alt="" @click="callMobile" />
      <img class="ys-yp-but1" src="../../assets/ys-zx-but.png" alt="" @click="consult" />
    </div>
    <!-- 完善的雅思教学闭环服务 -->
    <div class="ys-bh">
      <img class="ys-bh-img" src="../../assets/ys-bh.png" alt="" />
      <div class="ys-bh-but1" @click="callMobile"></div>
      <div class="ys-bh-but2" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 半海辅导优势 -->
    <img class="ys-fd-ys" src="../../assets/ys-fd-ys.png" alt="" />

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      showRouter: false,
      show: false,
      numberToCopy: 19821616277,
      classDes1: true,
      classDes2: false,
      classDes3: false,
      classDes4: false,
      classDes5: false
    };
  },
  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    classDesFun1() {
      this.classDes1 = true;
      this.classDes2 = false;
      this.classDes3 = false;
      this.classDes4 = false;
      this.classDes5 = false;
    },
    classDesFun2() {
      this.classDes1 = false;
      this.classDes2 = true;
      this.classDes3 = false;
      this.classDes4 = false;
      this.classDes5 = false;
    },
    classDesFun3() {
      this.classDes1 = false;
      this.classDes2 = false;
      this.classDes3 = true;
      this.classDes4 = false;
      this.classDes5 = false;
    },
    classDesFun4() {
      this.classDes1 = false;
      this.classDes2 = false;
      this.classDes3 = false;
      this.classDes4 = true;
      this.classDes5 = false;
    },
    classDesFun5() {
      this.classDes1 = false;
      this.classDes2 = false;
      this.classDes3 = false;
      this.classDes4 = false;
      this.classDes5 = true;
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.ys-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.ys-px {
  padding-top: 0.675676rem /* 25/37 */;
  position: relative;
  display: flex;
  justify-content: center;
  height: 14.864865rem /* 550/37 */;
  background-color: rgb(237, 239, 243);
  .ys-px-img {
    width: 9.324324rem /* 345/37 */;
    height: 12.297297rem /* 455/37 */;
  }
  .ys-mobile-but {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    position: absolute;
    top: 88%;
    left: 5%;
  }
  .ys-zx-but {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    position: absolute;
    top: 88%;
    left: 55%;
  }
}
.ys-kc-sz {
  background-color: #2790fdff;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  padding-bottom: 0.540541rem /* 20/37 */;
  align-items: center;
  flex-direction: column;
  padding-top: 0.540541rem /* 20/37 */;
  .ys-kc-sz-title {
    width: 3.837838rem /* 142/37 */;
    height: 0.486486rem /* 18/37 */;
  }
  .ys-kc-sz-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 9.297297rem /* 344/37 */;
    height: 2.972973rem /* 110/37 */;
    .ys-kc-sz-button1 {
      display: flex;
      justify-content: space-between;
      .ys-kc-sz-button1-but {
        width: 2.837838rem /* 105/37 */;
        height: 0.702703rem /* 26/37 */;
        border-radius: 0.540541rem /* 20/37 */;
        background-color: #fff;
        font-size: 0.324324rem /* 12/37 */;
        line-height: 0.702703rem /* 26/37 */;
        text-align: center;
      }
    }
    .ys-kc-sz-button2 {
      margin-top: 0.27027rem /* 10/37 */;
      display: flex;
      justify-content: space-between;
      padding-left: 1.351351rem /* 50/37 */;
      padding-right: 1.351351rem /* 50/37 */;
      .ys-kc-sz-button2-but {
        width: 2.837838rem /* 105/37 */;
        height: 0.702703rem /* 26/37 */;
        border-radius: 0.540541rem /* 20/37 */;
        background-color: #fff;
        font-size: 0.324324rem /* 12/37 */;
        line-height: 0.702703rem /* 26/37 */;
        text-align: center;
      }
    }
  }
  .ys-kc-des1 {
    width: 9.540541rem /* 353/37 */;
  }
}
.ys-jq {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 23.594595rem /* 873/37 */;
  .ys-jq-img {
    width: 10.135135rem /* 375/37 */;
    height: 23.594595rem /* 873/37 */;
  }
  .ys-jq-but1 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 92.5%;
    left: 7%;
  }
  .ys-jq-but2 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 92.5%;
    left: 53.4%;
  }
}
.app-ys-yg-yq {
  position: relative;
  height: 11rem /* 439/37 */;
  background-color: #f1f9ffff;
  padding-top: 0.810811rem /* 30/37 */;
  .app-ys-yg-yq-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5%;
    width: 4.594595rem /* 170/37 */;
    height: 0.486486rem /* 18/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
  }
  .app-ys-yg-yq-dx {
    margin-top: 0.810811rem /* 30/37 */;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    .app-ys-yg-yq-dx-img {
      display: inline-block;
      .app-ys-dx {
        width: 6.783784rem /* 251/37 */;
        height: 7.837838rem /* 290/37 */;
      }
    }
  }
  .app-ys-yg-yq-but1 {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    position: absolute;
    top: 85%;
    left: 7%;
  }
  .app-ys-yg-yq-but2 {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    position: absolute;
    top: 85%;
    left: 55%;
  }
}
.app-ys-fa {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 10.783784rem /* 399/37 */;
  .app-ys-fangan {
    width: 10.135135rem /* 375/37 */;
    height: 10.783784rem /* 399/37 */;
  }
  .app-ys-fangan-but1 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 83%;
    left: 7%;
  }
  .app-ys-fangan-but2 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 83%;
    left: 54%;
  }
}
.app-ys-yp-fw {
  width: 10.135135rem /* 375/37 */;
  height: 6rem /* 222/37 */;
}
.ys-yp-but {
  display: flex;
  justify-content: space-between;
  padding-left: 0.675676rem /* 25/37 */;
  padding-right: 0.675676rem /* 25/37 */;
  height: 2.108108rem /* 78/37 */;
  align-items: center;
  background-color: rgb(237, 239, 243);
  .ys-yp-but1 {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
  }
}
.ys-bh {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 16.783784rem /* 621/37 */;
  .ys-bh-img {
    width: 10.135135rem /* 375/37 */;
    height: 16.783784rem /* 621/37 */;
  }
  .ys-bh-but1 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 90.2%;
    left: 6.8%;
    border-radius: 0.540541rem /* 20/37 */;
  }
  .ys-bh-but2 {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 90.2%;
    left: 53.5%;
    border-radius: 0.540541rem /* 20/37 */;
  }
}
.ys-fd-ys {
  width: 10.135135rem /* 375/37 */;
  height: 15.324324rem /* 567/37 */;
}
</style>
