<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="tf-bg">
      <img class="tf-bg" src="../../assets/tf-bj.png" alt="" @click="consult" />
    </div>
    <!-- 托福考试培训为什么选择半海 -->
    <div class="tf-why-bh">
      <img class="tf-why-bh" src="../../assets/tf-why-bh.png" alt="" />
      <div class="tf-why-bh-but1" @click="consult"></div>
      <div class="tf-why-bh-but2" @click="consult"></div>
    </div>
    <!-- 学习托福遇到的问题 -->
    <div class="tf-study-question">
      <img class="tf-study-question-img" src="../../assets/tf-study-question.png" alt="" />
      <div class="tf-study-question-but1" @click="consult"></div>
      <div class="tf-study-question-but2" @click="consult"></div>
    </div>
    <!-- 阶梯型模式学习托福 -->
    <div class="tf-study-mode">
      <img class="tf-study-mode" src="../../assets/tf-study-mode.png" alt="" />
      <div class="tf-study-mode-but1" @click="consult"></div>
      <div class="tf-study-mode-but2" @click="consult"></div>
      <div class="tf-study-mode-but3" @click="consult"></div>
      <div class="tf-study-mode-but4" @click="consult"></div>
      <div class="tf-study-mode-but5" @click="consult"></div>
      <div class="tf-study-mode-but6" @click="consult"></div>
    </div>
    <!-- 完善的托福教学闭环服务 -->
    <div class="tf-ws-jx">
      <img class="tf-ws-jx-img" src="../../assets/tf-ws-jx.png" alt="" />
      <div class="tf-ws-jx-but1" @click="consult"></div>
      <div class="tf-ws-jx-but2" @click="consult"></div>
    </div>
    <!-- 托福学习中常见问题 -->
    <div class="tf-cj-question">
      <img class="tf-cj-question-img" src="../../assets/tf-cj-question.png" alt="" />
      <div class="tf-cj-question-div1" @click="consult"></div>
      <div class="tf-cj-question-div2" @click="consult"></div>
      <div class="tf-cj-question-div3" @click="consult"></div>
      <div class="tf-cj-question-div4" @click="consult"></div>
      <div class="tf-cj-question-div5" @click="consult"></div>
      <div class="tf-cj-question-div6" @click="consult"></div>
      <div class="tf-cj-question-div7" @click="consult"></div>
      <div class="tf-cj-question-div8" @click="consult"></div>
      <div class="tf-cj-question-div9" @click="consult"></div>
      <div class="tf-cj-question-div10" @click="consult"></div>
      <div class="tf-cj-question-but1" @click="consult"></div>
      <div class="tf-cj-question-but2" @click="consult"></div>
    </div>
    <!-- 托福报名流程很复杂 -->
    <div class="tf-bm-lc">
      <img class="tf-bm-lc" src="../../assets/tf-bm-lc.png" alt="" />
      <div class="tf-bm-lc-but1" @click="consult"></div>
      <div class="tf-bm-lc-but2" @click="consult"></div>
    </div>
    <!-- 半海学习服务双闭环 -->
    <div class="tf-sbh-study">
      <img class="tf-sbh-study" src="../../assets/tf-sbh-study.png" alt="" />
      <div class="tf-sbh-study-but1" @click="consult"></div>
      <div class="tf-sbh-study-but2" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="tf-teacher">
      <img class="tf-teacher-logo" src="../../assets/teacher-title-tf.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 半海辅导优势 -->
    <img class="allday-bh-ys" src="../../assets/allday-bh-ys.png" alt="" />

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {showRouter: false, show: false, numberToCopy: 19821616277};
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.tf-bg {
  height: 11.081081rem /* 410/37 */;
  width: 10.135135rem /* 375/37 */;
}
.tf-why-bh {
  position: relative;
  height: 13rem /* 481/37 */;
  width: 10.135135rem /* 375/37 */;
  .tf-why-bh-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 88%;
    left: 6.6%;
  }
  .tf-why-bh-but2 {
    position: absolute;
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 88%;
    left: 53.5%;
  }
}
.tf-study-question {
  height: 10.189189rem /* 377/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // background-color: rgb(132, 132, 132);
  .tf-study-question-img {
    height: 9.108108rem /* 337/37 */;
    width: 9.324324rem /* 345/37 */;
  }
  .tf-study-question-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 85%;
    left: 6.6%;
  }
  .tf-study-question-but2 {
    position: absolute;
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 85%;
    left: 53%;
  }
}
.tf-study-mode {
  position: relative;
  height: 24.405405rem /* 903/37 */;
  width: 10.135135rem /* 375/37 */;
  .tf-study-mode-but1 {
    position: absolute;
    height: 4.162162rem /* 154/37 */;
    width: 4.459459rem /* 165/37 */;
    border-radius: 0.27027rem /* 10/37 */;
    top: 7.3%;
    left: 4%;
  }
  .tf-study-mode-but2 {
    position: absolute;
    height: 4.162162rem /* 154/37 */;
    width: 4.459459rem /* 165/37 */;
    border-radius: 0.27027rem /* 10/37 */;
    top: 7.3%;
    left: 52%;
  }
  .tf-study-mode-but3 {
    position: absolute;
    height: 4.162162rem /* 154/37 */;
    width: 4.459459rem /* 165/37 */;
    border-radius: 0.27027rem /* 10/37 */;
    top: 26%;
    left: 4%;
  }
  .tf-study-mode-but4 {
    position: absolute;
    height: 4.162162rem /* 154/37 */;
    width: 4.459459rem /* 165/37 */;
    border-radius: 0.27027rem /* 10/37 */;
    top: 26%;
    left: 52%;
  }
  .tf-study-mode-but5 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 93.5%;
    left: 6.6%;
  }
  .tf-study-mode-but6 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 93.5%;
    left: 53.5%;
  }
}
.tf-ws-jx {
  height: 16.702703rem /* 618/37 */;
  width: 10.135135rem /* 375/37 */;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .tf-ws-jx-img {
    height: 15.621622rem /* 578/37 */;
    width: 9.378378rem /* 347/37 */;
  }
  .tf-ws-jx-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90.6%;
    left: 6.5%;
  }
  .tf-ws-jx-but2 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90.6%;
    left: 53%;
  }
}
.tf-cj-question {
  height: 16.135135rem /* 597/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .tf-cj-question-img {
    height: 15.054054rem /* 557/37 */;
    width: 9.324324rem /* 345/37 */;
  }
  .tf-cj-question-div1 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 11.5%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div2 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 19.6%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div3 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 27%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div4 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div5 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div6 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div7 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 58%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div8 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 65.5%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div9 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 73%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-div10 {
    position: absolute;
    height: 1rem /* 37/37 */;
    width: 9.324324rem /* 345/37 */;
    top: 81%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tf-cj-question-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90%;
    left: 6.5%;
  }
  .tf-cj-question-but2 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90%;
    left: 53.5%;
  }
}
.tf-bm-lc {
  height: 20.3125rem;
  width: 10.135135rem /* 375/37 */;
  position: relative;
  .tf-bm-lc-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 82%;
    left: 6.5%;
  }
  .tf-bm-lc-but2 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 82%;
    left: 53.5%;
  }
}
.tf-sbh-study {
  height: 11.648649rem /* 431/37 */;
  width: 10.135135rem /* 375/37 */;
  position: relative;
  .tf-sbh-study-but1 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 86.5%;
    left: 6.5%;
  }
  .tf-sbh-study-but2 {
    position: absolute;
    height: 1.081081rem /* 40/37 */;
    width: 4.054054rem /* 150/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 86.5%;
    left: 53.5%;
  }
}
.tf-teacher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.27027rem /* 10/37 */;
  margin-top: 0.540541rem /* 20/37 */;
  .tf-teacher-logo {
    width: 5.675676rem /* 210/37 */;
    height: 1.459459rem /* 54/37 */;
    margin-bottom: 0.459459rem /* 17/37 */;
  }
  .ig-teacher-swp {
    height: 10.27027rem /* 380/37 */;
    width: 10.135135rem /* 375/37 */;
    .van-swipe__indicator {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(213, 214, 215) !important;
    }
    .van-swipe__indicator--active {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(96, 158, 273) !important;
    }
  }
  .teacher-swp-item-ig {
    width: 9.432432rem /* 349/37 */;
    height: 9.189189rem /* 340/37 */;
    margin-left: 0.378378rem /* 14/37 */;
  }
}
</style>
