<template>
  <div>
    <div class="router-div">
      <div class="router-item">
        <router-link :to="{path: '/'}" class="router-link" :class="{'active-link': isActive('/')}">
          <div class="item">
            <div><img class="router-img" src="../assets/rou-img1.png" alt="" /></div>
            <div>首页</div>
          </div>
        </router-link>
      </div>
      <div class="router-item">
        <router-link :to="{path: '/al'}" class="router-link" :class="{'active-link': isActive('/al')}">
          <div class="item">
            <div><img class="router-img" src="../assets/rou-img2.png" alt="" /></div>
            <div>A-Level</div>
          </div>
        </router-link>
      </div>
      <div class="router-item">
        <router-link :to="{path: '/ig'}" class="router-link" :class="{'active-link': isActive('/ig')}">
          <div class="item">
            <div><img class="router-img" src="../assets/rou-img3.png" alt="" /></div>
            <div>IGCSE</div>
          </div>
        </router-link>
      </div>
      <div class="router-item">
        <div class="item">
          <van-cell is-link @click="showRouFun">
            <div><img class="router-img" src="../assets/rou-img2.png" alt="" /></div>
            更多</van-cell
          >
        </div>
      </div>
    </div>
    <van-popup v-model="showRouter" position="top" :style="{height: '25%', marginTop: '3.513514rem'}">
      <div class="router-div">
        <div class="router-item">
          <router-link :to="{path: '/g5'}" class="router-link" :class="{'active-link': isActive('/g5')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img5.png" alt="" /></div>
              <div>G5</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link :to="{path: '/allday'}" class="router-link" :class="{'active-link': isActive('/allday')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img6.png" alt="" /></div>
              <div>A-Level全日制</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link :to="{path: '/ap'}" class="router-link" :class="{'active-link': isActive('/ap')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img7.png" alt="" /></div>
              <div>AP</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link :to="{path: '/ib'}" class="router-link" :class="{'active-link': isActive('/ib')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img8.png" alt="" /></div>
              <div>IB</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="router-div">
        <div class="router-item">
          <router-link :to="{path: '/english'}" class="router-link" :class="{'active-link': isActive('/english')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img9.png" alt="" /></div>
              <div>雅思</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link :to="{path: '/language'}" class="router-link" :class="{'active-link': isActive('/language')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img10.png" alt="" /></div>
              <div>托福</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link
            :to="{path: '/competition'}"
            class="router-link"
            :class="{'active-link': isActive('/competition')}"
          >
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img11.png" alt="" /></div>
              <div>国际竞赛</div>
            </div>
          </router-link>
        </div>
        <div class="router-item">
          <router-link :to="{path: '/about'}" class="router-link" :class="{'active-link': isActive('/about')}">
            <div class="item">
              <div><img class="router-img" src="../assets/rou-img12.png" alt="" /></div>
              <div>关于我们</div>
            </div>
          </router-link>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  props: {},

  data() {
    return {
      showRouter: false
    };
  },

  methods: {
    showRouFun() {
      this.showRouter = true;
    },

    isActive(routePath) {
      return this.$route.path === routePath;
    }
  }
};
</script>
<style>
.item {
  min-width: 2.432432rem /* 90/37 */; /* 为了保证内容有足够的宽度 */
  margin-right: 0.27027rem /* 10/37 */; /* 可以根据实际需要调整间距 */
  height: 1.621622rem /* 60/37 */;
  font-size: 0.324324rem /* 12/37 */;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.27027rem /* 10/37 */;
  margin-left: -0.081081rem /* 3/37 */;
}
.router-div {
  display: flex;
  width: 10.135135rem /* 375/37 */;
  justify-content: space-evenly;
}

.router-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -0.081081rem /* 3/37 */;
  height: 1.621622rem /* 60/37 */;
}
.router-img {
  height: 0.648649rem /* 24/37 */;
  width: 0.648649rem /* 24/37 */;
}

.active-link {
  /* 添加高亮样式，比如修改链接文本颜色、背景色等 */
  color: #163fe0; /* 举例: 将文本颜色为红色 */
}
a {
  color: #333333;
}
</style>
