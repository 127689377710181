<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景页面 -->
    <div class="ap-bj">
      <img v-lazy="imageUrl" class="ap-bj" src="../../pc-imgs/ap-bj-new.png" alt="" @click="consult" />
    </div>
    <!-- AP课程知多少 -->
    <div class="ap-kc-zds">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ap-kc-zds-title">
            <img class="ap-kc-zds-title-img" src="../../pc-imgs/ap-kc-zds-title-img.png" alt="" />
          </div>
          <div class="ap-kc-zds-context">
            <div class="ap-kc-zds-top">
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img1.png" alt="" @click="consult" />
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img2.png" alt="" @click="consult" />
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img3.png" alt="" @click="consult" />
            </div>
            <div class="ap-kc-zds-top">
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img4.png" alt="" @click="consult" />
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img5.png" alt="" @click="consult" />
              <img class="ap-kc-zds-img" src="../../pc-imgs/ap-kc-zds-img6.png" alt="" @click="consult" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- AP科目介绍 -->
    <div class="ap-km-js">
      <img class="ap-km-js" src="../../pc-imgs/ap-km-js.png" alt="" />
      <div class="ap-km-js-but" @click="consult"></div>
    </div>
    <!-- AP考试选课推荐 -->
    <img class="ap-ks-tj" src="../../pc-imgs/ap-ks-tj.png" alt="" />
    <!-- 半海AP课程设置 -->
    <div class="pc-ap-kc-sz">
      <img class="pc-ap-kc-sz-img" src="../../pc-imgs/ap-kc-sz.png" alt="" />
      <div class="pc-ap-kc-sz-but1" @click="consult"></div>
      <div class="pc-ap-kc-sz-but2" @click="consult"></div>
    </div>
    <div class="new-al-teacher">
      <img class="new-al-teacher-img" src="../../pc-imgs/ap-teacher.png" alt="" @click="consult" />
      <div class="new-al-teacher-img-but1" @click="consult"></div>
      <div class="new-al-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 澜大半海优势 -->
    <img class="new-al-landa-bh-ys" src="../../pc-imgs/landa-bh-ys.png" alt="" />
    <!-- 校区环境 -->
    <div class="home-xq-hj">
      <img class="home-xq-hj-img" src="../../pc-imgs/home-xq-hj.png" alt="" />
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';

export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="less">
.ap-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
}
.ap-kc-zds {
  margin-top: 0.352857rem;
  height: auto;
  width: 100%;
  padding-top: 0.25rem /* 35/140 */;
  padding-bottom: 0.214286rem /* 30/140 */;
  background-color: #f5f5f5ff;
  .ap-kc-zds-title {
    height: 0.185714rem /* 26/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.178571rem /* 25/140 */;
    .ap-kc-zds-title-img {
      height: 0.185714rem /* 26/140 */;
    }
  }
  .ap-kc-zds-context {
    height: auto;
    width: 100%;

    .ap-kc-zds-top {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .ap-kc-zds-img {
        height: auto;
        width: 32%;
        transition: transform 0.3s ease; /* 添加过渡效果 */
        cursor: pointer;
      }
      .ap-kc-zds-img:hover {
        transform: translateY(-20px);
      }
    }
  }
}
.ap-km-js {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  .ap-km-js-but {
    position: absolute;
    cursor: pointer;
    height: 0.285714rem /* 40/140 */;
    width: 1.428571rem /* 200/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 90.8%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.ap-ks-tj {
  height: auto;
  width: 100%;
}
.pc-ap-kc-sz {
  position: relative;
  height: 5.378571rem /* 753/140 */;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pc-ap-kc-sz-img {
    height: 4.95rem /* 693/140 */;
  }
  .pc-ap-kc-sz-but1 {
    position: absolute;
    height: 0.285714rem /* 40/140 */;
    width: 1.285714rem /* 180/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 33.5%;
    cursor: pointer;
  }
  .pc-ap-kc-sz-but2 {
    position: absolute;
    height: 0.285714rem /* 40/140 */;
    width: 1.3rem /* 182/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 53.3%;
    cursor: pointer;
  }
}
.new-al-teacher {
  position: relative;
  height: 5.607143rem /* 785/140 */;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-al-teacher-img {
  height: 5.107143rem /* 715/140 */;
}
.new-al-teacher-img-but1 {
  position: absolute;
  width: 1.321429rem /* 185/140 */;
  height: 0.271429rem /* 38/140 */;
  border-radius: 0.142857rem /* 20/140 */;

  top: 91%;
  left: 34%;
  cursor: pointer;
}
.new-al-teacher-img-but2 {
  position: absolute;
  width: 1.321429rem /* 185/140 */;
  height: 0.271429rem /* 38/140 */;
  border-radius: 0.142857rem /* 20/140 */;

  top: 91%;
  left: 52.6%;
  cursor: pointer;
}
</style>
