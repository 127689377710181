<template>
  <div class="pc-home">
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>
    <div class="a"></div>
    <!-- 背景页面 -->
    <div class="home-bj">
      <img v-lazy="imageUrl" class="home-bj" src="../../pc-imgs/home-bj.png" alt="" @click="consult" />
    </div>
    <!-- 关于半海 -->
    <div class="home-about-bh">
      <img class="home-about-bh-img" src="../../pc-imgs/home-about-bh-img.png" alt="" />
      <div class="home-about-bh-but" @click="consult"></div>
    </div>
    <!-- 我们提供哪些服务 -->
    <div class="home-we-tg-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="home-we-tg-fw-title">
            <img class="home-we-tg-fw-title-img" src="../../pc-imgs/home-we-tg-fw-title.png" alt="" />
          </div>
          <div class="home-we-tg-fw-but">
            <div @click="tgFwFun1" :class="[tgFw1 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              A-Level 课程
            </div>
            <div @click="tgFwFun2" :class="[tgFw2 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              IGCSE课程
            </div>
            <div @click="tgFwFun3" :class="[tgFw3 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              IB课程
            </div>
            <div @click="tgFwFun4" :class="[tgFw4 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              AP课程
            </div>
            <div @click="tgFwFun5" :class="[tgFw5 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              国际竞赛
            </div>
            <div @click="tgFwFun6" :class="[tgFw6 ? 'home-we-tg-fw-but-samll' : 'home-we-tg-fw-but-samll-def']">
              英国本科申请
            </div>
          </div>
          <img v-show="tgFw1" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img1.png" alt="" />
          <img v-show="tgFw2" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img2.png" alt="" />
          <img v-show="tgFw3" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img3.png" alt="" />
          <img v-show="tgFw4" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img4.png" alt="" />
          <img v-show="tgFw5" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img5.png" alt="" />
          <img v-show="tgFw6" class="home-we-tg-fw-img" src="../../pc-imgs/home-we-tg-fw-img6.png" alt="" />
          <div class="home-we-tg-fw-bottom-but">
            <img class="home-we-tg-fw-bottom-but1" src="../../pc-imgs/home-but1.png" alt="" @click="consult" />
            <img class="home-we-tg-fw-bottom-but2" src="../../pc-imgs/home-but2.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 教学团队 -->
    <div class="home-teacher">
      <img class="home-teacher-img" src="../../pc-imgs/home-teacher-img.png" alt="" @click="consult" />
      <div class="home-teacher-img-but1" @click="consult"></div>
      <div class="home-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 澜大半海优势 -->
    <img class="home-ld-bh-ys" src="../../pc-imgs/home-ld-bh-ys.png" alt="" />
    <!-- 细致服务 -->
    <div class="ib-xz-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ib-xz-fw-title">细致入微贴心服务</div>
          <div class="ib-xz-fw-div">
            <div class="ib-xz-fw-div1">
              <div class="ib-xz-fw-div1-small">
                <div class="ib-xz-fw-div1-small-text">到离校通知家长</div>
              </div>
              <div class="ib-xz-fw-div2-small">
                <div class="ib-xz-fw-div2-small-text">测试问题必反馈</div>
              </div>
              <div class="ib-xz-fw-div3-small">
                <div class="ib-xz-fw-div3-small-text">上课收手机专心学习</div>
              </div>
            </div>
            <div class="ib-xz-fw-div1">
              <div class="ib-xz-fw-div4-small">
                <div class="ib-xz-fw-div4-small-text">课堂内容详细反馈全面了解</div>
              </div>
              <div class="ib-xz-fw-div5-small">
                <div class="ib-xz-fw-div5-small-text">学习记录实时跟踪</div>
              </div>
              <div class="ib-xz-fw-div6-small">
                <div class="ib-xz-fw-div6-small-text">课后任务细化快乐学习</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <!-- 服务学员来自全国国际学校 -->
    <img class="ib-fw-qg-xy" src="../../pc-imgs/ib-fw-qg-xy.png" alt="" />
    <!-- 校区环境 -->
    <div class="home-xq-hj">
      <img class="home-xq-hj-img" src="../../pc-imgs/home-xq-hj.png" alt="" />
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      tgFw1: true,
      tgFw2: false,
      tgFw3: false,
      tgFw4: false,
      tgFw5: false,
      tgFw6: false
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    tgFwFun1() {
      this.tgFw1 = true;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun2() {
      this.tgFw1 = false;
      this.tgFw2 = true;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun3() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = true;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun4() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = true;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun5() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = true;
      this.tgFw6 = false;
    },
    tgFwFun6() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = true;
    }
  }
};
</script>

<style lang="less">
.pc-home {
  .home-bj {
    margin-top: 0.352857rem;
    height: 3.714286rem /* 520/140 */;
    width: 10.285714rem /* 1440/140 */;
    cursor: pointer;
  }
  .home-about-bh {
    margin-top: 0.352857rem;
    position: relative;
    height: 2.2rem;
    width: 10.285714rem /* 1440/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
    .home-about-bh-img {
      height: 1.771429rem /* 248/140 */;
    }
    .home-about-bh-but {
      position: absolute;
      width: 1.428571rem /* 200/140 */;
      height: 0.257143rem /* 36/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 78.8%;
      left: 50%;
      transform: translatex(-50%);
      cursor: pointer;
    }
  }
  .home-we-tg-fw {
    padding-top: 0.214286rem /* 30/140 */;
    padding-bottom: 0.142857rem /* 20/140 */;
    background-color: #60b36dff;
    width: 10.285714rem /* 1440/140 */;
    .home-we-tg-fw-title {
      height: 0.728571rem /* 102/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 0.142857rem /* 20/140 */;
      .home-we-tg-fw-title-img {
        height: 0.728571rem /* 102/140 */;
      }
    }
    .home-we-tg-fw-but {
      height: 0.342857rem /* 48/140 */;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      line-height: 0.342857rem /* 48/140 */;
      background-color: #fdc526ff;
      .home-we-tg-fw-but-samll {
        width: 14%;
        background-color: #fff;
        text-align: center;
        height: 0.285714rem /* 40/140 */;
        line-height: 0.285714rem /* 40/140 */;
        margin-top: 0.05rem /* 7/140 */;
        border-radius: 0.071429rem /* 10/140 */ 0.071429rem /* 10/140 */ 0 0;
        font-size: 0.1rem /* 14/140 */;
        font-weight: bold;
      }
      .home-we-tg-fw-but-samll-def {
        width: 14%;
        text-align: center;
        line-height: 0.35rem /* 49/140 */;
        font-size: 0.1rem /* 14/140 */;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .home-we-tg-fw-img {
      width: 100%;
    }
    .home-we-tg-fw-bottom-but {
      position: relative;
      width: 100%;
      height: 60px;
      .home-we-tg-fw-bottom-but1 {
        height: 0.257143rem /* 36/140 */;
        position: absolute;
        top: 35%;
        left: 17%;
        cursor: pointer;
      }
      .home-we-tg-fw-bottom-but2 {
        height: 0.257143rem /* 36/140 */;
        position: absolute;
        top: 35%;
        left: 60%;
        cursor: pointer;
      }
    }
  }
  .home-teacher {
    position: relative;
    height: 5.607143rem /* 785/140 */;
    width: 100%;
    padding-top: 0.285714rem /* 40/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
    .home-teacher-img {
      height: 5.107143rem /* 715/140 */;
    }
    .home-teacher-img-but1 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91.4%;
      left: 33.4%;
      cursor: pointer;
    }
    .home-teacher-img-but2 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91.4%;
      left: 53%;
      cursor: pointer;
    }
  }
  .home-xq-hj {
    height: 2.071429rem /* 290/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .home-xq-hj-img {
      height: 1.685714rem /* 236/140 */;
    }
  }
  .home-ld-bh-ys {
    height: auto;
    width: 100%;
  }
  .ib-fw-qg-xy {
    height: auto;
    width: 100%;
  }
  .ib-xz-fw {
    height: 4.407143rem /* 617/140 */;
    width: 100%;
    padding-top: 0.242857rem /* 34/140 */;
    padding-bottom: 0.171429rem /* 24/140 */;
    font-size: 0.114286rem /* 16/140 */;
    .ib-xz-fw-title {
      height: 0.428571rem /* 60/140 */;
      width: 100%;
      display: flex;
      align-items: center;
      font-family: HelloFont, HelloFont;
      font-weight: bold;
      font-size: 0.228571rem /* 32/140 */;
      color: #333333;
      flex-direction: column;
      line-height: 0.285714rem /* 40/140 */;
      .ib-xz-fw-title-hx {
        margin-top: 0.064286rem /* 9/140 */;
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #46af43;
      }
    }
    .ib-xz-fw-div {
      height: 3.642857rem /* 510/140 */;
      width: 100%;
      .ib-xz-fw-div1 {
        display: flex;
        width: 100%;
        height: 1.75rem /* 245/140 */;
        margin-bottom: 0.057143rem /* 8/140 */;
        .ib-xz-fw-div1-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img1.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ib-xz-fw-div1-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div2-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img2.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div2-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ib-xz-fw-div2-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div3-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img3.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div3-small-text {
            position: absolute;
            top: 84%;
            left: 24%;
          }
        }
        .ib-xz-fw-div3-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div4-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img4.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div4-small-text {
            position: absolute;
            top: 84%;
            left: 11%;
          }
        }
        .ib-xz-fw-div4-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div5-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img5.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div5-small-text {
            position: absolute;
            top: 84%;
            left: 24%;
          }
        }
        .ib-xz-fw-div5-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div6-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img6.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div6-small-text {
            position: absolute;
            top: 84%;
            left: 21%;
          }
        }
        .ib-xz-fw-div6-small:hover {
          color: #46af43ff;
        }
      }
    }
  }
}
</style>
