var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('Narbar'),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.imageUrl),expression:"imageUrl"}],staticClass:"new-about-bj",attrs:{"src":require("../../pc-imgs/new-about-bj.png"),"alt":""}}),_vm._m(0),_c('img',{staticClass:"new-about-ts",attrs:{"src":require("../../pc-imgs/new-about-ts.png"),"alt":""}}),_c('div',{staticClass:"new-lx-us"},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}}),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"new-lx-us-title"},[_c('img',{staticClass:"new-lx-us-title-img",attrs:{"src":require("../../pc-imgs/new-lx-us-title.png"),"alt":""}})]),_c('div',{staticClass:"new-lx-us-but"},[_c('div',{staticClass:"new-lx-us-but-samll",style:({
              background: _vm.env1 ? '#2c52e3ff' : '',
              color: _vm.env1 ? '#fff' : '#333',
              cursor: _vm.env1 ? '' : 'pointer'
            }),on:{"click":_vm.envFun1}},[_vm._v(" 人广校区 ")]),_c('div',{staticClass:"new-lx-us-but-samll",style:({
              background: _vm.env2 ? '#2c52e3ff' : '',
              color: _vm.env2 ? '#fff' : '#333',
              cursor: _vm.env2 ? '' : 'pointer'
            }),on:{"click":_vm.envFun2}},[_vm._v(" 徐汇校区 ")]),_c('div',{staticClass:"new-lx-us-but-samll",style:({
              background: _vm.env3 ? '#2c52e3ff' : '',
              color: _vm.env3 ? '#fff' : '#333',
              cursor: _vm.env3 ? '' : 'pointer'
            }),on:{"click":_vm.envFun3}},[_vm._v(" 杨浦校区 ")]),_c('div',{staticClass:"new-lx-us-but-samll",style:({
              background: _vm.env4 ? '#2c52e3ff' : '',
              color: _vm.env4 ? '#fff' : '#333',
              cursor: _vm.env4 ? '' : 'pointer'
            }),on:{"click":_vm.envFun4}},[_vm._v(" 浦东校区 ")])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.env1),expression:"env1"}],staticClass:"new-env-img",attrs:{"src":require("../../pc-imgs/new-env-img1.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.env2),expression:"env2"}],staticClass:"new-env-img",attrs:{"src":require("../../pc-imgs/new-env-img2.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.env3),expression:"env3"}],staticClass:"new-env-img",attrs:{"src":require("../../pc-imgs/new-env-img3.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.env4),expression:"env4"}],staticClass:"new-env-img",attrs:{"src":require("../../pc-imgs/new-env-img4.png"),"alt":""}})]),_c('el-col',{attrs:{"span":6}})],1)],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-about-bh"},[_c('img',{staticClass:"new-about-bh-img",attrs:{"src":require("../../pc-imgs/new-about-bh.png"),"alt":""}})])
}]

export { render, staticRenderFns }