<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="com-bg">
      <img class="com-bg" src="../../assets/com-bg.png" alt="" @click="consult" />
    </div>
    <!-- 2024国际竞赛备考早知 -->
    <div :class="classJk">
      <img class="gjjs-title" src="../../assets/gjjs-title.png" alt="" />
      <div class="com-js-but">
        <div
          @click="jsFun1"
          class="com-js-but1"
          :style="{backgroundColor: js1 ? '#408cfeFF' : '#fff', color: js1 ? '#fff' : '#000'}"
        >
          9/10/11年级
        </div>
        <div
          @click="jsFun2"
          class="com-js-but1"
          :style="{backgroundColor: js2 ? '#408cfeFF' : '#fff', color: js2 ? '#fff' : '#000'}"
        >
          11年级
        </div>
        <div
          @click="jsFun3"
          class="com-js-but1"
          :style="{backgroundColor: js3 ? '#408cfeFF' : '#fff', color: js3 ? '#fff' : '#000'}"
        >
          12年级
        </div>
        <div
          @click="jsFun4"
          class="com-js-but1"
          :style="{backgroundColor: js4 ? '#408cfeFF' : '#fff', color: js4 ? '#fff' : '#000'}"
        >
          13年级
        </div>
      </div>
      <img v-show="js1" class="com-js-img" src="../../assets/com-js-img1.png" alt="" />
      <img v-show="js2" class="com-js-im2" src="../../assets/com-js-img2.png" alt="" />
      <img v-show="js3" class="com-js-im2" src="../../assets/com-js-img3.png" alt="" />
      <img v-show="js4" class="com-js-im4" src="../../assets/com-js-img4.png" alt="" />
      <div class="com-js-mobile-but1" @click="callMobile" v-show="!js4">电话：400-068-5018</div>
      <div class="com-js-zx-but2" @click="consult" v-show="!js4">在线咨询客服</div>
      <div class="com-js-mobile-but4" @click="callMobile" v-show="js4">电话：400-068-5018</div>
      <div class="com-js-zx-but4" @click="consult" v-show="js4">在线咨询客服</div>
      <div class="com-js-mobile-but" @click="callMobile"></div>
      <div class="com-js-zx-but" @click="consult"></div>
    </div>
    <!-- 为什么要参加国际竞赛 -->
    <div class="com-why">
      <img class="com-why" src="../../assets/com-why.png" alt="" />
      <div class="com-why-but1" @click="callMobile"></div>
      <div class="com-why-but2" @click="consult"></div>
      <div class="com-why-but3" @click="callMobile"></div>
      <div class="com-why-but4" @click="consult"></div>
    </div>
    <!-- 半海国际竞赛为什么专业 -->
    <div class="com-bh-zy">
      <img class="com-zy-title" src="../../assets/com-zy.png" alt="" />
      <div class="com-zy-but-top">
        <div
          @click="zyFun1"
          class="com-zy-but1"
          :style="{backgroundColor: zy1 ? '#408cfeFF' : '#fff', color: zy1 ? '#fff' : '#000'}"
        >
          长周期规划
        </div>
        <div
          @click="zyFun2"
          class="com-zy-but1"
          :style="{backgroundColor: zy2 ? '#408cfeFF' : '#fff', color: zy2 ? '#fff' : '#000'}"
        >
          规范化服务
        </div>
      </div>
      <img class="com-zy-title2" v-show="zy1" src="../../assets/com-zy-title2.png" alt="" />
      <img class="com-zy-title3" v-show="zy2" src="../../assets/com-gf-title2.png" alt="" />
      <img class="com-zy-img1" v-show="zy1" src="../../assets/com-zy-img1.png" alt="" />
      <img class="com-zy-img2" v-show="zy2" src="../../assets/com-zy-img2.png" alt="" />
      <div class="com-zy-but3" @click="callMobile" v-show="zy1"></div>
      <div class="com-zy-but4" @click="consult" v-show="zy1"></div>
      <div class="com-zy-but-t" v-show="zy2">
        <div class="com-zy-but3-a" @click="callMobile" v-show="zy2">电话：400-068-5018</div>
        <div class="com-zy-but4-a" @click="consult" v-show="zy2">在线咨询客服</div>
      </div>
    </div>
    <!-- 国际考试日期 -->
    <div class="com-ks-date">
      <img class="com-js-date-title" src="../../assets/com-js-date-title.png" alt="" />
      <div class="horizontal-scroll-container">
        <div class="scroll-content">
          <img class="com-ct" src="../../assets/com-ct.jpg" alt="" />
        </div>
      </div>
      <div class="com-js-date-but1" @click="callMobile">电话：400-068-5018</div>
      <div class="com-js-date-but2" @click="consult">在线咨询客服</div>
    </div>
    <!-- 半海课程竞赛课程设计 -->
    <div class="com-design">
      <img class="com-design-title" src="../../assets/com-design-title.png" alt="" />
      <div class="com-design-but">
        <div
          @click="desFun1"
          class="com-design-but1"
          :style="{backgroundColor: design1 ? '#408cfeFF' : '', color: design1 ? '#fff' : '#408cfeFF'}"
        >
          英联邦双语竞赛
        </div>
        <div
          @click="desFun2"
          class="com-design-but2"
          :style="{backgroundColor: design2 ? '#408cfeFF' : '', color: design2 ? '#fff' : '#408cfeFF'}"
        >
          北美双语竞赛
        </div>
      </div>
      <div class="com-design-router" v-show="design1">
        <div class="com-design-router-title">
          <div
            @click="desRouterFun1"
            class="com-design-router1"
            :style="{color: des1Rout1 ? '#408CFEFF' : '#333333FF'}"
          >
            STEP
            <div class="com-design-hx" v-show="des1Rout1"></div>
          </div>
          <div
            @click="desRouterFun2"
            class="com-design-router1"
            :style="{color: des1Rout2 ? '#408CFEFF' : '#333333FF'}"
          >
            MAT
            <div class="com-design-hx1" v-show="des1Rout2"></div>
          </div>
          <div
            @click="desRouterFun3"
            class="com-design-router1"
            :style="{color: des1Rout3 ? '#408CFEFF' : '#333333FF'}"
          >
            PAT
            <div class="com-design-hx1" v-show="des1Rout3"></div>
          </div>
          <div
            @click="desRouterFun4"
            class="com-design-router1"
            :style="{color: des1Rout4 ? '#408CFEFF' : '#333333FF'}"
          >
            BBO
            <div class="com-design-hx1" v-show="des1Rout4"></div>
          </div>
          <div
            @click="desRouterFun5"
            class="com-design-router1"
            :style="{color: des1Rout5 ? '#408CFEFF' : '#333333FF'}"
          >
            BPHO
            <div class="com-design-hx" v-show="des1Rout5"></div>
          </div>
          <div
            @click="desRouterFun6"
            class="com-design-router1"
            :style="{color: des1Rout6 ? '#408CFEFF' : '#333333FF'}"
          >
            BMO
            <div class="com-design-hx" v-show="des1Rout6"></div>
          </div>
          <div
            @click="desRouterFun7"
            class="com-design-router1"
            :style="{color: des1Rout7 ? '#408CFEFF' : '#333333FF'}"
          >
            UKCHO
            <div class="com-design-hx3" v-show="des1Rout7"></div>
          </div>
        </div>
        <img class="des-rou-img1" v-show="des1Rout1" src="../../assets/des-rou-img1.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout2" src="../../assets/des-rou-img2.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout3" src="../../assets/des-rou-img3.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout4" src="../../assets/des-rou-img4.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout5" src="../../assets/des-rou-img5.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout6" src="../../assets/des-rou-img6.png" alt="" />
        <img class="des-rou-img1" v-show="des1Rout7" src="../../assets/des-rou-img7.png" alt="" />
      </div>
      <div class="com-design-router" v-show="design2">
        <div class="com-design-router-title">
          <div
            @click="desRouterFun1"
            class="com-design-router1"
            :style="{color: des1Rout1 ? '#408CFEFF' : '#333333FF'}"
          >
            AMC
            <div class="com-design-hx" v-show="des1Rout1"></div>
          </div>
          <div
            @click="desRouterFun2"
            class="com-design-router2"
            :style="{color: des1Rout2 ? '#408CFEFF' : '#333333FF'}"
          >
            USABO
            <div class="com-design-hx3" v-show="des1Rout2"></div>
          </div>
          <div
            @click="desRouterFun3"
            class="com-design-router2"
            :style="{color: des1Rout3 ? '#408CFEFF' : '#333333FF'}"
          >
            USACO
            <div class="com-design-hx3" v-show="des1Rout3"></div>
          </div>
          <div
            @click="desRouterFun4"
            class="com-design-router2"
            :style="{color: des1Rout4 ? '#408CFEFF' : '#333333FF'}"
          >
            USNCO
            <div class="com-design-hx3" v-show="des1Rout4"></div>
          </div>
          <div
            @click="desRouterFun5"
            class="com-design-router2"
            :style="{color: des1Rout5 ? '#408CFEFF' : '#333333FF'}"
          >
            Physics
            <div class="Bowl">Bowl</div>
            <div class="com-design-hx3" v-show="des1Rout5"></div>
          </div>
          <div
            @click="desRouterFun6"
            class="com-design-router2"
            :style="{color: des1Rout6 ? '#408CFEFF' : '#333333FF'}"
          >
            GAUSS
            <div class="com-design-hx3" v-show="des1Rout6"></div>
          </div>
          <div
            @click="desRouterFun7"
            class="com-design-router2"
            :style="{color: des1Rout7 ? '#408CFEFF' : '#333333FF'}"
          >
            EUCLID
            <div class="com-design-hx3" v-show="des1Rout7"></div>
          </div>
        </div>
        <img class="des1-rou-img1" v-show="des1Rout1" src="../../assets/com-des2-img1.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout2" src="../../assets/com-des2-img2.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout3" src="../../assets/com-des2-img3.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout4" src="../../assets/com-des2-img4.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout5" src="../../assets/com-des2-img5.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout6" src="../../assets/com-des2-img6.png" alt="" />
        <img class="des1-rou-img1" v-show="des1Rout7" src="../../assets/com-des2-img7.png" alt="" />
      </div>
      <div class="com-des-but-bottom">
        <div class="com-des-but1" @click="callMobile">电话：400-068-5018</div>
        <div class="com-des-but2" @click="consult">在线咨询客服</div>
      </div>
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 竞赛难度知识分布图 -->
    <div class="com-ndt">
      <img class="com-nd-title" src="../../assets/com-nd-title.png" alt="" />
      <div class="com-ndt-router">
        <div @click="ndtFun1" class="com-ndt-router1" :style="{color: ndRout1 ? '#438efeFF' : '#000'}">
          数学竞赛
          <div class="com-ndt-hx" v-show="ndRout1"></div>
        </div>
        <div @click="ndtFun2" class="com-ndt-router1" :style="{color: ndRout2 ? '#438efeFF' : '#000'}">
          物理竞赛
          <div class="com-ndt-hx" v-show="ndRout2"></div>
        </div>

        <div @click="ndtFun3" class="com-ndt-router1" :style="{color: ndRout3 ? '#438efeFF' : '#000'}">
          化学竞赛
          <div class="com-ndt-hx" v-show="ndRout3"></div>
        </div>
        <div @click="ndtFun4" class="com-ndt-router1" :style="{color: ndRout4 ? '#438efeFF' : '#000'}">
          生物竞赛
          <div class="com-ndt-hx" v-show="ndRout4"></div>
        </div>
        <div @click="ndtFun5" class="com-ndt-router1" :style="{color: ndRout5 ? '#438efeFF' : '#000'}">
          经济竞赛
          <div class="com-ndt-hx" v-show="ndRout5"></div>
        </div>
      </div>
      <img v-show="ndRout1" class="com-nd-img1" src="../../assets/com-nd-img1.png" alt="" />
      <img v-show="ndRout2" class="com-nd-img1" src="../../assets/com-nd-img2.png" alt="" />
      <img v-show="ndRout3" class="com-nd-img1" src="../../assets/com-nd-img3.png" alt="" />
      <img v-show="ndRout4" class="com-nd-img1" src="../../assets/com-nd-img4.png" alt="" />
      <img v-show="ndRout5" class="com-nd-img1" src="../../assets/com-nd-img5.png" alt="" />
      <div class="com-nd-but1" @click="callMobile">电话：400-068-5018</div>
      <div class="com-nd-but2" @click="consult">在线咨询客服</div>
    </div>
    <!-- 服务流程 -->
    <div class="com-fu">
      <img class="com-fu-img" src="../../assets/com-fu.png" alt="" />
      <div class="com-fu-but1" @click="callMobile"></div>
      <div class="com-fu-but2" @click="consult"></div>
      <div class="com-fu-but3" @click="callMobile"></div>
      <div class="com-fu-but4" @click="consult"></div>
      <div class="com-fu-but5" @click="callMobile"></div>
      <div class="com-fu-but6" @click="consult"></div>
      <div class="com-fu-but7" @click="consult"></div>
      <div class="com-fu-but8" @click="consult"></div>
      <div class="com-fu-but9" @click="consult"></div>
      <div class="com-fu-but10" @click="consult"></div>
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      classJk: 'js-bk',
      showRouter: false,
      show: false,
      js1: true,
      js2: false,
      js3: false,
      js4: false,
      zy1: true,
      zy2: false,
      design1: true,
      design2: false,
      des1Rout1: true,
      des1Rout2: false,
      des1Rout3: false,
      des1Rout4: false,
      des1Rout5: false,
      des1Rout6: false,
      des1Rout7: false,
      ndRout1: true,
      ndRout2: false,
      ndRout3: false,
      ndRout4: false,
      ndRout5: false,
      numberToCopy: 19821616277
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    jsFun1() {
      this.classJk = 'js-bk';
      this.js1 = true;
      this.js2 = false;
      this.js3 = false;
      this.js4 = false;
    },
    jsFun2() {
      this.classJk = 'js-bk';
      this.js1 = false;
      this.js2 = true;
      this.js3 = false;
      this.js4 = false;
    },
    jsFun3() {
      this.classJk = 'js-bk';
      this.js1 = false;
      this.js2 = false;
      this.js3 = true;
      this.js4 = false;
    },
    jsFun4() {
      this.classJk = 'js-bk-a';
      this.js1 = false;
      this.js2 = false;
      this.js3 = false;
      this.js4 = true;
    },
    zyFun1() {
      this.zy1 = true;
      this.zy2 = false;
    },
    zyFun2() {
      this.zy1 = false;
      this.zy2 = true;
    },
    desFun1() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
      this.design1 = true;
      this.design2 = false;
    },
    desFun2() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
      this.design1 = false;
      this.design2 = true;
    },
    desRouterFun1() {
      this.des1Rout1 = true;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun2() {
      this.des1Rout1 = false;
      this.des1Rout2 = true;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun3() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = true;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun4() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = true;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun5() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = true;
      this.des1Rout6 = false;
      this.des1Rout7 = false;
    },
    desRouterFun6() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = true;
      this.des1Rout7 = false;
    },
    desRouterFun7() {
      this.des1Rout1 = false;
      this.des1Rout2 = false;
      this.des1Rout3 = false;
      this.des1Rout4 = false;
      this.des1Rout5 = false;
      this.des1Rout6 = false;
      this.des1Rout7 = true;
    },
    ndtFun1() {
      this.ndRout1 = true;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },
    ndtFun2() {
      this.ndRout1 = false;
      this.ndRout2 = true;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },
    ndtFun3() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = true;
      this.ndRout4 = false;
      this.ndRout5 = false;
    },

    ndtFun4() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = true;
      this.ndRout5 = false;
    },
    ndtFun5() {
      this.ndRout1 = false;
      this.ndRout2 = false;
      this.ndRout3 = false;
      this.ndRout4 = false;
      this.ndRout5 = true;
    }

    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style>
.com-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.js-bk {
  position: relative;
  height: 16.486486rem /* 610/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.810811rem /* 30/37 */;
}
.js-bk-a {
  position: relative;
  height: 7.027027rem /* 260/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.810811rem /* 30/37 */;
}
.gjjs-title {
  width: 5.756757rem /* 213/37 */;
  height: 1.243243rem /* 46/37 */;
  margin-bottom: 0.513514rem /* 19/37 */;
}
.com-js-but {
  display: flex;
  padding-left: 0.324324rem /* 12/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.com-js-but1 {
  margin-right: 0.27027rem /* 10/37 */;
  width: 2.135135rem /* 79/37 */;
  height: 0.810811rem /* 30/37 */;
  background-color: #fff;
  font-size: 0.324324rem /* 12/37 */;
  color: #000;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
}
.com-js-img {
  width: 9.324324rem /* 345/37 */;
  height: 12.432432rem /* 460/37 */;
}
.com-js-im2 {
  width: 9.324324rem /* 345/37 */;
  height: 10.918919rem /* 404/37 */;
}
.com-js-im4 {
  width: 9.324324rem /* 345/37 */;
  height: 2.027027rem /* 75/37 */;
}
.com-js-mobile-but {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 6%;
  top: 89%;
}
.com-js-mobile-but1 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 6%;
  top: 89%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
}
.com-js-zx-but2 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 89%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  font-size: 0.378378rem /* 14/37 */;
  background-color: #fec850ff;
}
.com-js-mobile-but4 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 6%;
  top: 80%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
}
.com-js-zx-but4 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 80%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  font-size: 0.378378rem /* 14/37 */;
  background-color: #fec850ff;
}
.com-js-zx-but1 {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 89%;
}
.com-why {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 40.648649rem /* 1504/37 */;
}
.com-why-but1 {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 6%;
  top: 38.3%;
}
.com-why-but2 {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 38.3%;
}
.com-why-but3 {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 6%;
  top: 96%;
}
.com-why-but4 {
  position: absolute;
  width: 4.162162rem /* 154/37 */;
  height: 1.054054rem /* 39/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 96%;
}
.com-bh-zy {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10.135135rem /* 375/37 */;
  padding-top: 0.810811rem /* 30/37 */;
  background-color: rgb(237, 239, 243);
  padding-bottom: 0.540541rem /* 20/37 */;
}
.com-zy-title {
  width: 5.648649rem /* 209/37 */;
  height: 0.513514rem /* 19/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.com-zy-but-top {
  display: flex;
  margin-bottom: 0.459459rem /* 17/37 */;
}
.com-zy-but1 {
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  background-color: #fff;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  color: #333;
  margin-right: 0.540541rem /* 20/37 */;
}
.com-zy-title2 {
  width: 6.513514rem /* 241/37 */;
  height: 0.351351rem /* 13/37 */;
  margin-bottom: 0.432432rem /* 16/37 */;
}
.com-zy-title3 {
  width: 7.918919rem /* 293/37 */;
  height: 0.810811rem /* 30/37 */;
  margin-bottom: 0.432432rem /* 16/37 */;
}
.com-zy-img1 {
  width: 9.324324rem /* 345/37 */;
  height: 13.162162rem /* 487/37 */;
}
.com-zy-img2 {
  width: 9.324324rem /* 345/37 */;
  height: 17.378378rem /* 643/37 */;
}
.com-zy-but3 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  color: #333;
  margin-right: 0.540541rem /* 20/37 */;
  left: 7%;
  top: 85.5%;
}
.com-zy-but-t {
  height: 1.351351rem /* 50/37 */;
}
.com-zy-but3-a {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  margin-right: 0.540541rem /* 20/37 */;
  left: 7%;
  top: 94%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
}
.com-zy-but4-a {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  margin-right: 0.540541rem /* 20/37 */;
  left: 54%;
  top: 94%;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  background-color: #ffc542ff;
}
.com-zy-but4 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.945946rem /* 35/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  color: #333;
  margin-right: 0.540541rem /* 20/37 */;
  left: 53%;
  top: 85.5%;
}
.com-ks-date {
  position: relative;
  height: 16.756757rem /* 620/37 */;
  background-color: #fdf9dfff;
  padding-top: 0.810811rem /* 30/37 */;
  width: 10.135135rem /* 375/37 */;
}
.com-js-date-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 3%;
  width: 3.837838rem /* 142/37 */;
  height: 0.513514rem /* 19/37 */;
}
.horizontal-scroll-container {
  margin-top: 0.72973rem /* 27/37 */;
  margin-left: 0.405405rem /* 15/37 */;
  margin-right: 0.405405rem /* 15/37 */;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* 使用原生滚动，启用滚动动画 */
}

.scroll-content {
  display: inline-block;
}
.com-ct {
  width: 32.432432rem /* 1200/37 */;
  height: 13.513514rem /* 500/37 */;
}
.com-js-date-but1 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
  border-radius: 0.540541rem /* 20/37 */;
  margin-left: 0.810811rem /* 30/37 */;
  margin-top: 0.27027rem /* 10/37 */;
}
.com-js-date-but2 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fec850ff;
  font-size: 0.378378rem /* 14/37 */;
  color: #000;
  border-radius: 0.540541rem /* 20/37 */;
  margin-left: 5.405405rem /* 200/37 */;
  margin-top: -0.945946rem /* 35/37 */;
}
.com-design {
  width: 10.135135rem /* 375/37 */;
  background: #c0d5f6;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.810811rem /* 30/37 */;
}
.com-design-title {
  width: 7.378378rem /* 273/37 */;
  height: 1.243243rem /* 46/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.com-design-but {
  display: flex;
  position: relative;
  height: 1.432432rem /* 53/37 */;
  width: 10.135135rem /* 375/37 */;
}
.com-design-but1 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  /* background-color: #408cfeff; */
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  color: #428dfeff;
  border: 0.027027rem /* 1/37 */ solid #428dfeff;
  top: 0;
  left: 15%;
  /* color: #fff; */
  font-size: 0.378378rem /* 14/37 */;
}
.com-design-but2 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  color: #428dfeff;
  border: 0.027027rem /* 1/37 */ solid #428dfeff;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  top: 0;
  left: 55%;
  font-size: 0.378378rem /* 14/37 */;
}
.com-design-router {
  width: 9.324324rem /* 345/37 */;
  border-radius: 0.27027rem /* 10/37 */;
  background-color: #f5f5f5ff;
  padding-top: 0.351351rem /* 13/37 */;
  display: flex;
  flex-direction: column;
  margin-left: 0.405405rem /* 15/37 */;
  margin-right: 0.405405rem /* 15/37 */;
  padding-left: 0.135135rem /* 5/37 */;
  padding-right: 0.135135rem /* 5/37 */;
  justify-content: space-between;
}
.com-design-router-title {
  display: flex;
}
.com-design-router1 {
  flex: 1;
  font-size: 0.324324rem /* 12/37 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  flex-direction: column;
}
.com-design-router2 {
  flex: 1.4;
  font-size: 0.324324rem /* 12/37 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  flex-direction: column;
}
.com-design-hx {
  height: 0.081081rem /* 3/37 */;
  width: 60%;
  margin-top: 0.081081rem /* 3/37 */;
  background-color: #408cfeff;
}
.com-design-hx1 {
  height: 0.081081rem /* 3/37 */;
  width: 50%;
  margin-top: 0.081081rem /* 3/37 */;
  background-color: #408cfeff;
}
.com-design-hx3 {
  height: 0.081081rem /* 3/37 */;
  width: 88%;
  margin-top: 0.081081rem /* 3/37 */;
  background-color: #408cfeff;
}
.des-rou-img1 {
  width: 9.297297rem /* 344/37 */;
  margin-bottom: 0.27027rem /* 10/37 */;
}

.com-des-but1 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-des-but2 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fec850ff;
  font-size: 0.378378rem /* 14/37 */;
  color: #000;
  border-radius: 0.540541rem /* 20/37 */;
  margin-left: 0.675676rem /* 25/37 */;
}
.com-des-but-bottom {
  height: 2.162162rem /* 80/37 */;
  display: flex;
  justify-content: space-between;
  background-color: #c0d5f6ff;
  align-items: center;
}
.com-ndt {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 8.648649rem /* 320/37 */;
  background-color: #c0d5f6ff;
  display: flex;
  align-items: center;
  padding-top: 0.594595rem /* 22/37 */;
  flex-direction: column;
}
.com-nd-title {
  width: 4.810811rem /* 178/37 */;
  height: 0.513514rem /* 19/37 */;
  margin-bottom: 0.594595rem /* 22/37 */;
}
.com-ndt-router {
  padding-left: 0.891892rem /* 33/37 */;
  padding-right: 0.405405rem /* 15/37 */;
  display: flex;
}
.com-ndt-router1 {
  position: relative;
  flex: 1;
  font-size: 0.324324rem /* 12/37 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  margin-right: 0.405405rem /* 15/37 */;
}
.com-ndt-hx {
  position: absolute;
  background-color: #5799fcff;
  width: 60%;
  height: 0.081081rem /* 3/37 */;
  margin-top: 0.135135rem /* 5/37 */;
  left: 50%;
  transform: translateX(-50%);
}
.com-nd-img1 {
  height: 4.513514rem /* 167/37 */;
  width: 9.540541rem /* 353/37 */;
  margin-top: 0.540541rem /* 20/37 */;
}
.com-nd-but1 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  color: #ffc542ff;
  border: 0.027027rem /* 1/37 */ solid #ffc542ff;
  border-radius: 0.540541rem /* 20/37 */;
  top: 83%;
  left: 5%;
}
.com-nd-but2 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  text-align: center;
  line-height: 0.972973rem /* 36/37 */;
  background-color: #fec850ff;
  font-size: 0.378378rem /* 14/37 */;
  color: #000;
  border-radius: 0.540541rem /* 20/37 */;
  margin-left: 1.081081rem /* 40/37 */;
  top: 83.5%;
  left: 42%;
}
.com-fu {
  position: relative;
  height: 29.72973rem /* 1100/37 */;
  background-color: rgb(237, 239, 243);
  padding-top: 0.810811rem /* 30/37 */;
}
.com-fu-img {
  height: 29.702703rem /* 1099/37 */;
  width: 10.135135rem /* 375/37 */;
}
.com-fu-but1 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 6.6%;
  top: 20.5%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but2 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 53.4%;
  top: 20.5%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but3 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 6.6%;
  top: 56%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but4 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 53.4%;
  top: 56%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but5 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 6.6%;
  top: 95%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but6 {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 1rem /* 37/37 */;
  left: 53.4%;
  top: 95%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but7 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  left: 10.5%;
  top: 76.5%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but8 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  left: 58.7%;
  top: 76.5%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but9 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  left: 10.5%;
  top: 90%;
  border-radius: 0.540541rem /* 20/37 */;
}
.com-fu-but10 {
  position: absolute;
  width: 3.243243rem /* 120/37 */;
  height: 0.810811rem /* 30/37 */;
  left: 58.7%;
  top: 90%;
  border-radius: 0.540541rem /* 20/37 */;
}
.Bowl {
  margin-left: 0.135135rem /* 5/37 */;
}
.des1-rou-img1 {
  width: 9rem /* 333/37 */;
  margin-left: 0.135135rem /* 5/37 */;
}
</style>
