<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>
    <!-- 背景图 -->
    <img v-lazy="imageUrl" class="new-about-bj" src="../../pc-imgs/new-about-bj.png" alt="" />
    <!-- 关于半海 -->
    <div class="new-about-bh">
      <img class="new-about-bh-img" src="../../pc-imgs/new-about-bh.png" alt="" />
    </div>
    <!-- 我们的特色 -->
    <img class="new-about-ts" src="../../pc-imgs/new-about-ts.png" alt="" />
    <!-- 联系我们 -->
    <div class="new-lx-us">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-lx-us-title">
            <img class="new-lx-us-title-img" src="../../pc-imgs/new-lx-us-title.png" alt="" />
          </div>
          <div class="new-lx-us-but">
            <div
              @click="envFun1"
              class="new-lx-us-but-samll"
              :style="{
                background: env1 ? '#2c52e3ff' : '',
                color: env1 ? '#fff' : '#333',
                cursor: env1 ? '' : 'pointer'
              }"
            >
              人广校区
            </div>
            <div
              @click="envFun2"
              class="new-lx-us-but-samll"
              :style="{
                background: env2 ? '#2c52e3ff' : '',
                color: env2 ? '#fff' : '#333',
                cursor: env2 ? '' : 'pointer'
              }"
            >
              徐汇校区
            </div>
            <div
              @click="envFun3"
              class="new-lx-us-but-samll"
              :style="{
                background: env3 ? '#2c52e3ff' : '',
                color: env3 ? '#fff' : '#333',
                cursor: env3 ? '' : 'pointer'
              }"
            >
              杨浦校区
            </div>
            <div
              @click="envFun4"
              class="new-lx-us-but-samll"
              :style="{
                background: env4 ? '#2c52e3ff' : '',
                color: env4 ? '#fff' : '#333',
                cursor: env4 ? '' : 'pointer'
              }"
            >
              浦东校区
            </div>
          </div>
          <img v-show="env1" class="new-env-img" src="../../pc-imgs/new-env-img1.png" alt="" />
          <img v-show="env2" class="new-env-img" src="../../pc-imgs/new-env-img2.png" alt="" />
          <img v-show="env3" class="new-env-img" src="../../pc-imgs/new-env-img3.png" alt="" />
          <img v-show="env4" class="new-env-img" src="../../pc-imgs/new-env-img4.png" alt="" />
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      env1: true,
      env2: false,
      env3: false,
      env4: false,
      formMobile: {name: '', mobile: ''}
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },
    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    envFun1() {
      this.env1 = true;
      this.env2 = false;
      this.env3 = false;
      this.env4 = false;
    },
    envFun2() {
      this.env1 = false;
      this.env2 = true;
      this.env3 = false;
      this.env4 = false;
    },
    envFun3() {
      this.env1 = false;
      this.env2 = false;
      this.env3 = true;
      this.env4 = false;
    },
    envFun4() {
      this.env1 = false;
      this.env2 = false;
      this.env3 = false;
      this.env4 = true;
    }
  }
};
</script>

<style lang="less">
.new-about-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
}
.new-about-bh {
  height: auto;
  width: 100%;
  display: flex;
  padding-top: 0.214286rem /* 30/140 */;
  padding-bottom: 0.178571rem /* 25/140 */;
  justify-content: center;
  align-items: center;
  .new-about-bh-img {
    height: 1.792857rem /* 251/140 */;
  }
}
.new-about-ts {
  height: auto;
  width: 100%;
}
.new-lx-us {
  height: 2.342857rem /* 398/140 */;
  width: 100%;
  padding-top: 0.25rem /* 35/140 */;
  padding-bottom: 0.214286rem /* 30/140 */;
  .new-lx-us-title {
    height: 0.185714rem /* 26/140 */;
    display: flex;
    justify-content: center;
    margin-bottom: 0.178571rem /* 25/140 */;
    .new-lx-us-title-img {
      height: 0.185714rem /* 26/140 */;
    }
  }
  .new-lx-us-but {
    height: 0.3rem /* 42/140 */;
    display: flex;
    justify-content: space-between;
    line-height: 0.3rem /* 42/140 */;
    .new-lx-us-but-samll {
      width: 22%;
      height: 0.3rem /* 42/140 */;
      border: 0.007143rem /* 1/140 */ solid #2c52e3ff;
      text-align: center;
    }
  }
  .new-env-img {
    height: auto;
    width: 100%;
    margin-top: 0.142857rem /* 20/140 */;
  }
}
</style>
