<template>
  <div>
    <img class="total-header" src="../pc-imgs/pc-header.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.total-header {
  position: fixed;
  height: 0.342857rem /* 48/140 */;
  width: 100%;
  z-index: 999;
}
</style>
