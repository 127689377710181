<template>
  <div>
    <div class="about-weixin">
      <el-row type="flex">
        <el-col :span="4"></el-col>
        <el-col :span="10">
          <div class="weixin-daohang">
            <div class="weixin-daohang-line"></div>
            <div class="weixin-daohang-font">快捷导航</div>
          </div>
          <div class="weixin-all-classes">
            <router-link :to="{path: '/pc/al'}" class="router-link" :class="{'active-link': isActive('/pc/al')}">
              <div class="weixin-all-classes1">A-Level课程</div>
            </router-link>
            <router-link :to="{path: '/pc/g5'}" class="router-link" :class="{'active-link': isActive('/pc/g5')}">
              <div class="weixin-all-classes1">G5课程</div>
            </router-link>
            <router-link
              :to="{path: '/pc/allday'}"
              class="router-link"
              :class="{'active-link': isActive('/pc/allday')}"
            >
              <div class="weixin-all-classes1">AL全日制</div>
            </router-link>
          </div>
          <div class="weixin-all-classes">
            <router-link :to="{path: '/pc/ig'}" class="router-link" :class="{'active-link': isActive('/pc/ig')}">
              <div class="weixin-all-classes1">IGCSE课程</div>
            </router-link>
            <router-link
              :to="{path: '/pc/intertation'}"
              class="router-link"
              :class="{'active-link': isActive('/pc/intertation')}"
            >
              <div class="weixin-all-classes1">国际竞赛课程</div>
            </router-link>
            <router-link :to="{path: '/pc/ib'}" class="router-link" :class="{'active-link': isActive('/pc/ib')}">
              <div class="weixin-all-classes1">IB课程</div>
            </router-link>
          </div>
          <div class="weixin-all-classes">
            <router-link :to="{path: '/pc/yasi'}" class="router-link" :class="{'active-link': isActive('/pc/yasi')}">
              <div class="weixin-all-classes1">雅思课程</div>
            </router-link>
            <router-link :to="{path: '/pc/tuofu'}" class="router-link" :class="{'active-link': isActive('/pc/tuofu')}">
              <div class="weixin-all-classes1">托福课程</div>
            </router-link>
            <router-link :to="{path: '/pc/ap'}" class="router-link" :class="{'active-link': isActive('/pc/ap')}">
              <div class="weixin-all-classes1">AP课程</div>
            </router-link>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="weixin-daohang">
            <div class="weixin-daohang-line"></div>
            <div class="weixin-daohang-font">联系方式</div>
          </div>
          <div class="weixin-all-classes">电话：400-068-5018</div>
          <div class="weixin-all-classes2">地址：上海市黄浦区汉口路300号4楼</div></el-col
        >
      </el-row>
      <div class="hengxian"></div>
      <div class="weixin-bottom-font">上海半海教育科技有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    isActive(routePath) {
      return this.$route.path === routePath;
    }
  }
};
</script>

<style lang="less" scoped>
.about-weixin {
  background-color: #191f37ff;
  height: 1.914286rem /* 268/140 */;
  width: 100%;
  color: #ffffff;
}
.about-weixin-img {
  margin-top: 0.235714rem /* 33/140 */;
}
.about-weixin-img1 {
  width: 0.807143rem /* 113/140 */;
  height: 0.807143rem /* 113/140 */;
}
.about-weixin-img1-font {
  margin-top: 0.064286rem /* 9/140 */;
  font-size: 0.114286rem /* 16/140 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 0.071429rem /* 10/140 */;
}
.weixin-daohang {
  display: flex;
  margin-top: 0.235714rem /* 33/140 */;
}
.weixin-daohang-line {
  width: 0.021429rem /* 3/140 */;
  height: 0.114286rem /* 16/140 */;
  background: #ffffff;
}
.weixin-daohang-font {
  width: 0.457143rem /* 64/140 */;
  height: 0.114286rem /* 16/140 */;
  font-size: 0.114286rem /* 16/140 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.114286rem /* 16/140 */;
  margin-left: 0.071429rem /* 10/140 */;
}
.weixin-all-classes {
  display: flex;
  justify-content: space-between;
  padding-left: 0.1rem /* 14/140 */;
  padding-right: 1.428571rem /* 200/140 */;
  margin-top: 0.178571rem /* 25/140 */;
  font-size: 0.114286rem /* 16/140 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  line-height: 0.114286rem /* 16/140 */;
}
.weixin-all-classes1 {
  width: 0.714286rem /* 100/140 */;
}
.weixin-all-classes2 {
  display: flex;
  justify-content: space-between;
  padding-left: 0.1rem /* 14/140 */;
  padding-right: 1.228571rem /* 200/140 */;
  margin-top: 0.178571rem /* 25/140 */;
  font-size: 0.114286rem /* 16/140 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  line-height: 0.114286rem /* 16/140 */;
}
.hengxian {
  width: 6.857143rem /* 960/140 */;
  height: 0.007143rem /* 1/140 */;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 0.214286rem /* 30/140 */;
  margin-left: 1.714286rem /* 240/140 */;
}
.weixin-bottom-font {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.1rem /* 14/140 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  line-height: 0.15rem /* 21/140 */;
  margin-top: 0.114286rem /* 16/140 */;
}
.active-link {
  /* 添加高亮样式，比如修改链接文本颜色、背景色等 */
  color: #163fe0; /* 举例: 将文本颜色为红色 */
}
a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
</style>
