<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <!-- 导航栏弹框 -->
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="al-bg">
      <img class="al-bg" src="../../assets/new-al-bg.png" alt="" @click="consult" />
    </div>
    <!-- 课程认可度 -->
    <div class="al-class-rk">
      <img class="al-class-rk-logo" src="../../assets/al-kcrk-logo.png" alt="" />
      <div class="al-class-rk-bt">
        <div
          class="al-class-rk-bt1"
          :style="{backgroundColor: ygBut ? 'rgb(69, 133, 221)' : '#fff', color: ygBut ? '#fff' : '#000'}"
          @click="toYg"
        >
          英国大学
        </div>
        <div
          class="al-class-rk-bt1"
          :style="{backgroundColor: mgBut ? 'rgb(69, 133, 221)' : '#fff', color: mgBut ? '#fff' : '#000'}"
          @click="toMg"
        >
          美国大学
        </div>
        <div
          class="al-class-rk-bt1"
          :style="{backgroundColor: xgBut ? 'rgb(69, 133, 221)' : '#fff', color: xgBut ? '#fff' : '#000'}"
          @click="toXg"
        >
          澳/新/香港
        </div>
      </div>
      <img v-show="ygBut" class="al-class-rk-img" src="../../assets/al-kcrk-img1.png" alt="" />
      <img v-show="mgBut" class="al-class-rk-img" src="../../assets/al-class-rk-img2.png" alt="" />
      <img v-show="xgBut" class="al-class-rk-img" src="../../assets/al-class-rk-img3.png" alt="" />
    </div>
    <!-- 成绩要求 -->
    <div class="al-cj">
      <img class="al-cj-logo" src="../../assets/al-cj-logo.png" alt="" />
      <van-swipe class="al-cj-swp" autoplay="3000">
        <van-swipe-item>
          <img class="al-cj-swp-item" src="../../assets/al-cj-img1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="al-cj-swp-item" src="../../assets/al-cj-img2.png" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="al-cj-button" @click="consult">更多大学要求咨询客服</div>
    </div>
    <!-- 课程区别 -->
    <div class="al-qb">
      <img class="al-qb" src="../../assets/al-qu.png" alt="" />
    </div>
    <!-- 是否有以下疑问 -->
    <div class="al-yw">
      <img class="al-yw-logo" src="../../assets/al-yw-logo.png" alt="" />
      <van-swipe class="al-yw-swp" autoplay="3000">
        <van-swipe-item>
          <img class="al-yw-swp-item" src="../../assets/al-yw-img1.png" alt="" />
          <div class="swp-bt1" @click="consult"></div>
          <div class="swp-bt2" @click="consult"></div>
          <div class="swp-bt3" @click="consult"></div>
          <div class="swp-bt4" @click="consult"></div>
          <div class="swp-bt5" @click="consult"></div>
        </van-swipe-item>
        <van-swipe-item>
          <img class="al-yw-swp-item" src="../../assets/al-yw-img2.png" alt="" />
          <div class="swp-bt1" @click="consult"></div>
          <div class="swp-bt2" @click="consult"></div>
          <div class="swp-bt3" @click="consult"></div>
          <div class="swp-bt4" @click="consult"></div>
          <div class="swp-bt5" @click="consult"></div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 学习规划 -->
    <div :class="classPlan">
      <div class="al-study-title">
        <img class="al-study-title-img" src="../../assets/al-study-title.png" alt="" />
      </div>
      <div class="al-studybutton">
        <div class="al-studybutton-top">
          <div
            @click="toPlan1"
            class="al-studybutton-top1"
            :style="{
              backgroundColor: plan1 ? 'rgb(252, 128, 38)' : 'rgb(232, 242, 255)',
              color: plan1 ? '#fff' : '#000'
            }"
          >
            1.本科申请规划
          </div>
          <div
            @click="toPlan2"
            class="al-studybutton-top1"
            :style="{
              backgroundColor: plan2 ? 'rgb(252, 128, 38)' : 'rgb(232, 242, 255)',
              color: plan2 ? '#fff' : '#000'
            }"
          >
            2.课程规划选择
          </div>
          <div
            @click="toPlan3"
            class="al-studybutton-top1"
            :style="{
              backgroundColor: plan3 ? 'rgb(252, 128, 38)' : 'rgb(232, 242, 255)',
              color: plan3 ? '#fff' : '#000'
            }"
          >
            3.学习时间规划
          </div>
        </div>
        <div class="al-studybutton-bottom">
          <div
            @click="toPlan4"
            class="al-studybutton-top1"
            :style="{
              backgroundColor: plan4 ? 'rgb(252, 128, 38)' : 'rgb(232, 242, 255)',
              color: plan4 ? '#fff' : '#000'
            }"
          >
            4.考试时间选择
          </div>
          <div
            @click="toPlan5"
            class="al-studybutton-top1"
            :style="{
              backgroundColor: plan5 ? 'rgb(252, 128, 38)' : 'rgb(232, 242, 255)',
              color: plan5 ? '#fff' : '#000'
            }"
          >
            5.语言学校规划
          </div>
        </div>
      </div>
      <img v-show="plan1" class="al-plan-img" src="../../assets/al-plan-img1.png" alt="" />
      <img v-show="plan2" class="al-plan-img2" src="../../assets/al-plan-img2.png" alt="" />
      <img v-show="plan3" class="al-plan-img3" src="../../assets/al-plan-img3.png" alt="" />
      <img v-show="plan4" class="al-plan-img4" src="../../assets/al-plan-img4.png" alt="" />
      <img v-show="plan5" class="al-plan-img5" src="../../assets/al-plan-img5.png" alt="" />
    </div>
    <!-- 课程适合哪些学生 -->
    <div class="al-sh">
      <img class="al-sh-img" src="../../assets/al-sh.png" alt="" />
      <div class="al-cj-button" @click="consult">更多A-Level课程咨询客服</div>
    </div>
    <!-- 教学团队 -->
    <div class="teacher-team">
      <van-swipe class="teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="form-all">
        <div class="form">
          <div class="form-input-div">
            <input placeholder="请输入电话" type="text" class="form-input-div1" v-model="form.mobile" />
            <input placeholder="请输入姓名" type="text" class="form-input-div2" v-model="form.name" />
            <img @click="submit" class="submit-but" src="../../assets/subbut.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 教学目标 -->
    <div class="al-jxmb">
      <img class="al-jxmb-img" src="../../assets/al-jxmu.png" alt="" />
    </div>
    <!-- 课程特色 -->
    <div class="al-ts-bj">
      <img class="al-ts-bj" src="../../assets/al-ts.png" alt="" />
      <img class="al-ts-bt" src="../../assets/al-button1.png" alt="" @click="consult" />
    </div>
    <!-- 课程科目介绍 -->
    <div class="al-class-js">
      <img class="al-class-js-img" src="../../assets/al-js.png" alt="" />
    </div>
    <!-- 辅导优势 -->
    <img class="al-fu" src="../../assets/al-fd.png" alt="" />
    <!-- 跟踪学习 -->
    <div class="al-gz">
      <img class="al-gz-img" src="../../assets/al-gz.png" alt="" />
    </div>
    <!-- 贴心服务 -->
    <img class="al-tx" src="../../assets/al-tx.png" alt="" />

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';
import {Toast} from 'vant';
import axios from 'axios';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      form: {
        mobile: '',
        name: ''
      },
      numberToCopy: 19821616277,
      classPlan: 'al-study-plan1',
      showRouter: false,
      show: false,
      ygBut: true,
      mgBut: false,
      xgBut: false,
      plan1: true,
      plan2: false,
      plan3: false,
      plan4: false,
      plan5: false
    };
  },
  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    toYg() {
      this.ygBut = true;
      this.mgBut = false;
      this.xgBut = false;
    },
    toMg() {
      this.ygBut = false;
      this.mgBut = true;
      this.xgBut = false;
    },
    toXg() {
      this.ygBut = false;
      this.mgBut = false;
      this.xgBut = true;
    },
    toPlan1() {
      this.classPlan = 'al-study-plan1';
      this.plan1 = true;
      this.plan2 = false;
      this.plan3 = false;
      this.plan4 = false;
      this.plan5 = false;
    },
    toPlan2() {
      this.classPlan = 'al-study-plan2';
      this.plan1 = false;
      this.plan2 = true;
      this.plan3 = false;
      this.plan4 = false;
      this.plan5 = false;
    },
    toPlan3() {
      this.classPlan = 'al-study-plan3';
      this.plan1 = false;
      this.plan2 = false;
      this.plan3 = true;
      this.plan4 = false;
      this.plan5 = false;
    },
    toPlan4() {
      this.classPlan = 'al-study-plan4';
      this.plan1 = false;
      this.plan2 = false;
      this.plan3 = false;
      this.plan4 = true;
      this.plan5 = false;
    },
    toPlan5() {
      this.classPlan = 'al-study-plan5';
      this.plan1 = false;
      this.plan2 = false;
      this.plan3 = false;
      this.plan4 = false;
      this.plan5 = true;
    },
    submit() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name && !this.form.mobile) {
        Toast.fail('姓名和手机号必须填写');
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        Toast.fail('手机号格式错误');
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            Toast.success('提交成功');
          })
          .catch(() => {});
      }
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.al-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.al-class-rk {
  width: 10.135135rem /* 375/37 */;
  height: 7.972973rem /* 295/37 */;
  padding-top: 0.540541rem /* 20/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  background-color: rgb(237, 239, 243);
  text-align: center;
}
.al-class-rk-logo {
  width: 8.72973rem /* 323/37 */;
  height: 1.27027rem /* 47/37 */;
  margin-bottom: 0.486486rem /* 18/37 */;
}
.al-class-rk-bt {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 0.810811rem /* 30/37 */;
}
.al-class-rk-bt1 {
  height: 0.810811rem /* 30/37 */;
  width: 2.432432rem /* 90/37 */;
  color: #000;
  background-color: #fff;
  font-size: 0.432432rem /* 16/37 */;
  line-height: 0.810811rem /* 30/37 */;
}
.al-class-rk-img {
  width: 9.405405rem /* 348/37 */;
  height: 4.351351rem /* 161/37 */;
}
.al-cj {
  height: 10.540541rem /* 390/37 */;
  width: 10.135135rem /* 375/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.al-cj-logo {
  width: 8.810811rem /* 326/37 */;
  height: 0.621622rem /* 23/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.al-cj-swp {
  height: 7.297297rem /* 270/37 */;
  width: 9.324324rem /* 345/37 */;
  .van-swipe__indicator {
    height: 0.27027rem /* 10/37 */ !important;
    width: 0.540541rem /* 20/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: rgb(213, 214, 215) !important;
  }
  .van-swipe__indicator--active {
    height: 0.27027rem /* 10/37 */ !important;
    width: 1.351351rem /* 50/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: rgb(96, 158, 273) !important;
  }
}
.al-cj-swp-item {
  height: 6.351351rem /* 235/37 */;
  width: 9.324324rem /* 345/37 */;
}

.al-cj-button {
  height: 0.810811rem /* 30/37 */;
  width: 5.405405rem /* 200/37 */;
  background-color: rgb(71, 140, 234);
  color: #fff;
  border-radius: 0.540541rem /* 20/37 */;
  font-size: 0.432432rem /* 16/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
}
.al-qb {
  width: 10.135135rem /* 375/37 */;
  height: 13.72973rem /* 508/37 */;
}
.al-yw {
  position: relative;
  height: 9.189189rem /* 340/37 */;
  width: 10.135135rem /* 375/37 */;
  //background-color: rgb(237, 239, 243);
  padding-top: 0.27027rem /* 10/37 */;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.al-yw-logo {
  width: 8.756757rem /* 324/37 */;
  height: 0.621622rem /* 23/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.al-yw-swp {
  height: 7.297297rem /* 270/37 */;
  width: 9.324324rem /* 345/37 */;
  .van-swipe__indicator {
    height: 0.27027rem /* 10/37 */ !important;
    width: 0.540541rem /* 20/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: rgb(213, 214, 215) !important;
  }
  .van-swipe__indicator--active {
    height: 0.27027rem /* 10/37 */ !important;
    width: 1.351351rem /* 50/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: rgb(96, 158, 273) !important;
  }
}
.al-yw-swp-item {
  width: 9.324324rem /* 345/37 */;
  height: 6.27027rem /* 232/37 */;
}
.swp-bt1 {
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 1.081081rem /* 40/37 */;
  top: 0;
  left: 0;
}

.swp-bt2 {
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 1.081081rem /* 40/37 */;
  top: 18%;
  left: 0;
}
.swp-bt3 {
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 1.081081rem /* 40/37 */;
  top: 36%;
  left: 0;
}
.swp-bt4 {
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 1.081081rem /* 40/37 */;
  top: 54%;
  left: 0;
}
.swp-bt5 {
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 1.081081rem /* 40/37 */;
  top: 73%;
  left: 0;
}
.al-study-plan1 {
  width: 10.135135rem /* 375/37 */;
  /* background-image: url('../../assets/al-stdudy-plan.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-plan2 {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-plan2 {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-plan3 {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-plan4 {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-plan5 {
  width: 10.135135rem /* 375/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(71, 140, 234);
  padding-bottom: 0.810811rem /* 30/37 */;
}
.al-study-title {
  height: 2.162162rem /* 80/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
}
.al-study-title-img {
  width: 8.783784rem /* 325/37 */;
  height: 0.621622rem /* 23/37 */;
}
.al-studybutton {
  padding-left: 0.405405rem /* 15/37 */;
  padding-right: 0.405405rem /* 15/37 */;
  width: 9.297297rem /* 344/37 */;
  height: 1.675676rem /* 62/37 */;
}
.al-studybutton-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.405405rem /* 15/37 */;
}
.al-studybutton-top1 {
  width: 2.891892rem /* 107/37 */;
  height: 0.72973rem /* 27/37 */;
  background-color: #fff;
  border-radius: 0.540541rem /* 20/37 */;
  color: #000;
  font-size: 0.324324rem /* 12/37 */;
  text-align: center;
  line-height: 0.72973rem /* 27/37 */;
}
.al-studybutton-bottom {
  display: flex;
  justify-content: space-between;
  padding-right: 1.621622rem /* 60/37 */;
  padding-left: 1.621622rem /* 60/37 */;
}
.al-plan-img {
  margin-top: 0.810811rem /* 30/37 */;
  width: 9.324324rem /* 345/37 */;
  height: 13.27027rem /* 491/37 */;
}
.al-plan-img2 {
  margin-top: 0.810811rem /* 30/37 */;
  width: 9.324324rem /* 345/37 */;
  height: 11.405405rem /* 422/37 */;
}
.al-plan-img3 {
  margin-top: 0.810811rem /* 30/37 */;
  width: 9.324324rem /* 345/37 */;
  height: 10.972973rem /* 406/37 */;
}
.al-plan-img4 {
  margin-top: 0.810811rem /* 30/37 */;
  width: 9.324324rem /* 345/37 */;
  height: 11.162162rem /* 413/37 */;
}
.al-plan-img5 {
  margin-top: 0.810811rem /* 30/37 */;
  width: 9.324324rem /* 345/37 */;
  height: 9.702703rem /* 359/37 */;
}
.al-sh {
  height: 18.918919rem /* 700/37 */;
  // background-color: rgb(237, 239, 243);
  padding-top: 0.810811rem /* 30/37 */;
  padding-bottom: 0.810811rem /* 30/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.al-sh-img {
  width: 9.324324rem /* 345/37 */;
  height: 17.594595rem /* 651/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.teacher-team {
  height: 18.621622rem /* 689/37 */;
  padding-bottom: 0.675676rem /* 25/37 */;
  background-image: url('../../assets/al-teacher.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.teacher-swp {
  height: 12.972973rem /* 480/37 */;
  margin-left: 0.351351rem /* 13/37 */;
  .van-swipe__indicator {
    height: 0.27027rem /* 10/37 */ !important;
    width: 0.27027rem /* 10/37 */ !important;
    background-color: #fff !important;
  }
  .van-swipe__indicator--active {
    height: 0.27027rem /* 10/37 */ !important;
    width: 0.27027rem /* 10/37 */ !important;
    background-color: rgb(96, 158, 273) !important;
  }
}
.teacher-swp-item {
  margin-top: 2.432432rem /* 90/37 */;
  width: 9.432432rem /* 349/37 */;
  height: 9.189189rem /* 340/37 */;
}
.form-all {
  width: 10.135135rem /* 375/37 */;
}
.form {
  position: relative;
  height: 5.135135rem /* 190/37 */;
  width: 9.324324rem /* 345/37 */;
  margin-left: 0.405405rem /* 15/37 */;
  background-image: url('../../assets/form.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0.27027rem /* 10/37 */;
  .form-input-div {
    height: 3.513514rem /* 130/37 */;
    width: 9.324324rem /* 345/37 */;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit-but {
      width: 3.135135rem /* 116/37 */;
      height: 1.027027rem /* 38/37 */;
    }
    .form-input-div1 {
      width: 6.216216rem /* 230/37 */ !important;
      height: 0.945946rem /* 35/37 */ !important;
      border-radius: 0.27027rem /* 10/37 */;
      border: 0.027027rem /* 1/37 */ solid #ccc;
      margin-bottom: 0.27027rem /* 10/37 */;
      padding-left: 0.540541rem /* 20/37 */;
      font-size: 0.432432rem /* 16/37 */;
    }
    .form-input-div2 {
      width: 6.216216rem /* 230/37 */ !important;
      height: 0.945946rem /* 35/37 */ !important;
      border-radius: 0.27027rem /* 10/37 */;
      border: 0.027027rem /* 1/37 */ solid #ccc;
      margin-bottom: 0.405405rem /* 15/37 */;
      padding-left: 0.540541rem /* 20/37 */;
      font-size: 0.432432rem /* 16/37 */;
    }
  }
}
.al-jxmb {
  height: 8.945946rem /* 331/37 */;
  // background-color: rgb(237, 239, 243);
  padding-top: 0.594595rem /* 22/37 */;
  padding-bottom: 0.810811rem /* 30/37 */;
  display: flex;
  align-items: center;
  justify-content: center;
}
.al-jxmb-img {
  height: 7.540541rem /* 279/37 */;
  width: 9.324324rem /* 345/37 */;
}
.al-ts-bj {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 15.459459rem /* 572/37 */;
}
.al-ts-bt {
  position: absolute;
  height: 0.810811rem /* 30/37 */;
  width: 5.405405rem /* 200/37 */;
  left: 50%;
  transform: translateX(-50%);
  top: 91%;
}
.al-class-js {
  height: 27.432432rem /* 1015/37 */;
  // background-color: rgb(237, 239, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.540541rem /* 20/37 */;
}
.al-class-js-img {
  height: 26.135135rem /* 967/37 */;
  width: 9.324324rem /* 345/37 */;
}
.al-fu {
  width: 10.135135rem /* 375/37 */;
  height: 15.351351rem /* 568/37 */;
}
.al-gz {
  height: 20.783784rem /* 769/37 */;

  display: flex;
  justify-content: center;
  align-items: center;
}
.al-gz-img {
  height: 19.891892rem /* 736/37 */;
  width: 9.540541rem /* 353/37 */;
}
.al-tx {
  height: 18rem /* 666/37 */;
  width: 10.135135rem /* 375/37 */;
}
.al-wx {
  height: 6.216216rem /* 230/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgb(237, 239, 243);
}
.al-wx-img {
  width: 6.540541rem /* 242/37 */;
  height: 5rem /* 185/37 */;
}
.copy-input-div {
  margin-bottom: 1.891892rem /* 70/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(71, 140, 234);
}
.copy-input {
  height: 0.540541rem /* 20/37 */;
  width: 3.783784rem /* 140/37 */;
  border: 0.027027rem /* 1/37 */ solid rgb(71, 140, 234);
  font-size: 0.378378rem /* 14/37 */;
  padding-left: 0.27027rem /* 10/37 */;
  border-radius: 0;
}
.copy-button {
  height: 0.72973rem /* 27/37 */;
  width: 1.891892rem /* 70/37 */;
  background-color: rgb(71, 140, 234);
  border: 0.027027rem /* 1/37 */ solid rgb(71, 140, 234);
  color: #fff;
  font-size: 0.378378rem /* 14/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
}
</style>
