<template>
  <div class="pc-allday">
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景页面 -->
    <div class="allday-bj">
      <img v-lazy="imageUrl" class="allday-bj" src="../../pc-imgs/allday-bj.png" alt="" @click="consult" />
    </div>
    <!-- 脱产AL课程适合哪些学员 -->
    <div class="allday-kc-sh">
      <img class="allday-kc-sh-img" src="../../pc-imgs/allday-kc-sh-img.png" alt="" />
      <div class="allday-kc-sh-but" @click="consult"></div>
    </div>
    <!-- AL课程认可度 -->
    <div class="allday-kc-rk-div">
      <img class="allday-kc-rk" src="../../pc-imgs/allday-kc-rk.png" alt="" />
      <div class="allday-kc-rk-but" @click="consult"></div>
    </div>
    <!-- 英国AL课程优势 -->
    <div class="allday-yg-kc-ys">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="allday-yg-kc-ys-title">
            <img class="allday-yg-kc-ys-title-img" src="../../pc-imgs/allday-yg-kc-ys-title-img.png" alt="" />
          </div>
          <div class="allday-yg-kc-ys-div">
            <div class="allday-yg-kc-ys-div1">
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys1.png" alt="" />
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys2.png" alt="" />
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys3.png" alt="" />
            </div>
            <div class="allday-yg-kc-ys-div1">
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys4.png" alt="" />
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys5.png" alt="" />
              <img class="allday-yg-kc-ys-div1-samll" src="../../pc-imgs/allday-yg-kc-ys6.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 为什么选择国内的AL脱产 -->
    <div class="allday-why-gn-div">
      <img class="allday-why-gn" src="../../pc-imgs/allday-why-gn.png" alt="" />
      <div class="allday-why-gn-but1" @click="consult"></div>
      <div class="allday-why-gn-but2" @click="consult"></div>
      <div class="allday-why-gn-but3" @click="consult"></div>
    </div>
    <!-- 为何选择国内的A-Level脱产？ -->
    <div class="allday-why-xz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <img class="allday-why-xz-title" src="../../pc-imgs/allday-why-xz-title.png" alt="" />
          <div class="allday-why-xz-but">
            <div
              @click="tCFun1"
              class="allday-why-xz-but-small1"
              :style="{background: tC1 ? '#5768ecFF' : '', color: tC1 ? '#fff' : '#333', cursor: tC1 ? '' : 'pointer'}"
            >
              1年脱产
            </div>
            <div
              @click="tCFun2"
              class="allday-why-xz-but-small2"
              :style="{background: tC2 ? '#5768ecFF' : '', color: tC2 ? '#fff' : '#333', cursor: tC2 ? '' : 'pointer'}"
            >
              1.5年脱产
            </div>
            <div
              @click="tCFun3"
              class="allday-why-xz-but-small3"
              :style="{background: tC3 ? '#5768ecFF' : '', color: tC3 ? '#fff' : '#333', cursor: tC3 ? '' : 'pointer'}"
            >
              2年脱产
            </div>
          </div>
          <img v-show="tC1" class="allday-why-xz-img" src="../../pc-imgs/allday-why-xz-img1.png" alt="" />
          <img v-show="tC2" class="allday-why-xz-img" src="../../pc-imgs/allday-why-xz-img2.png" alt="" />
          <img v-show="tC3" class="allday-why-xz-img" src="../../pc-imgs/allday-why-xz-img3.png" alt="" />
          <div class="allday-why-xz-zy">注：以上内容仅供参考，详情咨询老师</div>
          <div class="allday-why-xz-but">
            <img
              class="allday-why-xz-but-samll1"
              src="../../pc-imgs/allday-why-xz-but-samll1.png"
              alt=""
              @click="consult"
            />
            <img
              class="allday-why-xz-but-samll2"
              src="../../pc-imgs/allday-why-xz-but-samll2.png"
              alt=""
              @click="consult"
            />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- A-Level脱产课程目标 -->
    <img class="allday-tc-kc-mb" src="../../pc-imgs/allday-tc-kc-mb.png" alt="" />
    <!-- 英国本科留学条件 -->
    <div class="allday-lx">
      <img class="allday-lx-img" src="../../pc-imgs/allday-lx-img.png" alt="" />
      <div class="allday-lx-img-but" @click="consult"></div>
    </div>
    <!-- 涵盖所有笔/面试/背景提升辅导 -->
    <img class="allday-hg-nr" src="../../pc-imgs/allday-hg-nr.png" alt="" @click="consult" />
    <!-- 教学团队 -->
    <div class="day-new-al-teacher">
      <img class="day-new-al-teacher-img" src="../../pc-imgs/new-al-teacher-img.png" alt="" />
      <div class="day-new-al-teacher-img-but1" @click="consult"></div>
      <div class="day-new-al-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 热门科目 -->
    <img class="allday-kc-rk-lb" src="../../pc-imgs/allday-kc-rk-lb.png" alt="" />
    <!-- 热门科目体验试听 -->
    <div class="new-allday-yy">
      <input type="text" class="new-al-yy-inp1" placeholder="请输入姓名" v-model="form.name" />
      <input type="text" class="new-al-yy-inp2" placeholder="请输入电话" v-model="form.mobile" />
      <img class="new-lj-but" src="../../pc-imgs/new-lj-but.png" alt="" @click="submitForm" />
    </div>
    <!-- 科目搭配建议 -->
    <div class="allday-km-dp">
      <img class="pc-allday-km-dp-img" src="../../pc-imgs/allday-km-dp-img.png" alt="" />
      <div class="pc-allday-km-dp-but1" @click="consult"></div>
      <div class="pc-allday-km-dp-but2" @click="consult"></div>
      <div class="pc-allday-km-dp-but3" @click="consult"></div>
    </div>
    <!-- 全链路跟踪学习 -->
    <img class="new-ig-gz-study" src="../../pc-imgs/new-ig-gz-study.png" alt="" />
    <!-- 澜大半海优势 -->
    <img class="new-al-landa-bh-ys" src="../../pc-imgs/landa-bh-ys1.png" alt="" />
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
import Footer from '../../components/PcFooter.vue';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      tC1: true,
      tC2: false,
      tC3: false,
      form: {
        name: '',
        mobile: ''
      }
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    tCFun1() {
      this.tC1 = true;
      this.tC2 = false;
      this.tC3 = false;
    },
    tCFun2() {
      this.tC1 = false;
      this.tC2 = true;
      this.tC3 = false;
    },
    tCFun3() {
      this.tC1 = false;
      this.tC2 = false;
      this.tC3 = true;
    }
  }
};
</script>

<style lang="less">
.pc-allday {
  .allday-bj {
    padding-top: 0.352857rem;
    height: 3.714286rem /* 520/140 */;
    width: 10.285714rem /* 1440/140 */;
    cursor: pointer;
  }
  .allday-kc-sh {
    margin-top: 0.352857rem;
    position: relative;
    height: auto;
    padding-top: 0.142857rem /* 20/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.214286rem /* 30/140 */;
    .allday-kc-sh-img {
      height: 2.121429rem /* 297/140 */;
    }
    .allday-kc-sh-but {
      position: absolute;
      top: 75%;
      left: 46%;
      width: 0.857143rem /* 120/140 */;
      height: 0.257143rem /* 36/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      cursor: pointer;
    }
  }
  .allday-kc-rk-div {
    position: relative;
    height: auto;
    width: 100%;

    .allday-kc-rk {
      height: auto;
      width: 100%;
    }
    .allday-kc-rk-but {
      position: absolute;
      height: 0.264286rem /* 37/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 87%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .allday-yg-kc-ys {
    height: auto;
    width: 100%;
    padding-bottom: 0.214286rem /* 30/140 */;
    .allday-yg-kc-ys-title {
      height: 0.792857rem /* 111/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .allday-yg-kc-ys-title-img {
        height: 0.335714rem /* 47/140 */;
      }
    }
    .allday-yg-kc-ys-div {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      .allday-yg-kc-ys-div1 {
        height: auto;
        margin-bottom: 0.021429rem /* 3/140 */;
        width: 100%;
        display: flex;
        .allday-yg-kc-ys-div1-samll {
          width: 33%;
          height: auto;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .allday-yg-kc-ys-div1-samll:hover {
          transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
        }
      }
    }
  }
  .allday-why-gn-div {
    position: relative;
    height: auto;
    width: 100%;
    .allday-why-gn {
      height: auto;
      width: 100%;
    }
    .allday-why-gn-but1 {
      position: absolute;
      width: 0.871429rem /* 122/140 */;
      height: 0.25rem /* 35/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 82%;
      left: 27.8%;
      cursor: pointer;
    }
    .allday-why-gn-but2 {
      position: absolute;
      width: 0.871429rem /* 122/140 */;
      height: 0.25rem /* 35/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 82%;
      left: 45.8%;
      cursor: pointer;
    }
    .allday-why-gn-but3 {
      position: absolute;
      width: 0.871429rem /* 122/140 */;
      height: 0.25rem /* 35/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 82%;
      left: 63.8%;
      cursor: pointer;
    }
  }
  .allday-why-xz {
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 0.214286rem /* 30/140 */;
    .allday-why-xz-title {
      position: absolute;
      height: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 2.842857rem /* 398/140 */;
    }
    .allday-why-xz-but {
      position: relative;
      height: 0.564286rem /* 79/140 */;
      width: 100%;
      margin-bottom: 0.285714rem /* 40/140 */;
      font-size: 0.114286rem /* 16/140 */;
      .allday-why-xz-but-small1 {
        position: absolute;
        width: 0.842857rem /* 118/140 */;
        height: 0.271429rem /* 38/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        text-align: center;
        top: 80%;
        left: 22%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        line-height: 0.271429rem /* 38/140 */;
      }
      .allday-why-xz-but-small2 {
        position: absolute;
        width: 0.842857rem /* 118/140 */;
        height: 0.271429rem /* 38/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.042857rem /* 6/140 */;
        text-align: center;
        top: 80%;
        left: 42%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        line-height: 0.271429rem /* 38/140 */;
      }
      .allday-why-xz-but-small3 {
        position: absolute;
        width: 0.842857rem /* 118/140 */;
        height: 0.271429rem /* 38/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        line-height: 0.042857rem /* 6/140 */;
        text-align: center;
        top: 80%;
        left: 62%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        line-height: 0.271429rem /* 38/140 */;
      }
    }
    .allday-why-xz-img {
      height: auto;
      width: 100%;
    }
    .allday-why-xz-zy {
      margin-top: 0.064286rem /* 9/140 */;
      height: 0.085714rem /* 12/140 */;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      font-size: 0.085714rem /* 12/140 */;
      color: #df3232;
      text-align: center;
    }
    .allday-why-xz-but {
      height: 0.621429rem /* 87/140 */;
      width: 100%;
      position: relative;
      .allday-why-xz-but-samll1 {
        position: absolute;
        top: 20%;
        left: 18%;
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
      .allday-why-xz-but-samll2 {
        position: absolute;
        top: 20%;
        left: 55%;
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
    }
  }
  .allday-tc-kc-mb {
    width: 100%;
    height: auto;
  }
  .allday-lx {
    position: relative;
    display: flex;
    height: auto;
    padding-top: 0.142857rem /* 20/140 */;
    padding-bottom: 0.142857rem /* 20/140 */;
    width: 100%;
    justify-content: center;
    align-items: center;
    .allday-lx-img {
      height: 1.878571rem /* 263/140 */;
    }
    .allday-lx-img-but {
      position: absolute;
      height: 0.271429rem /* 38/140 */;
      width: 1.428571rem /* 200/140 */;
      top: 82.2%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.142857rem /* 20/140 */;
      cursor: pointer;
    }
  }
  .allday-hg-nr {
    height: auto;
    width: 100%;
    cursor: pointer;
  }
  .new-allday-yy {
    height: 1.785714rem /* 250/140 */;
    width: 100%;
    background-image: url(../../pc-imgs/new-allday-yy.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .new-al-yy-inp1 {
      position: absolute;
      height: 0.457143rem /* 64/140 */;
      border-radius: 0.214286rem /* 30/140 */;
      width: 1.771429rem /* 248/140 */;
      border: 0.007143rem /* 1/140 */ solid #ccc;
      padding-left: 0.142857rem /* 20/140 */;
      top: 50%;
      left: 21%;
      font-size: 0.142857rem /* 20/140 */;
    }
    .new-al-yy-inp1:focus {
      border-color: #fff; /* 可以根据需要更改颜色 */
      outline: none; /* 阻止默认的聚焦边框样式 */
    }
    .new-al-yy-inp2 {
      position: absolute;
      height: 0.457143rem /* 64/140 */;
      border-radius: 0.214286rem /* 30/140 */;
      width: 1.771429rem /* 248/140 */;
      border: 0.007143rem /* 1/140 */ solid #ccc;
      padding-left: 0.142857rem /* 20/140 */;
      top: 50%;
      left: 42%;
      font-size: 0.142857rem /* 20/140 */;
    }
    .new-al-yy-inp2:focus {
      border-color: #fff; /* 可以根据需要更改颜色 */
      outline: none; /* 阻止默认的聚焦边框样式 */
    }
    .new-lj-but {
      position: absolute;
      top: 50%;
      left: 63%;
      height: 0.457143rem /* 64/140 */;
      cursor: pointer;
    }
  }

  .allday-kc-rk-lb {
    height: auto;
    width: 100%;
  }
  .day-new-al-teacher {
    position: relative;
    height: 5.607143rem /* 785/140 */;
    width: 100%;
    padding-top: 0.285714rem /* 40/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .day-new-al-teacher-img {
    height: 5.107143rem /* 715/140 */;
  }
  .day-new-al-teacher-img-but1 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91.3%;
    left: 33.3%;
    cursor: pointer;
  }
  .day-new-al-teacher-img-but2 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91.3%;
    left: 53.3%;
    cursor: pointer;
  }
  .new-ig-gz-study {
    width: 100%;
    height: auto;
  }
  .new-al-landa-bh-ys {
    width: 100%;
    height: auto;
  }
  .allday-km-dp {
    height: 2.542857rem /* 356/140 */;
    width: 100%;
    margin-top: -0.357143rem /* 50/140 */;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    .pc-allday-km-dp-img {
      height: 2.121429rem /* 297/140 */;
    }
    .pc-allday-km-dp-but1 {
      position: absolute;
      height: 0.214286rem /* 30/140 */;
      width: 1.071429rem /* 150/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 77%;
      left: 26.3%;
      cursor: pointer;
    }
    .pc-allday-km-dp-but2 {
      position: absolute;
      height: 0.214286rem /* 30/140 */;
      width: 1.071429rem /* 150/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 77%;
      left: 44.7%;
      cursor: pointer;
    }
    .pc-allday-km-dp-but3 {
      position: absolute;
      height: 0.214286rem /* 30/140 */;
      width: 1.071429rem /* 150/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 77%;
      left: 63%;
      cursor: pointer;
    }
  }
}
</style>
