<template>
  <div>
    <div class="header-nar">
      <div class="header-logo">
        <img class="header-img" src="../assets/bh-logo-new.png" alt="" />
      </div>
      <div class="header-phone">
        <div class="phnoe-logo"><img src="../assets/mobile-logo.gif" alt="" class="phnoe-logo" /></div>
        400-068-5018
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    showPopup() {
      this.$emit('showPopupFun', true);
    }
  }
};
</script>

<style lang="less">
.header-nar {
  display: flex;
  width: 100%;

  height: 1.297297rem /* 48/37 */;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  .header-img {
    width: 2.162162rem /* 80/37 */;
    height: 0.675676rem /* 25/37 */;
    margin-left: 0.405405rem /* 15/37 */;
    margin-bottom: 0.054054rem /* 2/37 */;
  }
}

.header-phone {
  display: flex;
  margin-right: 0.405405rem /* 15/37 */;
  margin-top: -0.108108rem /* 4/37 */;
  font-weight: 500;
  color: #333333;
  font-size: 0.432432rem /* 16/37 */;
  margin-left: 0.405405rem /* 15/37 */;
}
.header-icon {
  flex: 1;
  margin-top: -0.594595rem /* 22/37 */;
  margin-right: 0.27027rem /* 10/37 */;
  width: 0.621622rem /* 23/37 */;
  height: 0.540541rem /* 20/37 */;
}
.phnoe-logo {
  padding-right: 0.135135rem /* 5/37 */;
  margin-top: -0.081081rem /* 3/37 */;
  width: 0.945946rem /* 35/37 */;
  height: 0.810811rem /* 30/37 */;
}
</style>
