<template>
  <div class="pc-ib">
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>
    <!-- 背景图 -->
    <div class="ib-bj">
      <img
        v-lazy="imageUrl"
        class="ib-bj"
        src="../../pc-imgs/ib-bj.png"
        alt=""
        @click="consult"
        style="cursor: pointer"
      />
    </div>
    <!-- IB课程体系介绍 -->
    <div class="ib-kc-ty">
      <img class="ib-kc-ty-img" src="../../pc-imgs/ib-kc-ty.png" alt="" />
    </div>
    <!-- 英美大学的IB分数录取要求 -->
    <div class="ib-ym-qy">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ib-ym-qy-title">
            <img class="ib-ym-qy-title-img" src="../../pc-imgs/ib-ym-qy-title.png" alt="" />
            <div class="ib-ym-qy-title-hx"></div>
          </div>
          <div class="ib-ym-qy-but">
            <div
              @click="ymYqFun1"
              class="ib-ym-qy-but-samll"
              :style="{background: ymYq1 ? '#dbf240FF' : '', cursor: ymYq1 ? '' : 'pointer'}"
            >
              英国大学的IB成绩要求
            </div>
            <div
              @click="ymYqFun2"
              class="ib-ym-qy-but-samll"
              :style="{background: ymYq2 ? '#dbf240FF' : '', cursor: ymYq2 ? '' : 'pointer'}"
            >
              美国大学的IB成绩要求
            </div>
          </div>
          <img v-show="ymYq1" class="ib-ym-qy-img" src="../../pc-imgs/ib-ym-qy-img1.png" alt="" />
          <img v-show="ymYq2" class="ib-ym-qy-img" src="../../pc-imgs/ib-ym-qy-img2.png" alt="" />
          <div class="ib-ym-qy-bottom-div">
            <img class="ib-ym-qy-bottom" src="../../pc-imgs/ib-ym-qy-bottom.png" alt="" />
          </div>
          <div class="ib-ym-qy-bottom-but">
            <img class="ib-ym-qy-bottom-but1" src="../../pc-imgs/ib-but1.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- IB课程科目一览 -->
    <div class="pc-ib-kc-yl">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-ib-kc-yl-title">
            IB课程科目一览
            <div class="pc-ib-kc-yl-hx"></div>
            <div class="pc-ib-kc-yl-title-bottom">开始学习之前要先了解IB课程的常见科目</div>
          </div>
          <div class="pc-ib-kc-yl-div">
            <div class="pc-ib-kc-yl-div-samll">
              <img class="pc-ib-kc-yl-div-samll1" src="../../pc-imgs/ib-kc-yl-img1.png" alt="" @click="consult" />
              <img class="pc-ib-kc-yl-div-samll1" src="../../pc-imgs/ib-kc-yl-img2.png" alt="" @click="consult" />
              <img class="pc-ib-kc-yl-div-samll1" src="../../pc-imgs/ib-kc-yl-img3.png" alt="" @click="consult" />
            </div>
            <div class="pc-ib-kc-yl-div-samll">
              <img class="pc-ib-kc-yl-div-samll2" src="../../pc-imgs/ib-kc-yl-img4.png" alt="" @click="consult" />
              <img class="pc-ib-kc-yl-div-samll2" src="../../pc-imgs/ib-kc-yl-img5.png" alt="" @click="consult" />
              <img class="pc-ib-kc-yl-div-samll2" src="../../pc-imgs/ib-kc-yl-img6.png" alt="" @click="consult" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- IB核心课程考评详解 -->
    <div class="pc-ib-hx-kc">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-ib-hx-kc-title">
            <img class="pc-ib-hx-kc-title-img" src="../../pc-imgs/ib-hx-kc-title.png" alt="" />
            <div class="pc-ib-hx-kc-title-hx"></div>
          </div>
          <div class="pc-ib-hx-kc-but">
            <div
              @click="hxKcFun1"
              class="pc-ib-hx-kc-but1"
              :style="{
                background: hxKc1 ? '#46af43FF' : '',
                color: hxKc1 ? '#fff' : '#333',
                cursor: hxKc1 ? '' : 'pointer'
              }"
            >
              TOK知道理论
            </div>
            <div
              @click="hxKcFun2"
              class="pc-ib-hx-kc-but2"
              :style="{
                background: hxKc2 ? '#46af43FF' : '',
                color: hxKc2 ? '#fff' : '#333',
                cursor: hxKc2 ? '' : 'pointer'
              }"
            >
              EE拓展论文
            </div>
            <div
              @click="hxKcFun3"
              class="pc-ib-hx-kc-but3"
              :style="{
                background: hxKc3 ? '#46af43FF' : '',
                color: hxKc3 ? '#fff' : '#333',
                cursor: hxKc3 ? '' : 'pointer'
              }"
            >
              CAS创新行动服务
            </div>
          </div>
          <div class="pc-ib-hx-kc-img1-div" v-show="hxKc1">
            <img class="pc-ib-hx-kc-img1" src="../../pc-imgs/ib-hx-kc-img1.png" alt="" />
            <div class="pc-ib-hx-kc-img-but1" @click="consult"></div>
          </div>
          <div class="pc-ib-hx-kc-img2-div" v-show="hxKc2">
            <img class="pc-ib-hx-kc-img2" src="../../pc-imgs/ib-hx-kc-img2.png" alt="" />
            <div class="pc-ib-hx-kc-img-but2" @click="consult"></div>
          </div>
          <div class="pc-ib-hx-kc-img3-div" v-show="hxKc3">
            <img class="pc-ib-hx-kc-img3" src="../../pc-imgs/ib-hx-kc-img3.png" alt="" />
            <div class="pc-ib-hx-kc-img-but3" @click="consult"></div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海IB选课建议 -->
    <div class="ib-xk-jy-div">
      <img class="ib-xk-jy-div" src="../../pc-imgs/ib-xk-jy.png" alt="" />
      <div class="ib-xk-jy-but" @click="consult"></div>
    </div>
    <!-- 热门留学专业对IB课程的偏好 -->
    <div class="pc-ib-rm-zy">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-ib-rm-zy-title">
            热门留学专业对IB课程的偏好
            <div class="pc-ib-rm-zy-title-hx"></div>
            <div class="pc-ib-rm-zy-title-bottom">我们以中国IB学生常申请的专业为例，看看国外大学热门专业青睐的课程</div>
          </div>
          <div class="pc-ib-rm-zy-div">
            <div class="pc-ib-rm-zy-div-left">
              <div
                @click="rmZyFun1"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy1 ? '#46af43FF' : '',
                  color: rmZy1 ? '#fff' : '#333',
                  cursor: rmZy1 ? '' : 'pointer'
                }"
              >
                工程专业
              </div>
              <div
                @click="rmZyFun2"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy2 ? '#46af43FF' : '',
                  color: rmZy2 ? '#fff' : '#333',
                  cursor: rmZy2 ? '' : 'pointer'
                }"
              >
                经济学专业
              </div>
              <div
                @click="rmZyFun3"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy3 ? '#46af43FF' : '',
                  color: rmZy3 ? '#fff' : '#333',
                  cursor: rmZy3 ? '' : 'pointer'
                }"
              >
                化学专业
              </div>
              <div
                @click="rmZyFun4"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy4 ? '#46af43FF' : '',
                  color: rmZy4 ? '#fff' : '#333',
                  cursor: rmZy4 ? '' : 'pointer'
                }"
              >
                物理专业
              </div>
              <div
                @click="rmZyFun5"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy5 ? '#46af43FF' : '',
                  color: rmZy5 ? '#fff' : '#333',
                  cursor: rmZy5 ? '' : 'pointer'
                }"
              >
                生物专业
              </div>
              <div
                @click="rmZyFun6"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy6 ? '#46af43FF' : '',
                  color: rmZy6 ? '#fff' : '#333',
                  cursor: rmZy6 ? '' : 'pointer'
                }"
              >
                医学类专业
              </div>
              <div
                @click="rmZyFun7"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy7 ? '#46af43FF' : '',
                  color: rmZy7 ? '#fff' : '#333',
                  cursor: rmZy7 ? '' : 'pointer'
                }"
              >
                数学专业
              </div>
              <div
                @click="rmZyFun8"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy8 ? '#46af43FF' : '',
                  color: rmZy8 ? '#fff' : '#333',
                  cursor: rmZy8 ? '' : 'pointer'
                }"
              >
                心理学专业
              </div>
              <div
                @click="rmZyFun9"
                class="pc-ib-rm-zy-div-left-samll"
                :style="{
                  background: rmZy9 ? '#46af43FF' : '',
                  color: rmZy9 ? '#fff' : '#333',
                  cursor: rmZy9 ? '' : 'pointer'
                }"
              >
                计算机专业
              </div>
            </div>
            <div class="pc-ib-rm-zy-div-right">
              <img v-show="rmZy1" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img1.png" alt="" />
              <img v-show="rmZy2" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img2.png" alt="" />
              <img v-show="rmZy3" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img3.png" alt="" />
              <img v-show="rmZy4" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img4.png" alt="" />
              <img v-show="rmZy5" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img5.png" alt="" />
              <img v-show="rmZy6" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img6.png" alt="" />
              <img v-show="rmZy7" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img7.png" alt="" />
              <img v-show="rmZy8" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img8.png" alt="" />
              <img v-show="rmZy9" class="pc-ib-rm-zy-div-right-img" src="../../pc-imgs/ib-rm-kc-img9.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海IB课程设置 -->
    <div class="ib-kc-sz">
      <img class="ib-kc-sz" src="../../pc-imgs/ib-kc-sz.png" alt="" />
      <div class="ib-kc-sz-but" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="ib-teacher">
      <img class="ib-teacher-img" src="../../pc-imgs/ib-teacher.png" alt="" @click="consult" />
      <div class="ib-teacher-img-but1" @click="consult"></div>
      <div class="ib-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 澜大半海优势 -->
    <img class="ib-ld-bh-ys" src="../../pc-imgs/ib-ld-bh-ys.png" alt="" />
    <!-- 跟踪学习 -->
    <img class="ib-gz-study" src="../../pc-imgs/ib-gz-study.png" alt="" />
    <!-- 细致服务 -->
    <div class="ib-xz-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ib-xz-fw-title">
            细致入微贴心服务
            <div class="ib-xz-fw-title-hx"></div>
          </div>
          <div class="ib-xz-fw-div">
            <div class="ib-xz-fw-div1">
              <div class="ib-xz-fw-div1-small">
                <div class="ib-xz-fw-div1-small-text">到离校通知家长</div>
              </div>
              <div class="ib-xz-fw-div2-small">
                <div class="ib-xz-fw-div2-small-text">测试问题必反馈</div>
              </div>
              <div class="ib-xz-fw-div3-small">
                <div class="ib-xz-fw-div3-small-text">上课收手机专心学习</div>
              </div>
            </div>
            <div class="ib-xz-fw-div1">
              <div class="ib-xz-fw-div4-small">
                <div class="ib-xz-fw-div4-small-text">课堂内容详细反馈全面了解</div>
              </div>
              <div class="ib-xz-fw-div5-small">
                <div class="ib-xz-fw-div5-small-text">学习记录实时跟踪</div>
              </div>
              <div class="ib-xz-fw-div6-small">
                <div class="ib-xz-fw-div6-small-text">课后任务细化快乐学习</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <!-- 服务学员来自全国国际学校 -->
    <img class="ib-fw-qg-xy" src="../../pc-imgs/ib-fw-qg-xy.png" alt="" />
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
import Footer from '../../components/PcFooter.vue';

export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      ymYq1: true,
      ymYq2: false,
      hxKc1: true,
      hxKc2: false,
      hxKc3: false,
      rmZy1: true,
      rmZy2: false,
      rmZy3: false,
      rmZy4: false,
      rmZy5: false,
      rmZy6: false,
      rmZy7: false,
      rmZy8: false,
      rmZy9: false
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    ymYqFun1() {
      this.ymYq1 = true;
      this.ymYq2 = false;
    },
    ymYqFun2() {
      this.ymYq1 = false;
      this.ymYq2 = true;
    },
    hxKcFun1() {
      this.hxKc1 = true;
      this.hxKc2 = false;
      this.hxKc3 = false;
    },
    hxKcFun2() {
      this.hxKc1 = false;
      this.hxKc2 = true;
      this.hxKc3 = false;
    },
    hxKcFun3() {
      this.hxKc1 = false;
      this.hxKc2 = false;
      this.hxKc3 = true;
    },
    rmZyFun1() {
      this.rmZy1 = true;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun2() {
      this.rmZy1 = false;
      this.rmZy2 = true;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun3() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = true;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun4() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = true;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun5() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = true;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun6() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = true;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun7() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = true;
      this.rmZy8 = false;
      this.rmZy9 = false;
    },
    rmZyFun8() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = true;
      this.rmZy9 = false;
    },
    rmZyFun9() {
      this.rmZy1 = false;
      this.rmZy2 = false;
      this.rmZy3 = false;
      this.rmZy4 = false;
      this.rmZy5 = false;
      this.rmZy6 = false;
      this.rmZy7 = false;
      this.rmZy8 = false;
      this.rmZy9 = true;
    }
  }
};
</script>

<style lang="less">
.pc-ib {
  .ib-bj {
    padding-top: 0.352857rem;
    height: 3.714286rem /* 520/140 */;
    width: 10.285714rem /* 1440/140 */;
    cursor: pointer;
  }
  .ib-kc-ty {
    margin-top: 0.352857rem;
    height: 2.921429rem /* 409/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ib-kc-ty-img {
      height: 2.464286rem /* 345/140 */;
    }
  }
  .ib-ym-qy {
    background-color: #46af43ff;
    padding-top: 0.242857rem /* 34/140 */;
    padding-bottom: 0.142857rem /* 20/140 */;
    width: 100%;
    .ib-ym-qy-title {
      height: 0.5rem /* 70/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.064286rem /* 9/140 */;
      flex-direction: column;
      .ib-ym-qy-title-img {
        height: 0.2rem /* 28/140 */;
        margin-bottom: 0.071429rem /* 10/140 */;
      }
      .ib-ym-qy-title-hx {
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #ffffff;
        margin-bottom: 0.142857rem /* 20/140 */;
      }
    }
    .ib-ym-qy-but {
      height: 0.428571rem /* 60/140 */;
      width: 100%;
      display: flex;
      line-height: 0.428571rem /* 60/140 */;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 0.171429rem /* 24/140 */;
      color: #333333;
      .ib-ym-qy-but-samll {
        flex: 1;
        text-align: center;
        background-color: #90cf89ff;
      }
    }
    .ib-ym-qy-img {
      margin-top: 0.142857rem /* 20/140 */;
      width: 100%;
    }
    .ib-ym-qy-bottom-div {
      height: 0.257143rem /* 36/140 */;
      display: flex;
      justify-content: center;
      margin-top: 0.071429rem /* 10/140 */;
      .ib-ym-qy-bottom {
        height: 0.257143rem /* 36/140 */;
      }
    }
    .ib-ym-qy-bottom-but {
      margin-top: 0.142857rem /* 20/140 */;
      height: 0.357143rem /* 50/140 */;
      width: 100%;
      display: flex;
      justify-content: center;
      .ib-ym-qy-bottom-but1 {
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
    }
  }
  .pc-ib-kc-yl {
    height: 6.542857rem /* 916/140 */;
    width: 100%;
    background-color: #f5f5f5ff;
    .pc-ib-kc-yl-title {
      height: 0.785714rem /* 110/140 */;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: HelloFont, HelloFont;
      font-weight: bold;
      font-size: 0.228571rem /* 32/140 */;
      color: #333333;
      padding-top: 0.285714rem /* 40/140 */;
      .pc-ib-kc-yl-hx {
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #46af43;
        margin-top: 0.064286rem /* 9/140 */;
      }
      .pc-ib-kc-yl-title-bottom {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.142857rem /* 20/140 */;
        color: #777777;
        margin-top: 0.064286rem /* 9/140 */;
      }
    }
    .pc-ib-kc-yl-div {
      width: 100%;
      height: 5.785714rem /* 810/140 */;
      .pc-ib-kc-yl-div-samll {
        display: flex;
        height: 2.857143rem /* 400/140 */;
        width: 100%;

        .pc-ib-kc-yl-div-samll1 {
          flex: 1;
          height: 2.857143rem /* 400/140 */;
          cursor: pointer;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }

        .pc-ib-kc-yl-div-samll1:hover {
          transform: translateY(-0.107143rem /* 15/140 */); /* 向上浮动20像素 */
        }
        .pc-ib-kc-yl-div-samll2 {
          flex: 1;
          height: 2.385714rem /* 334/140 */;
          cursor: pointer;
          transition: transform 0.3s ease; /* 添加过渡效果 */
        }
        .pc-ib-kc-yl-div-samll2:hover {
          transform: translateY(-0.107143rem /* 15/140 */); /* 向上浮动20像素 */
        }
      }
    }
  }
  .pc-ib-hx-kc {
    width: 100%;
    padding-top: 0.214286rem /* 30/140 */;
    padding-bottom: 0.214286rem /* 30/140 */;
    .pc-ib-hx-kc-title {
      height: 0.357143rem /* 50/140 */;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .pc-ib-hx-kc-title-img {
        height: 0.192857rem /* 27/140 */;
      }
      .pc-ib-hx-kc-title-hx {
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #46af43;
        margin-top: 0.064286rem /* 9/140 */;
      }
    }
    .pc-ib-hx-kc-but {
      position: relative;
      width: 100%;
      height: 0.464286rem /* 65/140 */;
      .pc-ib-hx-kc-but1 {
        position: absolute;
        top: 10%;
        left: 20%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 0.271429rem /* 38/140 */;
        height: 0.271429rem /* 38/140 */;
        width: 0.985714rem /* 138/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        font-size: 0.1rem /* 14/140 */;
      }
      .pc-ib-hx-kc-but2 {
        position: absolute;
        top: 10%;
        left: 41%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 0.271429rem /* 38/140 */;
        height: 0.271429rem /* 38/140 */;
        width: 0.985714rem /* 138/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        font-size: 0.1rem /* 14/140 */;
      }
      .pc-ib-hx-kc-but3 {
        position: absolute;
        top: 10%;
        left: 62%;
        box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 0.271429rem /* 38/140 */;
        height: 0.271429rem /* 38/140 */;
        width: 0.985714rem /* 138/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        font-size: 0.1rem /* 14/140 */;
      }
    }
    .pc-ib-hx-kc-img1-div {
      position: relative;
      width: 100%;

      .pc-ib-hx-kc-img1 {
        width: 100%;
      }
      .pc-ib-hx-kc-img-but1 {
        position: absolute;
        height: 0.257143rem /* 36/140 */;
        width: 1.357143rem /* 190/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
    .pc-ib-hx-kc-img2-div {
      position: relative;
      width: 100%;

      .pc-ib-hx-kc-img2 {
        width: 100%;
      }
      .pc-ib-hx-kc-img-but2 {
        position: absolute;
        height: 0.257143rem /* 36/140 */;
        width: 1.357143rem /* 190/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        top: 85%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
    .pc-ib-hx-kc-img3-div {
      position: relative;
      width: 100%;

      .pc-ib-hx-kc-img3 {
        width: 100%;
      }
      .pc-ib-hx-kc-img-but3 {
        position: absolute;
        height: 0.257143rem /* 36/140 */;
        width: 1.357143rem /* 190/140 */;
        border-radius: 20px;
        top: 91%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
  }
  .ib-xk-jy-div {
    position: relative;
    width: 100%;
    height: auto;
    .ib-xk-jy-but {
      position: absolute;
      width: 1.428571rem /* 200/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 90.8%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .pc-ib-rm-zy {
    // height: auto;
    width: 100%;
    padding-top: 0.214286rem /* 30/140 */;
    padding-bottom: 0.214286rem /* 30/140 */;

    .pc-ib-rm-zy-title {
      height: 0.635714rem /* 89/140 */;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: HelloFont, HelloFont;
      font-weight: bold;
      font-size: 0.228571rem /* 32/140 */;
      color: #333333;
      margin-bottom: 0.071429rem /* 10/140 */;
      .pc-ib-rm-zy-title-hx {
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #46af43;
        margin-bottom: 0.064286rem /* 9/140 */;
      }
      .pc-ib-rm-zy-title-bottom {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.142857rem /* 20/140 */;
        color: #777777;
        margin-bottom: 0.142857rem /* 20/140 */;
      }
    }
    .pc-ib-rm-zy-div {
      display: flex;
      width: 100%;
      // height: auto;
      .pc-ib-rm-zy-div-left {
        flex: 1;
        background-color: #f4fcf3ff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .pc-ib-rm-zy-div-left-samll {
          // height: au;
          text-align: center;
          line-height: 0.285714rem /* 40/140 */;
          font-size: 0.114286rem /* 16/140 */;
        }
      }
      .pc-ib-rm-zy-div-right {
        flex: 3.75;
        .pc-ib-rm-zy-div-right-img {
          width: 100%;
        }
      }
    }
  }
  .ib-kc-sz {
    position: relative;
    height: auto;
    width: 100%;
    .ib-kc-sz-but {
      position: absolute;
      height: 0.257143rem /* 36/140 */;
      width: 1.428571rem /* 200/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 86%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .ib-teacher {
    position: relative;
    height: 5.607143rem /* 785/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ib-teacher-img {
      height: 5.107143rem /* 715/140 */;
    }
    .ib-teacher-img-but1 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91%;
      left: 33%;
      cursor: pointer;
    }
    .ib-teacher-img-but2 {
      position: absolute;
      width: 1.321429rem /* 185/140 */;
      height: 0.271429rem /* 38/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      top: 91%;
      left: 53%;
      cursor: pointer;
    }
  }
  .ib-ld-bh-ys {
    height: auto;
    width: 100%;
  }
  .ib-gz-study {
    height: auto;
    width: 100%;
  }
  .ib-xz-fw {
    height: 4.407143rem /* 617/140 */;
    width: 100%;
    padding-top: 0.242857rem /* 34/140 */;
    padding-bottom: 0.171429rem /* 24/140 */;
    .ib-xz-fw-title {
      height: 0.428571rem /* 60/140 */;
      width: 100%;
      display: flex;
      align-items: center;
      font-family: HelloFont, HelloFont;
      font-weight: bold;
      font-size: 0.228571rem /* 32/140 */;
      color: #333333;
      flex-direction: column;
      line-height: 0.285714rem /* 40/140 */;
      .ib-xz-fw-title-hx {
        margin-top: 0.064286rem /* 9/140 */;
        width: 0.635714rem /* 89/140 */;
        height: 0.042857rem /* 6/140 */;
        background: #46af43;
      }
    }
    .ib-xz-fw-div {
      height: 3.642857rem /* 510/140 */;
      width: 100%;
      font-size: 0.114286rem /* 16/140 */;
      .ib-xz-fw-div1 {
        display: flex;
        width: 100%;
        height: 1.75rem /* 245/140 */;
        margin-bottom: 0.057143rem /* 8/140 */;
        .ib-xz-fw-div1-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img1.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ib-xz-fw-div1-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div2-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img2.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div2-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ib-xz-fw-div2-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div3-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img3.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div3-small-text {
            position: absolute;
            top: 84%;
            left: 24%;
          }
        }
        .ib-xz-fw-div3-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div4-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img4.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div4-small-text {
            position: absolute;
            top: 84%;
            left: 11%;
          }
        }
        .ib-xz-fw-div4-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div5-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img5.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div5-small-text {
            position: absolute;
            top: 84%;
            left: 24%;
          }
        }
        .ib-xz-fw-div5-small:hover {
          color: #46af43ff;
        }
        .ib-xz-fw-div6-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img6.png);
          background-repeat: no-repeat;
          background-size: cover;
          .ib-xz-fw-div6-small-text {
            position: absolute;
            top: 84%;
            left: 21%;
          }
        }
        .ib-xz-fw-div6-small:hover {
          color: #46af43ff;
        }
      }
    }
  }
  .ib-fw-qg-xy {
    height: auto;
    width: 100%;
  }
}
</style>
