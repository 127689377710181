<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景页面 -->
    <div class="ys-bj">
      <img v-lazy="imageUrl" class="ys-bj" src="../../pc-imgs/ys-bj.png" alt="" @click="consult" />
    </div>
    <!-- 雅思培训 为什么选择半海 -->
    <div class="ys-px-why-bh">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ys-px-why-bh-title">
            <img class="ys-px-why-bh-title-img" src="../../pc-imgs/ys-px-why-bh-title.png" alt="" />
          </div>
          <div class="ys-px-why-bh-title-context">
            <img
              class="ys-px-why-bh-title-context-img"
              src="../../pc-imgs/ys-px-why-bh-title-context-img1.png"
              alt=""
            />
            <img
              class="ys-px-why-bh-title-context-img"
              src="../../pc-imgs/ys-px-why-bh-title-context-img2.png"
              alt=""
            />
            <img
              class="ys-px-why-bh-title-context-img"
              src="../../pc-imgs/ys-px-why-bh-title-context-img3.png"
              alt=""
            />
            <img
              class="ys-px-why-bh-title-context-img"
              src="../../pc-imgs/ys-px-why-bh-title-context-img4.png"
              alt=""
            />
          </div>
          <div class="ys-px-why-bh-but">
            <img class="ys-px-why-bh-but1" src="../../pc-imgs/ys-mobile-but1.png" alt="" @click="consult" />
            <img class="ys-px-why-bh-but2" src="../../pc-imgs/ys-but1.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海雅思课程设置 -->
    <div class="ys-bh-kc-sz">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ys-bh-kc-sz-title">
            <img class="ys-bh-kc-sz-title-img" src="../../pc-imgs/ys-bh-kc-sz-title-img.png" alt="" />
          </div>
          <div class="ys-bh-kc-sz-but">
            <div
              @click="kcSzFun1"
              class="ys-bh-kc-sz-but-samll"
              :style="{
                background: kcSz1 ? '#F5A823FF' : '#fff',
                color: kcSz1 ? '#fff' : '#333',
                cursor: kcSz1 ? '' : 'pointer',
                borderRadius: '20px 0 0 20px'
              }"
            >
              预备衔接班
            </div>
            <div
              @click="kcSzFun2"
              class="ys-bh-kc-sz-but-samll"
              :style="{
                background: kcSz2 ? '#F5A823FF' : '#fff',
                color: kcSz2 ? '#fff' : '#333',
                cursor: kcSz2 ? '' : 'pointer'
              }"
            >
              5.5分提高班
            </div>
            <div
              @click="kcSzFun3"
              class="ys-bh-kc-sz-but-samll"
              :style="{
                background: kcSz3 ? '#F5A823FF' : '#fff',
                color: kcSz3 ? '#fff' : '#333',
                cursor: kcSz3 ? '' : 'pointer'
              }"
            >
              6分精练班
            </div>
            <div
              @click="kcSzFun4"
              class="ys-bh-kc-sz-but-samll"
              :style="{
                background: kcSz4 ? '#F5A823FF' : '#fff',
                color: kcSz4 ? '#fff' : '#333',
                cursor: kcSz4 ? '' : 'pointer'
              }"
            >
              6.5分特训班
            </div>
            <div
              @click="kcSzFun5"
              class="ys-bh-kc-sz-but-samll"
              :style="{
                background: kcSz5 ? '#F5A823FF' : '#fff',
                color: kcSz5 ? '#fff' : '#333',
                cursor: kcSz5 ? '' : 'pointer',
                borderRadius: ' 0 20px  20px 0'
              }"
            >
              高分名师班
            </div>
          </div>
          <img v-show="kcSz1" class="ys-bh-kc-sz-img" src="../../pc-imgs/ys-bh-kc-sz-img1.png" alt="" />
          <img v-show="kcSz2" class="ys-bh-kc-sz-img" src="../../pc-imgs/ys-bh-kc-sz-img2.png" alt="" />
          <img v-show="kcSz3" class="ys-bh-kc-sz-img" src="../../pc-imgs/ys-bh-kc-sz-img3.png" alt="" />
          <img v-show="kcSz4" class="ys-bh-kc-sz-img" src="../../pc-imgs/ys-bh-kc-sz-img4.png" alt="" />
          <img v-show="kcSz5" class="ys-bh-kc-sz-img" src="../../pc-imgs/ys-bh-kc-sz-img5.png" alt="" />
          <div class="ys-bh-kc-sz-bottom">
            <img class="ys-bh-kc-sz-bottom-but1" src="../../pc-imgs/ys-mobile-but2.png" alt="" @click="consult" />
            <img class="ys-bh-kc-sz-bottom-but2" src="../../pc-imgs/ys-but2.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 雅思学习的这些问题 你中了吗？ -->
    <div class="ys-study-question">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="ys-study-question-title">
            <img class="ys-study-question-title-img" src="../../pc-imgs/ys-study-question-title-img.png" alt="" />
          </div>
          <div class="ys-study-question-but">
            <div
              @click="studyQuestionFun1"
              class="ys-study-question-but-small1"
              :style="{
                background: studyQuestion1 ? '#2790fdFF' : '',
                color: studyQuestion1 ? '#fff' : '#333',
                cursor: studyQuestion1 ? '' : 'pointer'
              }"
            >
              雅思基础(4.5/5.0)
            </div>
            <div
              @click="studyQuestionFun2"
              class="ys-study-question-but-small2"
              :style="{
                background: studyQuestion2 ? '#2790fdFF' : '',
                color: studyQuestion2 ? '#fff' : '#333',
                cursor: studyQuestion2 ? '' : 'pointer'
              }"
            >
              雅思强化(5.0/5.5)
            </div>
            <div
              @click="studyQuestionFun3"
              class="ys-study-question-but-small3"
              :style="{
                background: studyQuestion3 ? '#2790fdFF' : '',
                color: studyQuestion3 ? '#fff' : '#333',
                cursor: studyQuestion3 ? '' : 'pointer'
              }"
            >
              雅思冲刺(6.0/6.5)
            </div>
          </div>
          <img
            v-show="studyQuestion1"
            class="ys-study-question-img"
            src="../../pc-imgs/ys-study-question-img1.png"
            alt=""
            @click="consult"
          />
          <img
            v-show="studyQuestion2"
            class="ys-study-question-img"
            src="../../pc-imgs/ys-study-question-img2.png"
            alt=""
            @click="consult"
          />
          <img
            v-show="studyQuestion3"
            class="ys-study-question-img"
            src="../../pc-imgs/ys-study-question-img3.png"
            alt=""
            @click="consult"
          />
          <div class="ys-study-question-bottom-but">
            <img class="ys-study-question-bottom-but1" src="../../pc-imgs/ys-mobile-but1.png" alt="" @click="consult" />
            <img class="ys-study-question-bottom-but2" src="../../pc-imgs/ys-but3.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 雅思学习小技巧 -->
    <div class="ys-study-jq">
      <img class="ys-study-jq" src="../../pc-imgs/ys-study-jq.png" alt="" />
      <div class="ys-study-jq-but1" @click="consult"></div>
      <div class="ys-study-jq-but2" @click="consult"></div>
    </div>
    <!-- 英国G5院校雅思要求 -->
    <div class="pc-ys-yg-yq">
      <img class="pc-ys-yg-yq" src="../../pc-imgs/ys-yg-yq.png" alt="" />
      <div class="pc-ys-yg-yq-but1" @click="consult"></div>
      <div class="pc-ys-yg-yq-but2" @click="consult"></div>
    </div>
    <!-- 半海雅思方案 -->
    <div class="ys-fa">
      <img class="ys-fa-img" src="../../pc-imgs/ys-fa-img.png" alt="" />
    </div>
    <!-- 半海语培服务流程 -->
    <div class="ys-yp-fw">
      <img class="ys-yp-fw" src="../../pc-imgs/ys-yp-fw.png" alt="" />
      <div class="ys-yp-fw-but1" @click="consult"></div>
      <div class="ys-yp-fw-but2" @click="consult"></div>
    </div>
    <!-- 完善的雅恩轨学闭环服务 -->
    <img class="ys-study-bh" src="../../pc-imgs/ys-study-bh.png" alt="" />
    <!-- 教学团队 -->
    <div class="new-al-teacher">
      <img class="new-al-teacher-img" src="../../pc-imgs/new-al-teacher-img.png" alt="" @click="consult" />
      <div class="new-al-teacher-img-but1" @click="consult"></div>
      <div class="new-al-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 校区环境 -->
    <div class="home-xq-hj">
      <img class="home-xq-hj-img" src="../../pc-imgs/home-xq-hj.png" alt="" />
    </div>

    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      kcSz1: true,
      kcSz2: false,
      kcSz3: false,
      kcSz4: false,
      kcSz5: false,
      studyQuestion1: true,
      studyQuestion2: false,
      studyQuestion3: false
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    kcSzFun1() {
      this.kcSz1 = true;
      this.kcSz2 = false;
      this.kcSz3 = false;
      this.kcSz4 = false;
      this.kcSz5 = false;
    },
    kcSzFun2() {
      this.kcSz1 = false;
      this.kcSz2 = true;
      this.kcSz3 = false;
      this.kcSz4 = false;
      this.kcSz5 = false;
    },
    kcSzFun3() {
      this.kcSz1 = false;
      this.kcSz2 = false;
      this.kcSz3 = true;
      this.kcSz4 = false;
      this.kcSz5 = false;
    },
    kcSzFun4() {
      this.kcSz1 = false;
      this.kcSz2 = false;
      this.kcSz3 = false;
      this.kcSz4 = true;
      this.kcSz5 = false;
    },
    kcSzFun5() {
      this.kcSz1 = false;
      this.kcSz2 = false;
      this.kcSz3 = false;
      this.kcSz4 = false;
      this.kcSz5 = true;
    },
    studyQuestionFun1() {
      this.studyQuestion1 = true;
      this.studyQuestion2 = false;
      this.studyQuestion3 = false;
    },
    studyQuestionFun2() {
      this.studyQuestion1 = false;
      this.studyQuestion2 = true;
      this.studyQuestion3 = false;
    },
    studyQuestionFun3() {
      this.studyQuestion1 = false;
      this.studyQuestion2 = false;
      this.studyQuestion3 = true;
    }
  }
};
</script>

<style lang="less">
.ys-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
}
.ys-px-why-bh {
  margin-top: 0.352857rem;
  height: auto;
  width: 100%;
  padding-top: 0.25rem /* 35/140 */;
  padding-bottom: 0.214286rem /* 30/140 */;
  .ys-px-why-bh-title {
    width: 100%;
    height: 0.192857rem /* 27/140 */;
    display: flex;
    justify-content: center;
    margin-bottom: 0.128571rem /* 18/140 */;
    .ys-px-why-bh-title-img {
      height: 0.192857rem /* 27/140 */;
    }
  }
  .ys-px-why-bh-title-context {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ys-px-why-bh-title-context-img {
      height: auto;
      width: 24%;
      transition: transform 0.3s ease; /* 添加过渡效果 */
    }
    .ys-px-why-bh-title-context-img:hover {
      transform: translateY(-0.142857rem /* 20/140 */);
    }
  }
  .ys-px-why-bh-but {
    position: relative;
    height: 0.392857rem /* 55/140 */;
    width: 100%;
    .ys-px-why-bh-but1 {
      position: absolute;
      height: 0.257143rem /* 36/140 */;
      cursor: pointer;
      top: 45%;
      left: 20%;
    }
    .ys-px-why-bh-but2 {
      position: absolute;
      height: 0.257143rem /* 36/140 */;
      cursor: pointer;
      top: 45%;
      left: 60%;
    }
  }
}
.ys-bh-kc-sz {
  width: 100%;
  padding-top: 0.25rem /* 35/140 */;
  background-color: #2790fdff;
  .ys-bh-kc-sz-title {
    height: 0.192857rem /* 27/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.157143rem /* 22/140 */;
    .ys-bh-kc-sz-title-img {
      height: 0.192857rem /* 27/140 */;
    }
  }
  .ys-bh-kc-sz-but {
    height: 0.485714rem /* 68/140 */;
    margin-bottom: 0.085714rem /* 12/140 */;
    width: 100%;
    display: flex;
    background-color: #fff;
    line-height: 0.485714rem /* 68/140 */;
    border-radius: 0.142857rem /* 20/140 */ !important;
    font-size: 0.142857rem /* 20/140 */;
    .ys-bh-kc-sz-but-samll {
      text-align: center;
      flex: 1;
    }
  }
  .ys-bh-kc-sz-img {
    width: 100%;
  }
  .ys-bh-kc-sz-bottom {
    height: 0.5rem /* 70/140 */;
    width: 100%;
    position: relative;
    .ys-bh-kc-sz-bottom-but1 {
      height: 0.257143rem /* 36/140 */;
      position: absolute;
      top: 20%;
      left: 20%;
      cursor: pointer;
    }
    .ys-bh-kc-sz-bottom-but2 {
      height: 0.257143rem /* 36/140 */;
      position: absolute;
      top: 20%;
      left: 60%;
      cursor: pointer;
    }
  }
}
.ys-study-question {
  width: 100%;
  padding-top: 0.25rem /* 35/140 */;
  .ys-study-question-title {
    height: 0.192857rem /* 27/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.142857rem /* 20/140 */;
    .ys-study-question-title-img {
      height: 0.192857rem /* 27/140 */;
    }
  }
  .ys-study-question-but {
    position: relative;
    height: 0.414286rem /* 58/140 */;
    width: 100%;
    margin-bottom: 0.114286rem /* 16/140 */;
    font-size: 0.114286rem /* 16/140 */;
    .ys-study-question-but-small1 {
      position: absolute;
      height: 0.271429rem /* 38/140 */;
      width: 1.135714rem /* 159/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
      top: 10%;
      left: 16%;
      line-height: 0.271429rem /* 38/140 */;
      text-align: center;
    }
    .ys-study-question-but-small2 {
      position: absolute;
      height: 0.271429rem /* 38/140 */;
      width: 1.135714rem /* 159/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
      top: 10%;
      left: 40%;
      line-height: 0.271429rem /* 38/140 */;
      text-align: center;
    }
    .ys-study-question-but-small3 {
      position: absolute;
      height: 0.271429rem /* 38/140 */;
      width: 1.142857rem /* 160/140 */;
      border-radius: 0.142857rem /* 20/140 */;
      box-shadow: 0 0 0.071429rem /* 10/140 */ rgba(0, 0, 0, 0.1);
      top: 10%;
      left: 65%;
      line-height: 0.271429rem /* 38/140 */;
      text-align: center;
    }
  }
  .ys-study-question-img {
    width: 100%;
    cursor: pointer;
    height: auto;
  }
  .ys-study-question-bottom-but {
    height: 0.607143rem /* 85/140 */;
    width: 100%;
    position: relative;
    .ys-study-question-bottom-but1 {
      cursor: pointer;
      position: absolute;
      top: 20%;
      left: 17%;
      height: 0.257143rem /* 36/140 */;
    }
    .ys-study-question-bottom-but2 {
      cursor: pointer;
      position: absolute;
      top: 20%;
      left: 55%;
      height: 0.257143rem /* 36/140 */;
    }
  }
}
.ys-study-jq {
  position: relative;
  width: 100%;
  height: auto;
  .ys-study-jq-but1 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 88.5%;
    left: 34%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
  .ys-study-jq-but2 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 88.5%;
    left: 53.4%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
}
.pc-ys-yg-yq {
  height: auto;
  width: 100%;
  position: relative;
  .pc-ys-yg-yq-but1 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 86.8%;
    left: 34%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
  .pc-ys-yg-yq-but2 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 86.5%;
    left: 53.5%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
}
.ys-fa {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.142857rem /* 20/140 */;
  padding-bottom: 0.182857rem /* 20/140 */;
  .ys-fa-img {
    height: 1.95rem /* 273/140 */;
  }
}
.ys-yp-fw {
  height: auto;
  width: 100%;
  position: relative;
  .ys-yp-fw-but1 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 85%;
    left: 34%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
  .ys-yp-fw-but2 {
    position: absolute;
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    top: 85.4%;
    left: 53.5%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
}
.ys-study-bh {
  height: auto;
  width: 100%;
}
.home-xq-hj {
  height: 2.071429rem /* 290/140 */;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .home-xq-hj-img {
    height: 1.685714rem /* 236/140 */;
  }
}
.new-al-teacher {
  position: relative;
  height: 5.607143rem /* 785/140 */;
  width: 100%;
  padding-top: 0.285714rem /* 40/140 */;
}
.new-al-teacher-img {
  height: 5.107143rem /* 715/140 */;
  cursor: pointer;
}
.new-al-teacher-img-but1 {
  position: absolute;
  width: 1.321429rem /* 185/140 */;
  height: 0.271429rem /* 38/140 */;
  border-radius: 0.142857rem /* 20/140 */;
  top: 91.5%;
  left: 33%;
  cursor: pointer;
}
.new-al-teacher-img-but2 {
  position: absolute;
  width: 1.321429rem /* 185/140 */;
  height: 0.271429rem /* 38/140 */;
  border-radius: 0.142857rem /* 20/140 */;
  top: 91.5%;
  left: 53%;
  cursor: pointer;
}
</style>
