<template>
  <div class="pc-al">
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>

    <!-- 背景图 -->
    <div class="new-al-bj">
      <img
        v-lazy="imageUrl"
        class="new-al-bj"
        src="../../pc-imgs/new-al-bj.png"
        alt=""
        @click="consult"
        style="cursor: pointer"
      />
    </div>
    <!-- AL课程认可度/英国大学申请AL要求 -->
    <div class="new-kc-yq">
      <div class="new-kc-yq-left"></div>
      <div class="new-kc-yq-middle">
        <div class="new-kc-yq-but">
          <div
            @click="kcYqFun1"
            class="new-kc-yq-but1"
            :style="{backgroundColor: kcYq1 ? '#fcbe77FF' : '#FFE4C4FF', cursor: kcYq1 ? '' : 'pointer'}"
          >
            A-Level课程认可度
          </div>
          <div
            @click="kcYqFun2"
            class="new-kc-yq-but2"
            :style="{backgroundColor: kcYq2 ? '#fcbe77FF' : '#FFE4C4FF', cursor: kcYq2 ? '' : 'pointer'}"
          >
            英国大学申请A-Level要求
          </div>
        </div>
        <img class="new-kc-img" v-show="kcYq1" src="../../pc-imgs/new-kc-img1.png" alt="" />
        <div class="new-kc-yg-yq" v-show="kcYq2">
          <div class="new-kc-yg-yq1">
            基准A*A*A* 通常要求4科
            <img class="new-kc-yg-yq1-img" src="../../pc-imgs/new-kc-yg-yq1-img.png" alt="" />
            第一梯队
          </div>
          <div class="new-kc-yg-yq2">
            基准A*AA 通常要求4科
            <img class="new-kc-yg-yq2-img" src="../../pc-imgs/new-kc-yg-yq2-img.png" alt="" />
            第二梯队
          </div>
          <div class="new-kc-yg-yq3">
            基准A* AA-AAB 通常要求3科
            <img class="new-kc-yg-yq3-img" src="../../pc-imgs/new-kc-yg-yq3-img.png" alt="" />
            第三梯队
          </div>
          <div class="new-kc-yg-yq4">
            基准ААА-BВB
            <img class="new-kc-yg-yq4-img" src="../../pc-imgs/new-kc-yg-yq4-img.png" alt="" />
            第四梯队
          </div>
          <div class="new-kc-yg-yq5">
            基准ААА-BВB
            <img class="new-kc-yg-yq5-img" src="../../pc-imgs/new-kc-yg-yq5-img.png" alt="" />
            第五梯队
          </div>
        </div>
        <div class="new-kc-bottom-but" v-show="kcYq1" @click="consult">
          <img class="new-al-kc-but" src="../../pc-imgs/new-al-but.png" alt="" />
        </div>
        <div class="new-kc-bottom-but1" v-show="kcYq2" @click="consult">
          <img class="new-al-kc-but" src="../../pc-imgs/new-al-but.png" alt="" />
        </div>
      </div>
      <div class="new-kc-yq-right"></div>
    </div>
    <!-- A-Level课程优势/A-Level考试须知 -->
    <div class="new-kc-ys">
      <div class="new-kc-ys-left"></div>
      <div class="new-kc-ys-middle">
        <div class="new-kc-ys-but">
          <div
            @click="kcYsFun1"
            class="new-kc-ys-but1"
            :style="{
              backgroundColor: kcYs1 ? '#55A1EDFF' : '#fff',
              color: kcYs1 ? '#fff' : '#000',
              cursor: kcYs1 ? '' : 'pointer'
            }"
          >
            A-Level课程优势
          </div>
          <div
            @click="kcYsFun2"
            class="new-kc-ys-but2"
            :style="{
              backgroundColor: kcYs2 ? '#55A1EDFF' : '#fff',
              color: kcYs2 ? '#fff' : '#000',
              cursor: kcYs2 ? '' : 'pointer'
            }"
          >
            A-Level考试须知
          </div>
        </div>
        <div class="new-kc-ys-div" v-if="kcYs1">
          <div class="new-kc-ys-div-samll">
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll2.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll3.png" alt="" />
          </div>
          <div class="new-kc-ys-div-samll">
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll4.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll5.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll6.png" alt="" />
          </div>
        </div>
        <div class="new-kc-ys-div" v-if="kcYs2">
          <div class="new-kc-ys-div-samll">
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom1.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom2.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom3.png" alt="" />
          </div>
          <div class="new-kc-ys-div-samll">
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom4.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom5.png" alt="" />
            <img class="new-kc-ys-div-samll1" src="../../pc-imgs/new-kc-ys-div-samll1-bottom6.png" alt="" />
          </div>
        </div>
        <div class="new-kc-ys-bottom-but" @click="consult">
          <img class="new-kc-ys-bottom-but-small" src="../../pc-imgs/new-al-but.png" alt="" />
        </div>
      </div>
      <div class="new-kc-ys-right"></div>
    </div>
    <!-- A-Level、IB、AP和OSSO课程的区别 -->
    <img class="new-al-kc-qb" src="../../pc-imgs/new-al-kc-qb.png" alt="" />
    <!-- 关于A-Level是否有以下疑问？ -->
    <div class="new-al-kc-question">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-al-kc-question-title">
            <img class="new-al-kc-question-title-img" src="../../pc-imgs/new-al-kc-question-title.png" alt="" />
          </div>
          <div class="new-al-kc-question-div">
            <div class="new-al-kc-question-div1">
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">目前学习情况如何？能否跟上进度？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">有没有找到适合自己的学习方法？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">是否需要A-Level辅导？辅导什么？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">目前AL成绩与大学要求是否匹配？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">在校表现如何？作业写得好吗？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
            </div>
            <div class="new-al-kc-question-div1">
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">现在哪些科目比较薄弱？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">是否需要参加语言培训班？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">留学专业选择及申请如何规划？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">除AL课程学习外还需背景提升吗？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
              <div class="new-al-kc-question-div1-small" @click="consult">
                <div class="new-al-kc-question-div1-left">
                  <img class="new-al-Q" src="../../pc-imgs/new-al-Q.png" alt="" />
                  <div class="">是否需参加夏令营/游学？</div>
                </div>
                <img class="new-al-kc-question-div1-right" src="../../pc-imgs/new-al-mf-dy.png" alt="" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 学习A-Level需要做的规划 -->
    <div class="new-al-study-gh">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-al-study-gh-title">学习A-Level需要做的规划</div>
          <div class="new-al-study-gh-but">
            <div
              @click="alStudyPlanFun1"
              class="new-al-study-gh-but1"
              :style="{background: alStudyPlan1 ? '#fcbe77FF' : '#ffe4c4FF', cursor: alStudyPlan1 ? '' : 'pointer'}"
            >
              本科申请规划
            </div>
            <div
              @click="alStudyPlanFun2"
              class="new-al-study-gh-but1"
              :style="{background: alStudyPlan2 ? '#fcbe77FF' : '#ffe4c4FF', cursor: alStudyPlan2 ? '' : 'pointer'}"
            >
              课程选择规划
            </div>
            <div
              @click="alStudyPlanFun3"
              class="new-al-study-gh-but1"
              :style="{background: alStudyPlan3 ? '#fcbe77FF' : '#ffe4c4FF', cursor: alStudyPlan3 ? '' : 'pointer'}"
            >
              学习时间规划
            </div>
            <div
              @click="alStudyPlanFun4"
              class="new-al-study-gh-but1"
              :style="{background: alStudyPlan4 ? '#fcbe77FF' : '#ffe4c4FF', cursor: alStudyPlan4 ? '' : 'pointer'}"
            >
              考试时间规划
            </div>
            <div
              @click="alStudyPlanFun5"
              class="new-al-study-gh-but1"
              :style="{background: alStudyPlan5 ? '#fcbe77FF' : '#ffe4c4FF', cursor: alStudyPlan5 ? '' : 'pointer'}"
            >
              语言学习规划
            </div>
          </div>
          <div class="new-al-study-gh-img-div">
            <img v-if="alStudyPlan1" class="new-al-study-gh-img" src="../../pc-imgs/new-al-study-gh-img1.png" alt="" />
            <img v-if="alStudyPlan2" class="new-al-study-gh-img" src="../../pc-imgs/new-al-study-gh-img2.png" alt="" />
            <img v-if="alStudyPlan3" class="new-al-study-gh-img" src="../../pc-imgs/new-al-study-gh-img3.png" alt="" />
            <img v-if="alStudyPlan4" class="new-al-study-gh-img" src="../../pc-imgs/new-al-study-gh-img4.png" alt="" />
            <img v-if="alStudyPlan5" class="new-al-study-gh-img" src="../../pc-imgs/new-al-study-gh-img5.png" alt="" />
            <div class="new-al-study-gh-img-but" @click="consult"></div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- A-Level课程适合哪些学生 -->
    <div class="new-al-sh">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <img class="new-al-sh-img" src="../../pc-imgs/new-al-sh.png" alt="" />
          <div class="new-al-sh-but">
            <img class="new-al-sh-but-samll" src="../../pc-imgs/new-al-but.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 半海A-Level课程教学目标 -->
    <img class="new-al-kc-mb" src="../../pc-imgs/new-al-kc-mb1.png" alt="" />
    <!-- 学习A-Level需要做的规划 -->
    <div class="new-al-need-gh">
      <img class="new-al-need-gh-img" src="../../pc-imgs/new-al-need-gh.png" alt="" />
      <img class="new-al-need-gh-but" src="../../pc-imgs/al-but2.png" alt="" @click="consult" />
    </div>
    <!-- 半海A-Level课程与科目介绍 -->
    <div class="new-al-kc-js">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-al-kc-js-title">
            <img class="new-al-kc-js-title-img" src="../../pc-imgs/new-al-kc-js-title.png" alt="" />
          </div>
          <img class="new-al-fl" src="../../pc-imgs/new-al-fl.png" alt="" />
          <div class="new-al-kc-js-but">
            <div class="new-al-kc-js-but11" v-if="!alKcJs1" @click="alKcJsFun1">A-Level 衔接班</div>
            <div class="new-al-kc-js-but11-click" v-else>A-Level 衔接班</div>
            <div class="new-al-kc-js-but1" v-if="!alKcJs2" @click="alKcJsFun2">A-Level 提升班</div>
            <div class="new-al-kc-js-but1-click" v-else>A-Level 提升班</div>
            <div class="new-al-kc-js-but12" v-if="!alKcJs3" @click="alKcJsFun3">A-Level 冲刺班</div>
            <div class="new-al-kc-js-but12-click" v-else>A-Level 冲刺班</div>
          </div>
          <div class="new-al-kc-js-img-div">
            <img v-show="alKcJs1" class="new-al-kc-js-img" src="../../pc-imgs/new-al-kc-js-img1.png" alt="" />
            <img v-show="alKcJs2" class="new-al-kc-js-img" src="../../pc-imgs/new-al-kc-js-img2.png" alt="" />
            <img v-show="alKcJs3" class="new-al-kc-js-img" src="../../pc-imgs/new-al-kc-js-img3.png" alt="" />
            <div class="new-al-kc-js-img-but" @click="consult"></div>
            <img class="new-al-kc-js-bottom" src="../../pc-imgs/new-al-kc-js-bottom.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 教学团队 -->
    <div class="new-al-teacher">
      <img class="new-al-teacher-img" src="../../pc-imgs/new-al-teacher-img.png" alt="" @click="consult" />
      <div class="new-al-teacher-img-but1" @click="consult"></div>
      <div class="new-al-teacher-img-but2" @click="consult"></div>
    </div>
    <!-- 预约老师开始试听 -->
    <div class="new-al-yy">
      <input type="text" class="new-al-yy-inp1" placeholder="请输入姓名" v-model="form.name" />
      <input type="text" class="new-al-yy-inp2" placeholder="请输入电话" v-model="form.mobile" />
      <img class="new-lj-but" src="../../pc-imgs/new-lj-but.png" alt="" @click="submitForm" />
    </div>
    <!-- 澜大半海优势 -->
    <img class="new-al-landa-bh-ys" src="../../pc-imgs/landa-bh-ys.png" alt="" />
    <!-- 全链路跟踪学习 -->
    <img class="new-al-gz-study" src="../../pc-imgs/new-al-gz-study.png" alt="" />
    <!-- 细致入微贴心服务 -->
    <div class="new-al-xz-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-al-xz-fw-title">
            <img class="new-al-xz-fw-title-img" src="../../pc-imgs/new-al-xz-fw-title.png" alt="" />
          </div>
          <div class="new-al-xz-fw-div">
            <div class="new-al-xz-fw-div1">
              <div class="new-al-xz-fw-div1-small">
                <div class="new-al-xz-fw-div1-small-text">到离校通知家长</div>
              </div>
              <div class="new-al-xz-fw-div2-small">
                <div class="new-al-xz-fw-div1-small-text">测试问题必反馈</div>
              </div>
              <div class="new-al-xz-fw-div3-small">
                <div class="new-al-xz-fw-div1-small-text">上课收手机专心学习</div>
              </div>
            </div>
            <div class="new-al-xz-fw-div1">
              <div class="new-al-xz-fw-div4-small">
                <div class="new-al-xz-fw-div1-small-text">课堂内容详细反馈全面了解</div>
              </div>
              <div class="new-al-xz-fw-div5-small">
                <div class="new-al-xz-fw-div1-small-text">学习记录实时跟踪</div>
              </div>
              <div class="new-al-xz-fw-div6-small">
                <div class="new-al-xz-fw-div1-small-text">课后任务细化快乐学习</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 服务学院来自全国的学校 -->
    <img class="new-fw-xy" src="../../pc-imgs/new-fw-xy.png" alt="" />
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';
import Footer from '../../components/PcFooter.vue';
export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  data() {
    return {
      kcYq1: true,
      kcYq2: false,
      kcYs1: true,
      kcYs2: false,
      alStudyPlan1: true,
      alStudyPlan2: false,
      alStudyPlan3: false,
      alStudyPlan4: false,
      alStudyPlan5: false,
      alKcJs1: true,
      alKcJs2: false,
      alKcJs3: false,
      form: {
        name: '',
        mobile: ''
      }
    };
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },
    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    },
    kcYqFun1() {
      this.kcYq1 = true;
      this.kcYq2 = false;
    },
    kcYqFun2() {
      this.kcYq1 = false;
      this.kcYq2 = true;
    },
    kcYsFun1() {
      this.kcYs1 = true;
      this.kcYs2 = false;
    },
    kcYsFun2() {
      this.kcYs1 = false;
      this.kcYs2 = true;
    },
    alStudyPlanFun1() {
      this.alStudyPlan1 = true;
      this.alStudyPlan2 = false;
      this.alStudyPlan3 = false;
      this.alStudyPlan4 = false;
      this.alStudyPlan5 = false;
    },
    alStudyPlanFun2() {
      this.alStudyPlan1 = false;
      this.alStudyPlan2 = true;
      this.alStudyPlan3 = false;
      this.alStudyPlan4 = false;
      this.alStudyPlan5 = false;
    },
    alStudyPlanFun3() {
      this.alStudyPlan1 = false;
      this.alStudyPlan2 = false;
      this.alStudyPlan3 = true;
      this.alStudyPlan4 = false;
      this.alStudyPlan5 = false;
    },
    alStudyPlanFun4() {
      this.alStudyPlan1 = false;
      this.alStudyPlan2 = false;
      this.alStudyPlan3 = false;
      this.alStudyPlan4 = true;
      this.alStudyPlan5 = false;
    },
    alStudyPlanFun5() {
      this.alStudyPlan1 = false;
      this.alStudyPlan2 = false;
      this.alStudyPlan3 = false;
      this.alStudyPlan4 = false;
      this.alStudyPlan5 = true;
    },
    alKcJsFun1() {
      this.alKcJs1 = true;
      this.alKcJs2 = false;
      this.alKcJs3 = false;
    },
    alKcJsFun2() {
      this.alKcJs1 = false;
      this.alKcJs2 = true;
      this.alKcJs3 = false;
    },
    alKcJsFun3() {
      this.alKcJs1 = false;
      this.alKcJs2 = false;
      this.alKcJs3 = true;
    }
  }
};
</script>

<style lang="less">
.pc-al {
  .new-al-bj {
    padding-top: 0.352857rem;
    width: 10.278571rem /* 1439/140 */;
    height: 3.714286rem /* 520/140 */;
  }
  .new-kc-yq {
    margin-top: 0.352857rem;
    height: 3.65rem /* 511/140 */;
    width: 10.278571rem /* 1439/140 */;
    padding-bottom: 0.285714rem /* 40/140 */;
    padding-bottom: 0.271429rem /* 38/140 */;
    display: flex;
    .new-kc-yq-left {
      flex: 5.5;
    }
    .new-kc-yq-right {
      flex: 5.5;
    }
    .new-kc-yq-middle {
      flex: 12;
      .new-kc-yq-but {
        height: 0.428571rem /* 60/140 */;
        width: 100%;
        display: flex;
        line-height: 0.428571rem /* 60/140 */;
        margin-top: 0.405714rem /* 40/140 */;
        margin-bottom: 0.228571rem /* 32/140 */;
        .new-kc-yq-but1 {
          flex: 1;
          background-color: #ffe4c4ff;
          color: #333333ff;
          text-align: center;
          font-size: 0.142857rem /* 20/140 */;
        }
        .new-kc-yq-but2 {
          flex: 1;
          background-color: #ffe4c4ff;
          color: #333333ff;
          text-align: center;
          font-size: 0.142857rem /* 20/140 */;
        }
      }
      .new-kc-img {
        width: 100%;
        height: auto;
        margin-bottom: 0.207143rem /* 29/140 */;
      }
      .new-kc-yg-yq {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .new-kc-yg-yq1 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: rgba(51, 51, 51, 0.6);
          display: flex;
          flex-direction: column;
          text-align: center;
          .new-kc-yg-yq1-img {
            height: 1.428571rem /* 200/140 */;
            width: 95%;
            margin-bottom: 0.085714rem /* 12/140 */;
          }
        }
        .new-kc-yg-yq1:hover {
          color: #55a1ed;
        }
        .new-kc-yg-yq2 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: rgba(51, 51, 51, 0.6);
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 0.107143rem /* 15/140 */;
          .new-kc-yg-yq2-img {
            height: 1.321429rem /* 185/140 */;
            width: 95%;
            margin-bottom: 0.085714rem /* 12/140 */;
          }
        }
        .new-kc-yg-yq2:hover {
          color: #55a1ed;
        }
        .new-kc-yg-yq3 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: rgba(51, 51, 51, 0.6);
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 0.157143rem /* 22/140 */;
          .new-kc-yg-yq3-img {
            height: 1.271429rem /* 178/140 */;
            width: 95%;

            margin-bottom: 0.085714rem /* 12/140 */;
          }
        }
        .new-kc-yg-yq3:hover {
          color: #55a1ed;
        }
        .new-kc-yg-yq4 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: rgba(51, 51, 51, 0.6);
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 0.464286rem /* 65/140 */;
          .new-kc-yg-yq4-img {
            height: 1.092857rem /* 153/140 */;
            width: 95%;
            margin-bottom: 0.085714rem /* 12/140 */;
          }
        }
        .new-kc-yg-yq4:hover {
          color: #55a1ed;
        }
        .new-kc-yg-yq5 {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 0.1rem /* 14/140 */;
          color: rgba(51, 51, 51, 0.6);
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 0.571429rem /* 80/140 */;
          .new-kc-yg-yq5-img {
            height: 0.992857rem /* 139/140 */;
            width: 95%;
            margin-bottom: 0.085714rem /* 12/140 */;
          }
        }
        .new-kc-yg-yq5:hover {
          color: #55a1ed;
        }
      }
      .new-kc-bottom-but {
        width: 100%;
        height: 0.285714rem /* 40/140 */;
        display: flex;
        justify-content: center;
        align-items: center;
        .new-al-kc-but {
          height: 0.257143rem /* 36/140 */;
          cursor: pointer;
        }
      }
      .new-kc-bottom-but1 {
        width: 100%;
        height: 0.785714rem /* 110/140 */;
        display: flex;
        justify-content: center;
        align-items: center;
        .new-al-kc-but {
          height: 0.257143rem /* 36/140 */;
          cursor: pointer;
        }
      }
    }
  }
  .new-kc-ys {
    height: auto;
    width: 100%;
    background-color: #f5f5f5ff;
    display: flex;
    .new-kc-ys-left {
      flex: 5.3;
    }
    .new-kc-ys-right {
      flex: 5.5;
    }
    .new-kc-ys-middle {
      flex: 12.5;
      .new-kc-ys-but {
        height: 0.428571rem /* 60/140 */;
        width: 100%;
        display: flex;
        line-height: 0.428571rem /* 60/140 */;
        margin-top: 0.285714rem /* 40/140 */;
        margin-bottom: 0.071429rem /* 10/140 */;
        .new-kc-ys-but1 {
          flex: 1;
          background-color: #fff;
          color: #333333ff;
          text-align: center;
          font-size: 0.142857rem /* 20/140 */;
        }
        .new-kc-ys-but2 {
          flex: 1;
          background-color: #fff;
          color: #333333ff;
          text-align: center;
          font-size: 0.142857rem /* 20/140 */;
        }
      }
      .new-kc-ys-div {
        width: 100%;
        height: auto;
        .new-kc-ys-div-samll {
          height: auto;
          display: flex;
          .new-kc-ys-div-samll1 {
            width: 33%;
            // flex: 1;
            height: auto;
            transition: transform 0.3s ease; /* 添加过渡效果 */
          }
          .new-kc-ys-div-samll1:hover {
            transform: translateY(-0.071429rem /* 10/140 */); /* 向上浮动20像素 */
          }
        }
      }
      .new-kc-ys-bottom-but {
        height: 0.285714rem /* 40/140 */;
        display: flex;
        justify-content: center;
        margin-top: 0.107143rem /* 15/140 */;
        align-items: center;
        .new-kc-ys-bottom-but-small {
          height: 0.257143rem /* 36/140 */;
          cursor: pointer;
        }
      }
    }
  }

  .new-al-kc-qb {
    width: 100%;
  }

  .new-al-kc-question {
    height: 2.321429rem /* 325/140 */;
    width: 100%;
    padding-bottom: 0.25rem /* 35/140 */;
    .new-al-kc-question-title {
      width: 100%;
      height: 0.785714rem /* 110/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .new-al-kc-question-title-img {
        height: 0.221429rem /* 31/140 */;
      }
    }
    .new-al-kc-question-div {
      width: 100%;
      height: 1.214286rem /* 170/140 */;
      display: flex;
      .new-al-kc-question-div1 {
        flex: 1;
        height: 1.214286rem /* 170/140 */;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 0.142857rem /* 20/140 */;
        .new-al-kc-question-div1-small {
          display: flex;
          justify-content: space-between;
          .new-al-kc-question-div1-left {
            display: flex;
            line-height: 0.1rem /* 14/140 */;
            font-size: 0.1rem /* 14/140 */;
            .new-al-Q {
              width: 0.114286rem /* 16/140 */;
              height: 0.114286rem /* 16/140 */;
              margin-right: 0.085714rem /* 12/140 */;
            }
          }
          .new-al-kc-question-div1-right {
            height: 0.1rem /* 14/140 */;
          }
        }
        .new-al-kc-question-div1-small:hover {
          color: #60a5efff;
          cursor: pointer;
        }
      }
    }
  }
  .new-al-study-gh {
    width: 100%;
    background-color: #f5f5f5ff;
    padding-bottom: 0.142857rem /* 20/140 */;
    .new-al-study-gh-title {
      width: 100%;
      height: 0.635714rem /* 89/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: HelloFont, HelloFont;
      font-weight: bold;
      font-size: 0.228571rem /* 32/140 */;
      color: #333333;
    }
    .new-al-study-gh-but {
      height: 0.485714rem /* 68/140 */;
      width: 100%;
      display: flex;
      line-height: 0.485714rem /* 68/140 */;
      margin-bottom: 0.071429rem /* 10/140 */;
      .new-al-study-gh-but1 {
        flex: 1;
        height: 0.485714rem /* 68/140 */;
        text-align: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        font-size: 0.128571rem /* 18/140 */;
        color: #333333;
        background-color: #ffe4c4ff;
      }
    }
    .new-al-study-gh-img-div {
      position: relative;
      height: 1.928571rem /* 270/140 */;
      height: auto;
      width: 100%;
      .new-al-study-gh-img {
        width: 100%;
        max-width: 100%;
      }
      .new-al-study-gh-img-but {
        position: absolute;
        height: 0.264286rem /* 37/140 */;
        width: 1.357143rem /* 190/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        top: 75%;
        left: 39%;
        cursor: pointer;
      }
    }
  }
  .new-al-sh {
    height: 3.05714rem /* 530/140 */;
    width: 100%;
    padding-top: 0.285714rem /* 40/140 */;
    padding-bottom: 0.285714rem /* 40/140 */;
    .new-al-sh-img {
      width: 100%;
      margin-bottom: 0.142857rem /* 20/140 */;
    }
    .new-al-sh-but {
      width: 100%;
      height: 0.285714rem /* 40/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .new-al-sh-but-samll {
        height: 0.257143rem /* 36/140 */;
        cursor: pointer;
      }
    }
  }
  .new-al-kc-mb {
    width: 100%;
    height: auto;
  }
  .new-al-need-gh {
    position: relative;
    width: 100%;
    height: 3.457143rem /* 484/140 */;
    .new-al-need-gh-img {
      width: 100%;
    }
    .new-al-need-gh-but {
      position: absolute;
      height: 0.257143rem /* 36/140 */;
      top: 87%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .new-al-kc-js {
    width: 100%;
    height: auto;
    background-color: #ecf5ffff;
    padding-top: 0.285714rem /* 40/140 */;
    padding-bottom: 0.214286rem /* 30/140 */;
    .new-al-kc-js-title {
      width: 100%;
      height: 0.214286rem /* 30/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.135714rem /* 19/140 */;
      .new-al-kc-js-title-img {
        height: 0.214286rem /* 30/140 */;
      }
    }
    .new-al-fl {
      width: 100%;
      height: 0.735714rem /* 103/140 */;
      margin-bottom: 0.1rem /* 14/140 */;
    }
    .new-al-kc-js-but {
      height: 0.342857rem /* 48/140 */;
      width: 5.428571rem /* 760/140 */;
      background-color: #fcbe77ff;
      font-weight: bold;
      font-size: 0.128571rem /* 18/140 */;
      color: #333333;
      line-height: 0.392857rem /* 55/140 */;
      border-radius: 0.142857rem /* 20/140 */ 0.142857rem /* 20/140 */ 0 0;
      display: flex;
      // padding-left: 0.557143rem /* 78/140 */;
      // padding-right: 0.557143rem /* 78/140 */;
      justify-content: space-between;
      .new-al-kc-js-but1 {
        text-align: center;
        width: 27%;
        height: 0.342857rem /* 48/140 */;
        cursor: pointer;
        line-height: 0.385714rem; /* 40/140 */
      }
      .new-al-kc-js-but11 {
        text-align: center;
        width: 27%;
        height: 0.342857rem /* 48/140 */;
        cursor: pointer;
        margin-left: 0.214286rem; /* 30/140 */
        line-height: 0.385714rem; /* 40/140 */
      }
      .new-al-kc-js-but11-click {
        text-align: center;
        width: 27%;
        height: 0.271429rem /* 38/140 */;
        background-color: #fff;
        margin-top: 0.071429rem /* 10/140 */;
        border-radius: 0.071429rem /* 10/140 */ 0.071429rem /* 10/140 */ 0 0;
        line-height: 0.271429rem /* 38/140 */;
        margin-left: 0.214286rem; /* 30/140 */
      }
      .new-al-kc-js-but12 {
        text-align: center;
        width: 27%;
        height: 0.342857rem /* 48/140 */;
        cursor: pointer;
        margin-right: 0.214286rem; /* 30/140 */
        line-height: 0.385714rem; /* 40/140 */
      }
      .new-al-kc-js-but12-click {
        text-align: center;
        width: 27%;
        height: 0.271429rem /* 38/140 */;
        background-color: #fff;
        margin-top: 0.071429rem /* 10/140 */;
        border-radius: 0.071429rem /* 10/140 */ 0.071429rem /* 10/140 */ 0 0;
        line-height: 0.271429rem /* 38/140 */;
        margin-right: 0.214286rem; /* 30/140 */
      }

      .new-al-kc-js-but1-click {
        text-align: center;
        width: 27%;
        height: 0.271429rem /* 38/140 */;
        background-color: #fff;
        margin-top: 0.071429rem /* 10/140 */;
        border-radius: 0.071429rem /* 10/140 */ 0.071429rem /* 10/140 */ 0 0;
        line-height: 0.21429rem /* 38/140 */;
      }
    }
    .new-al-kc-js-img-div {
      position: relative;
      width: 5.428571rem /* 760/140 */;
      // height: auto;

      .new-al-kc-js-img {
        width: 5.428571rem /* 760/140 */;
        height: 1.378571rem /* 193/140 */;
        margin-bottom: 0.142857rem /* 20/140 */;
      }
      .new-al-kc-js-img-but {
        position: absolute;
        width: 0.928571rem /* 130/140 */;
        height: 0.242857rem /* 34/140 */;
        border-radius: 0.142857rem /* 20/140 */;
        top: 28.5%;
        left: 42%;
        cursor: pointer;
      }
      .new-al-kc-js-bottom {
        width: 100%;
        height: auto;
      }
    }
  }
  .new-al-teacher {
    position: relative;
    height: 5.607143rem /* 785/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-al-teacher-img {
    height: 5.107143rem /* 715/140 */;
  }
  .new-al-teacher-img-but1 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 33%;
    cursor: pointer;
  }
  .new-al-teacher-img-but2 {
    position: absolute;
    width: 1.321429rem /* 185/140 */;
    height: 0.271429rem /* 38/140 */;
    border-radius: 0.142857rem /* 20/140 */;
    top: 91%;
    left: 53%;
    cursor: pointer;
  }
  .new-al-yy {
    height: 1.785714rem /* 250/140 */;
    width: 100%;
    background-image: url(../../pc-imgs/new-al-yy.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .new-al-yy-inp1 {
      position: absolute;
      height: 0.457143rem /* 64/140 */;
      border-radius: 0.214286rem /* 30/140 */;
      width: 1.771429rem /* 248/140 */;
      border: 0.007143rem /* 1/140 */ solid #ccc;
      padding-left: 0.142857rem /* 20/140 */;
      top: 50%;
      left: 21%;
      font-size: 0.142857rem /* 20/140 */;
    }
    .new-al-yy-inp1:focus {
      border-color: #fff; /* 可以根据需要更改颜色 */
      outline: none; /* 阻止默认的聚焦边框样式 */
    }
    .new-al-yy-inp2 {
      position: absolute;
      height: 0.457143rem /* 64/140 */;
      border-radius: 0.214286rem /* 30/140 */;
      width: 1.771429rem /* 248/140 */;
      border: 0.007143rem /* 1/140 */ solid #ccc;
      padding-left: 0.142857rem /* 20/140 */;
      top: 50%;
      left: 42%;
      font-size: 0.142857rem /* 20/140 */;
    }
    .new-al-yy-inp2:focus {
      border-color: #fff; /* 可以根据需要更改颜色 */
      outline: none; /* 阻止默认的聚焦边框样式 */
    }
    .new-lj-but {
      position: absolute;
      top: 50%;
      left: 63%;
      height: 0.457143rem /* 64/140 */;
      cursor: pointer;
    }
  }
  .new-al-landa-bh-ys {
    width: 100%;
    height: auto;
  }
  .new-al-gz-study {
    width: 100%;
    height: auto;
  }
  .new-al-xz-fw {
    height: 4.471429rem /* 626/140 */;
    // height: auto;
    width: 100%;
    .new-al-xz-fw-title {
      height: 0.642857rem /* 90/140 */;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .new-al-xz-fw-title-img {
      height: 0.221429rem /* 31/140 */;
    }
    .new-al-xz-fw-div {
      height: 3.571429rem /* 500/140 */;
      width: 100%;
      .new-al-xz-fw-div1 {
        display: flex;
        width: 100%;
        height: 1.75rem /* 245/140 */;
        margin-bottom: 0.057143rem /* 8/140 */;
        .new-al-xz-fw-div1-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img1.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .new-al-xz-fw-div1-small:hover {
          color: #57a0eeff;
        }
        .new-al-xz-fw-div2-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img2.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .new-al-xz-fw-div2-small:hover {
          color: #57a0eeff;
        }
        .new-al-xz-fw-div3-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img3.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 23%;
          }
        }
        .new-al-xz-fw-div3-small:hover {
          color: #57a0eeff;
        }
        .new-al-xz-fw-div4-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img4.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 11%;
          }
        }
        .new-al-xz-fw-div4-small:hover {
          color: #57a0eeff;
        }
        .new-al-xz-fw-div5-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img5.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 27%;
          }
        }
        .new-al-xz-fw-div5-small:hover {
          color: #57a0eeff;
        }
        .new-al-xz-fw-div6-small {
          position: relative;
          flex: 1;
          height: 1.75rem /* 245/140 */;
          background-image: url(../../pc-imgs/new-al-xz-fw-img6.png);
          background-repeat: no-repeat;
          background-size: cover;
          .new-al-xz-fw-div1-small-text {
            position: absolute;
            top: 84%;
            left: 20%;
          }
        }
        .new-al-xz-fw-div6-small:hover {
          color: #57a0eeff;
        }
      }
    }
  }
  .new-fw-xy {
    height: auto;
    width: 100%;
  }
}
</style>
