<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <!-- 背景图 -->
    <div class="ap-bg">
      <img class="ap-bg" src="../../assets/ap-bg.png" alt="" @click="consult" />
    </div>
    <!-- AP课程知多少 -->
    <div class="ap-kc-ds">
      <img class="ap-kc-ds-img" src="../../assets/ap-kc-ds.png" alt="" />
      <div class="ap-kc-ds-img-but1" @click="consult"></div>
      <div class="ap-kc-ds-img-but2" @click="consult"></div>
      <div class="ap-kc-ds-img-but3" @click="consult"></div>
      <div class="ap-kc-ds-img-but4" @click="consult"></div>
      <div class="ap-kc-ds-img-but5" @click="consult"></div>
      <div class="ap-kc-ds-img-but6" @click="consult"></div>
    </div>
    <!-- AP科目介绍 -->
    <img class="app-ap-km-js" src="../../assets/ap-km-js.png" alt="" />
    <!-- AP考试选课推荐 -->
    <div class="ap-ks-xk">
      <img class="ap-ks-xk-title" src="../../assets/ap-ks-xk-title.png" alt="" />
      <van-swipe class="ap-ks-xk-swp" autoplay="3000">
        <van-swipe-item>
          <img class="ap-ks-xk-swp-swp-item" src="../../assets/ap-ks-xk1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item> <img class="ap-ks-xk-swp-swp-item" src="../../assets/ap-ks-xk2.png" alt="" /> </van-swipe-item>
        <van-swipe-item> <img class="ap-ks-xk-swp-swp-item3" src="../../assets/ap-ks-xk3.png" alt="" /></van-swipe-item>
      </van-swipe>
      <img class="ap-ks-xk-zs" src="../../assets/ap-ks-xk-zs.png" alt="" />
    </div>
    <!-- 半海AP课程设置 -->
    <div class="ap-kc-sz">
      <img class="ap-kc-sz-img" src="../../assets/ap-kc-sz.png" alt="" />
      <img class="ap-phone-but" src="../../assets/ap-phone-but.png" alt="" @click="callMobile" />
      <img class="ap-zx-button" src="../../assets/ap-zx-button.png" alt="" @click="consult" />
    </div>
    <!-- 教学团队 -->
    <div class="ap-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 我们的优势 -->
    <div class="ap-ys">
      <img class="ap-ys-img" src="../../assets/ig-ys.png" alt="" />
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>

    <Popup :show="show" @showFun="showFun"></Popup>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      show: false,
      numberToCopy: 19821616277
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.ap-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.ap-kc-ds {
  padding-top: 0.675676rem /* 25/37 */;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(237, 239, 243);
  padding-bottom: 0.810811rem /* 30/37 */;
  .ap-kc-ds-img {
    width: 9.324324rem /* 345/37 */;
    height: 17.189189rem /* 636/37 */;
  }
  .ap-kc-ds-img-but1 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 32%;
    left: 7%;
  }
  .ap-kc-ds-img-but2 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 32%;
    left: 55%;
  }
  .ap-kc-ds-img-but3 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 61%;
    left: 7%;
  }
  .ap-kc-ds-img-but4 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 61%;
    left: 55%;
  }
  .ap-kc-ds-img-but5 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90%;
    left: 7%;
  }
  .ap-kc-ds-img-but6 {
    position: absolute;
    width: 3.837838rem /* 142/37 */;
    height: 0.702703rem /* 26/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    top: 90%;
    left: 55%;
  }
}
.app-ap-km-js {
  height: 20.459459rem /* 757/37 */;
}
.ap-ks-xk {
  padding-top: 0.540541rem /* 20/37 */;
  width: 10.135135rem /* 375/37 */;
  height: 12.891892rem /* 477/37 */;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(237, 239, 243);
  .ap-ks-xk-title {
    width: 5.972973rem /* 221/37 */;
    height: 1.216216rem /* 45/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
  }
  .ap-ks-xk-swp {
    width: 9.324324rem /* 345/37 */;
    height: 8.378378rem /* 310/37 */;
    .van-swipe__indicator {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: #ffffffff !important;
    }
    .van-swipe__indicator--active {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: #ff8e4cff !important;
    }
  }
  .ap-ks-xk-swp-swp-item {
    width: 9.324324rem /* 345/37 */;
    height: 7.513514rem /* 278/37 */;
  }
  .ap-ks-xk-swp-swp-item3 {
    width: 4.459459rem /* 165/37 */;
    height: 7.513514rem /* 278/37 */;
  }
  .ap-ks-xk-zs {
    width: 8.054054rem /* 298/37 */;
    margin-top: 0.675676rem /* 25/37 */;
  }
}
.ap-kc-sz {
  position: relative;
  height: 22.432432rem /* 830/37 */;
  display: flex;
  justify-content: center;
  padding-top: 0.540541rem /* 20/37 */;
  .ap-kc-sz-img {
    width: 9.324324rem /* 345/37 */;
    height: 19.594595rem /* 725/37 */;
  }
  .ap-phone-but {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 91%;
    left: 6%;
  }
  .ap-zx-button {
    position: absolute;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    top: 91%;
    left: 55%;
  }
}
.ap-teacher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.27027rem /* 10/37 */;
  .ig-teacher-logo {
    width: 5.675676rem /* 210/37 */;
    height: 1.27027rem /* 47/37 */;
    margin-bottom: 0.459459rem /* 17/37 */;
  }
  .ig-teacher-swp {
    height: 10.27027rem /* 380/37 */;
    width: 10.135135rem /* 375/37 */;
    .van-swipe__indicator {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(213, 214, 215) !important;
    }
    .van-swipe__indicator--active {
      height: 0.27027rem /* 10/37 */ !important;
      width: 0.27027rem /* 10/37 */ !important;
      background-color: rgb(96, 158, 273) !important;
    }
  }
  .teacher-swp-item-ig {
    width: 9.432432rem /* 349/37 */;
    height: 9.189189rem /* 340/37 */;
    margin-left: 0.378378rem /* 14/37 */;
  }
}
.ap-ys {
  height: 16.216216rem /* 600/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  align-items: center;
  justify-content: center;
  .ap-ys-img {
    width: 9.324324rem /* 345/37 */;
    height: 15.972973rem /* 591/37 */;
  }
}
</style>
