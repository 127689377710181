<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="ib-bg">
      <img class="ib-bg" src="../../assets/ib-bg.png" alt="" @click="consult" />
    </div>
    <!-- IB课程体系介绍 -->
    <div class="ib-kc-js">
      <img class="ib-kc-js-img" src="../../assets/ib-kcjs.png" alt="" />
    </div>
    <!-- 英美大学的IB分数录取要求 -->
    <div class="ib-ym-lq">
      <img class="ym-lq-title" src="../../assets/ym-lq-title.png" alt="" />
      <div class="ib-ym-lq-but">
        <div @click="lqFun1" class="ib-ym-lq-but1" :style="{backgroundColor: lq1 ? '#dbf240FF' : '#90cf89FF'}">
          英国大学的IB成绩要求
        </div>
        <div @click="lqFun2" class="ib-ym-lq-but1" :style="{backgroundColor: lq2 ? '#dbf240FF' : '#90cf89FF'}">
          美国大学的IB成绩要求
        </div>
      </div>
      <img v-show="lq1" class="ib-lq-img" src="../../assets/ib-lq-img1.png" alt="" />
      <img v-show="lq2" class="ib-lq-img" src="../../assets/ib-lq-img2.png" alt="" />
      <img class="ib-lq-text" src="../../assets/ib-lq-text.png" alt="" />
      <img class="ib-but1" src="../../assets/ib-but1.png" alt="" @click="consult" />
    </div>
    <!-- IB课程科目一览 -->
    <div class="ib-kc-yl">
      <img class="ib-kc-yl-img" src="../../assets/ib-kc-yl-img.png" alt="" />
    </div>
    <!-- IB核心课程考评详解 -->
    <div class="ib-hx-kc">
      <img class="ib-kc-yl-title" src="../../assets/ib-kc-yl-title.png" alt="" />
      <div class="ib-hx-kc-but">
        <div
          @click="hxFun1"
          class="ib-hx-kc-but1"
          :style="{backgroundColor: hx1 ? '#f78505FF' : '', color: hx1 ? '#fff' : '#f78505FF'}"
        >
          TOK知识理论
        </div>
        <div
          @click="hxFun2"
          class="ib-hx-kc-but1"
          :style="{backgroundColor: hx2 ? '#f78505FF' : '', color: hx2 ? '#fff' : '#f78505FF'}"
        >
          EE拓展论文
        </div>
        <div
          @click="hxFun3"
          class="ib-hx-kc-but1"
          :style="{backgroundColor: hx3 ? '#f78505FF' : '', color: hx3 ? '#fff' : '#f78505FF'}"
        >
          CAS创新行动服务
        </div>
      </div>
      <img v-show="hx1" class="hx-img" src="../../assets/hx-img1.png" alt="" />
      <img v-show="hx2" class="hx-img" src="../../assets/hx-img2.png" alt="" />
      <img v-show="hx3" class="hx-img" src="../../assets/hx-img3.png" alt="" />
      <div class="ib-hx-but" @click="consult"></div>
    </div>
    <!-- 半海导师IB选课建议 -->
    <div class="ib-xuke-jy-div">
      <img class="ib-xuke-jy" src="../../assets/ib-xuke-jy.png" alt="" />
      <div class="ib-xuke-jy-but" @click="consult"></div>
    </div>

    <!-- 热门留学专业对IB课程的偏好 -->
    <div class="ib-rm-zy">
      <img class="ib-rm-zy-title" src="../../assets/ib-rm-zy-title.png" alt="" />
      <div class="ib-rm-zy-fiv">
        <div class="ib-rm-left">
          <div
            @click="rmFun1"
            class="ib-rm-left1"
            :style="{backgroundColor: rm1 ? '#46af43FF' : '#f4fcf3FF', color: rm1 ? '#f4fcf3FF' : '#333333FF'}"
          >
            工程专业
          </div>
          <div
            @click="rmFun2"
            class="ib-rm-left1"
            :style="{backgroundColor: rm2 ? '#46af43FF' : '#f4fcf3FF', color: rm2 ? '#f4fcf3FF' : '#333333FF'}"
          >
            经济学专业
          </div>
          <div
            @click="rmFun3"
            class="ib-rm-left1"
            :style="{backgroundColor: rm3 ? '#46af43FF' : '#f4fcf3FF', color: rm3 ? '#f4fcf3FF' : '#333333FF'}"
          >
            化学专业
          </div>
          <div
            @click="rmFun4"
            class="ib-rm-left1"
            :style="{backgroundColor: rm4 ? '#46af43FF' : '#f4fcf3FF', color: rm4 ? '#f4fcf3FF' : '#333333FF'}"
          >
            物理专业
          </div>
          <div
            @click="rmFun5"
            class="ib-rm-left1"
            :style="{backgroundColor: rm5 ? '#46af43FF' : '#f4fcf3FF', color: rm5 ? '#f4fcf3FF' : '#333333FF'}"
          >
            生物专业
          </div>
          <div
            @click="rmFun6"
            class="ib-rm-left1"
            :style="{backgroundColor: rm6 ? '#46af43FF' : '#f4fcf3FF', color: rm6 ? '#f4fcf3FF' : '#333333FF'}"
          >
            医学类专业
          </div>
          <div
            @click="rmFun7"
            class="ib-rm-left1"
            :style="{backgroundColor: rm7 ? '#46af43FF' : '#f4fcf3FF', color: rm7 ? '#f4fcf3FF' : '#333333FF'}"
          >
            数学专业
          </div>
          <div
            @click="rmFun8"
            class="ib-rm-left1"
            :style="{backgroundColor: rm8 ? '#46af43FF' : '#f4fcf3FF', color: rm8 ? '#f4fcf3FF' : '#333333FF'}"
          >
            心理学专业
          </div>
          <div
            @click="rmFun9"
            class="ib-rm-left1"
            :style="{backgroundColor: rm9 ? '#46af43FF' : '#f4fcf3FF', color: rm9 ? '#f4fcf3FF' : '#333333FF'}"
          >
            计算机专业
          </div>
        </div>
        <img v-show="rm1" class="ib-rm-right" src="../../assets/ib-rm-img1.png" alt="" />
        <img v-show="rm2" class="ib-rm-right" src="../../assets/ib-rm-img2.png" alt="" />
        <img v-show="rm3" class="ib-rm-right" src="../../assets/ib-rm-img3.png" alt="" />
        <img v-show="rm4" class="ib-rm-right" src="../../assets/ib-rm-img4.png" alt="" />
        <img v-show="rm5" class="ib-rm-right" src="../../assets/ib-rm-img5.png" alt="" />
        <img v-show="rm6" class="ib-rm-right" src="../../assets/ib-rm-img6.png" alt="" />
        <img v-show="rm7" class="ib-rm-right" src="../../assets/ib-rm-img7.png" alt="" />
        <img v-show="rm8" class="ib-rm-right" src="../../assets/ib-rm-img8.png" alt="" />
        <img v-show="rm9" class="ib-rm-right" src="../../assets/ib-rm-img9.png" alt="" />
      </div>
    </div>
    <!-- 半海IB课程设置 -->
    <div class="ib-kc-design">
      <img class="ib-kc-design-img" src="../../assets/ib-kc-design.png" alt="" />
      <div class="ib-kc-design-but" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/ib-teacher-title.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 国内IB学校/国际部近年考试情况 -->
    <div class="ib-gn-gj-ks">
      <div class="ib-gn-gj-ks-title-all">
        <div class="ib-gn-gj-ks-hx1"></div>
        <div class="ib-gn-gj-ks-title">国内IB学校/国际部近年考试情况</div>
        <div class="ib-gn-gj-ks-hx2"></div>
      </div>
      <div class="ib-gn-gj-ks-but">
        <div class="ib-gn-gj-ks-but-top">
          <div
            @click="gjgnFun1"
            class="ib-gn-gj-ks-but-top1"
            :style="{backgroundColor: gngj1 ? '#46af43FF' : '', color: gngj1 ? '#fff' : '#46af43FF'}"
          >
            上海院校
          </div>
          <div
            @click="gjgnFun2"
            class="ib-gn-gj-ks-but-top1"
            :style="{backgroundColor: gngj2 ? '#46af43FF' : '', color: gngj2 ? '#fff' : '#46af43FF'}"
          >
            北京院校
          </div>
          <div
            @click="gjgnFun3"
            class="ib-gn-gj-ks-but-top1"
            :style="{backgroundColor: gngj3 ? '#46af43FF' : '', color: gngj3 ? '#fff' : '#46af43FF'}"
          >
            深圳院校
          </div>
        </div>
        <div class="ib-gn-gj-ks-but-bottom">
          <div
            @click="gjgnFun4"
            class="ib-gn-gj-ks-but-top1"
            :style="{backgroundColor: gngj4 ? '#46af43FF' : '', color: gngj4 ? '#fff' : '#46af43FF'}"
          >
            其它院校
          </div>
        </div>
      </div>
      <img v-show="gngj1" class="ib-gn-gj-ks-img" src="../../assets/ib-gn-gj-ks-img1.png" alt="" />
      <img v-show="gngj2" class="ib-gn-gj-ks-img" src="../../assets/ib-gn-gj-ks-img2.png" alt="" />
      <img v-show="gngj3" class="ib-gn-gj-ks-img" src="../../assets/ib-gn-gj-ks-img5.png" alt="" />
      <img v-show="gngj4" class="ib-gn-gj-ks-img" src="../../assets/ib-gn-gj-ks-img4.png" alt="" />
      <img class="ib-gn-gj-text" src="../../assets/ib-gn-gj-text.png" alt="" />
      <img class="ib-gn-gj-but" src="../../assets/ib-gn-gj-but.png" alt="" @click="consult" />
      <!-- 半海教育优势 -->
      <img class="ib-ys" src="../../assets/ib-ys.png" alt="" />
      <!-- 跟踪学习 -->
      <img class="ib-gz" src="../../assets/ib-gz.png" alt="" />
      <!-- 贴心服务 -->
      <img class="ib-tx" src="../../assets/ib-tx.png" alt="" />
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      showRouter: false,
      show: false,
      lq1: true,
      lq2: false,
      hx1: true,
      hx2: false,
      hx3: false,
      rm1: true,
      rm2: false,
      rm3: false,
      rm4: false,
      rm5: false,
      rm6: false,
      rm7: false,
      rm8: false,
      rm9: false,
      numberToCopy: 19821616277,
      gngj1: true,
      gngj2: false,
      gngj3: false,
      gngj4: false
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    lqFun1() {
      this.lq1 = true;
      this.lq2 = false;
    },
    lqFun2() {
      this.lq1 = false;
      this.lq2 = true;
    },
    hxFun1() {
      this.hx1 = true;
      this.hx2 = false;
      this.hx3 = false;
    },
    hxFun2() {
      this.hx1 = false;
      this.hx2 = true;
      this.hx3 = false;
    },
    hxFun3() {
      this.hx1 = false;
      this.hx2 = false;
      this.hx3 = true;
    },
    rmFun1() {
      this.rm1 = true;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun2() {
      this.rm1 = false;
      this.rm2 = true;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun3() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = true;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun4() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = true;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun5() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = true;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun6() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = true;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun7() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = true;
      this.rm8 = false;
      this.rm9 = false;
    },
    rmFun8() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = true;
      this.rm9 = false;
    },
    rmFun9() {
      this.rm1 = false;
      this.rm2 = false;
      this.rm3 = false;
      this.rm4 = false;
      this.rm5 = false;
      this.rm6 = false;
      this.rm7 = false;
      this.rm8 = false;
      this.rm9 = true;
    },
    gjgnFun1() {
      this.gngj1 = true;
      this.gngj2 = false;
      this.gngj3 = false;
      this.gngj4 = false;
    },
    gjgnFun2() {
      this.gngj1 = false;
      this.gngj2 = true;
      this.gngj3 = false;
      this.gngj4 = false;
    },
    gjgnFun3() {
      this.gngj1 = false;
      this.gngj2 = false;
      this.gngj3 = true;
      this.gngj4 = false;
    },
    gjgnFun4() {
      this.gngj1 = false;
      this.gngj2 = false;
      this.gngj3 = false;
      this.gngj4 = true;
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style>
.ib-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.ib-kc-js {
  display: flex;
  width: 10.135135rem /* 375/37 */;
  padding-top: 0.810811rem /* 30/37 */;
  flex-direction: column;
  background-color: rgb(237, 239, 243);
  align-items: center;
  padding-bottom: 0.810811rem /* 30/37 */;
}
.ib-kc-js-img {
  width: 9.432432rem /* 349/37 */;
  height: 15.081081rem /* 558/37 */;
}
.ib-ym-lq {
  background-color: #46af43ff;
  display: flex;
  flex-direction: column;
  padding-top: 0.540541rem /* 20/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  align-items: center;
}
.ym-lq-title {
  width: 8.297297rem /* 307/37 */;
  height: 0.621622rem /* 23/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
  margin-top: 0.540541rem /* 20/37 */;
}
.ib-ym-lq-but {
  display: flex;
  width: 9.054054rem /* 335/37 */;
  height: 0.810811rem /* 30/37 */;
  justify-content: space-between;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ib-ym-lq-but1 {
  width: 4.324324rem /* 160/37 */;
  height: 0.810811rem /* 30/37 */;
  background-color: #90cf89ff;
  color: #363836ff;
  font-size: 0.378378rem /* 14/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  font-weight: 900;
}
.ib-lq-img {
  width: 9.324324rem /* 345/37 */;
  margin-bottom: 0.486486rem /* 18/37 */;
}
.ib-lq-text {
  width: 9.297297rem /* 344/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ib-but1 {
  width: 5.405405rem /* 200/37 */;
  height: 0.972973rem /* 36/37 */;
}
.ib-kc-yl {
  display: flex;
  background-color: rgb(237, 239, 243);
  flex-direction: column;
  align-items: center;
  padding-top: 0.810811rem /* 30/37 */;
  padding-bottom: 0.810811rem /* 30/37 */;
}
.ib-kc-yl-img {
  width: 9.324324rem /* 345/37 */;
  height: 19rem /* 703/37 */;
}
.ib-kc-yl-title {
  width: 6.837838rem /* 253/37 */;
  height: 0.621622rem /* 23/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ib-hx-kc {
  position: relative;
  display: flex;
  background-color: rgb(237, 239, 243);
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.810811rem /* 30/37 */;
}
.ib-hx-kc-but {
  display: flex;
  width: 9.297297rem /* 344/37 */;
  margin-bottom: 0.405405rem /* 15/37 */;
  justify-content: space-between;
}
.ib-hx-kc-but1 {
  width: 2.864865rem /* 106/37 */;
  height: 0.702703rem /* 26/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  color: #f88504ff;
  border: 0.027027rem /* 1/37 */ solid #f88504ff;
  font-size: 0.324324rem /* 12/37 */;
  text-align: center;
  line-height: 0.702703rem /* 26/37 */;
}
.hx-img {
  width: 9.324324rem /* 345/37 */;
}
.ib-hx-but {
  position: absolute;
  top: 91.5%;
  left: 50%;
  transform: translateX(-50%);
  height: 1.081081rem /* 40/37 */;
  width: 5.675676rem /* 210/37 */;
  border-radius: 0.540541rem /* 20/37 */;
}
.ib-xuke-jy-div {
  position: relative;
  height: 13.135135rem /* 486/37 */;
  width: 10.135135rem /* 375/37 */;
}
.ib-xuke-jy {
  height: 13.135135rem /* 486/37 */;
  width: 10.135135rem /* 375/37 */;
}
.ib-xuke-jy-but {
  position: absolute;
  width: 5.405405rem /* 200/37 */;
  height: 0.972973rem /* 36/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  left: 50%;
  transform: translateX(-50%);
  top: 88.5%;
}
.ib-rm-zy {
  width: 10.135135rem /* 375/37 */;
  padding-top: 0.810811rem /* 30/37 */;
  padding-bottom: 0.810811rem /* 30/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ib-rm-zy-title {
  width: 9.324324rem /* 345/37 */;
  height: 1.648649rem /* 61/37 */;
  margin-bottom: 0.540541rem /* 20/37 */;
}
.ib-rm-zy-fiv {
  width: 9.324324rem /* 345/37 */;
  height: 7.297297rem /* 270/37 */;
  display: flex;
}
.ib-rm-left {
  display: flex;
  flex-direction: column;
  background-color: #f4fcf3ff;
  width: 3.243243rem /* 120/37 */;
}
.ib-rm-right {
  width: 6.081081rem /* 225/37 */;
}
.ib-rm-left1 {
  height: 0.810811rem /* 30/37 */;
  text-align: center;
  line-height: 0.810811rem /* 30/37 */;
  font-size: 0.351351rem /* 13/37 */;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
}
.ib-kc-design {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 15.405405rem /* 570/37 */;
  background-color: rgb(237, 239, 243);
}
.ib-kc-design-img {
  margin-left: 0.405405rem /* 15/37 */;
  position: absolute;
  width: 9.324324rem /* 345/37 */;
  height: 14.810811rem /* 548/37 */;
  top: 0;
  left: 0;
}
.ib-kc-design-but {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 89.5%;
  height: 1.081081rem /* 40/37 */;
  width: 5.405405rem /* 200/37 */;
  border-radius: 0.540541rem /* 20/37 */;
}
.ib-gn-gj-ks {
  width: 10.135135rem /* 375/37 */;
  background-color: #f4fcf3ff;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ib-gn-gj-ks-title-all {
  display: flex;
  width: 9.324324rem /* 345/37 */;
  height: 1.702703rem /* 63/37 */;
  justify-content: center;
  align-items: center;
}
.ib-gn-gj-ks-title {
  width: 7.243243rem /* 268/37 */;
  height: 1.702703rem /* 63/37 */;
  font-size: 0.486486rem /* 18/37 */;
  font-family: HelloFont, HelloFont;
  font-weight: normal;
  color: #333333;
  line-height: 1.702703rem /* 63/37 */;
  text-align: center;
  font-weight: 900;
  display: flex;
}
.ib-gn-gj-ks-hx1 {
  width: 0.891892rem /* 33/37 */;
  height: 0.054054rem /* 2/37 */;
  background: #f78505;
  margin-right: 0.054054rem /* 2/37 */;
}
.ib-gn-gj-ks-hx2 {
  width: 0.891892rem /* 33/37 */;
  height: 0.054054rem /* 2/37 */;
  background: #f78505;
}
.ib-gn-gj-ks-but {
  width: 9.297297rem /* 344/37 */;
  height: 1.675676rem /* 62/37 */;
  display: flex;
  flex-direction: column;
  margin-top: 0.540541rem /* 20/37 */;
}
.ib-gn-gj-ks-but-top {
  display: flex;
  justify-content: space-between;
}
.ib-gn-gj-ks-but-top1 {
  width: 2.756757rem /* 102/37 */;
  height: 0.702703rem /* 26/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  color: #54b551ff;
  font-size: 0.378378rem /* 14/37 */;
  border: 0.027027rem /* 1/37 */ solid #54b551ff;
  text-align: center;
  line-height: 0.702703rem /* 26/37 */;
  margin-bottom: 0.351351rem /* 13/37 */;
}
.ib-gn-gj-ks-but-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ib-gn-gj-ks-img {
  width: 9.378378rem /* 347/37 */;
  margin-bottom: 0.432432rem /* 16/37 */;
  margin-top: 0.432432rem /* 16/37 */;
}
.ib-gn-gj-text {
  width: 9.054054rem /* 335/37 */;
  height: 0.837838rem /* 31/37 */;
  margin-bottom: 0.486486rem /* 18/37 */;
}
.ib-gn-gj-but {
  height: 0.972973rem /* 36/37 */;
  width: 5.405405rem /* 200/37 */;
  margin-bottom: 0.675676rem /* 25/37 */;
}
.ib-ys {
  width: 8.675676rem /* 321/37 */;
  height: 13.72973rem /* 508/37 */;
  margin-bottom: 0.675676rem /* 25/37 */;
}
.ib-gz {
  width: 10.135135rem /* 375/37 */;
  height: 21.351351rem /* 790/37 */;
}
.ib-tx {
  width: 10.135135rem /* 375/37 */;
  height: 18.027027rem /* 667/37 */;
}
</style>
