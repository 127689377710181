<template>
  <div>
    <!-- 头部logo -->
    <Header></Header>
    <!-- 导航栏 -->
    <Narbar></Narbar>
    <!-- 背景页面 -->
    <div class="tf-bj">
      <img v-lazy="imageUrl" class="tf-bj" src="../../pc-imgs/tf-bj.png" alt="" @click="consult" />
    </div>
    <!-- 托福为什么选择半海 -->
    <div class="tf-why-select-bh">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="tf-why-select-bh-title">
            <img class="tf-why-select-bh-title-img" src="../../pc-imgs/tf-why-select-bh-title.png" alt="" />
          </div>
          <div class="tf-why-select-bh-context">
            <img
              class="tf-why-select-bh-context-small"
              src="../../pc-imgs/tf-why-select-bh-context-small1.png"
              alt=""
            />
            <img
              class="tf-why-select-bh-context-small"
              src="../../pc-imgs/tf-why-select-bh-context-small2.png"
              alt=""
            />
            <img
              class="tf-why-select-bh-context-small"
              src="../../pc-imgs/tf-why-select-bh-context-small3.png"
              alt=""
            />
            <img
              class="tf-why-select-bh-context-small"
              src="../../pc-imgs/tf-why-select-bh-context-small4.png"
              alt=""
            />
          </div>
          <div class="tf-why-select-bh-but">
            <img class="tf-why-select-bh-but1" src="../../pc-imgs/test-mobile.png" alt="" @click="consult" />
            <img class="tf-why-select-bh-but2" src="../../pc-imgs/test-zx.png" alt="" @click="consult" />
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 学习托福遇到的问题 -->
    <div class="tf-study-question">
      <img class="tf-study-question" src="../../pc-imgs/tf-study-question.png" alt="" @click="consult" />
      <div class="tf-study-question-but1" @click="consult"></div>
      <div class="tf-study-question-but2" @click="consult"></div>
    </div>
    <!-- 阶梯型模式学习托福 -->
    <div class="tf-study-jt">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="tf-study-jt-title">
            <img class="tf-study-jt-title-img" src="../../pc-imgs/tf-study-jt-title-img.png" alt="" />
          </div>
          <div class="tf-study-jt-context">
            <div class="tf-study-jt-context-top">
              <img
                @click="consult"
                class="tf-study-jt-context-top-small"
                src="../../pc-imgs/tf-study-jt-context-top-samll1.png"
                alt=""
              />
              <img
                @click="consult"
                class="tf-study-jt-context-top-small"
                src="../../pc-imgs/tf-study-jt-context-top-samll2.png"
                alt=""
              />
              <img
                @click="consult"
                class="tf-study-jt-context-top-small"
                src="../../pc-imgs/tf-study-jt-context-top-samll3.png"
                alt=""
              />
              <img
                @click="consult"
                class="tf-study-jt-context-top-small"
                src="../../pc-imgs/tf-study-jt-context-top-samll4.png"
                alt=""
              />
            </div>
            <img class="tf-study-jt-context-bottom" src="../../pc-imgs/tf-study-jt-context-bottom.png" alt="" />
            <div class="tf-study-jt-but">
              <img class="tf-study-jt-but1" src="../../pc-imgs/test-mobile.png" alt="" @click="consult" />
              <img class="tf-study-jt-but2" src="../../pc-imgs/test-zx.png" alt="" @click="consult" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 完善的托福教学闭环服务 -->
    <img class="tf-bh-fw" src="../../pc-imgs/tf-bh-fw.png" alt="" />
    <!-- 托福学习中常见的问题 -->
    <div class="pc-tf-cj-question">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="pc-tf-cj-question-title">
            <img class="pc-tf-cj-question-title-img" src="../../pc-imgs/tf-cj-question-title-img.png" alt="" />
          </div>
          <div class="pc-tf-cj-question-context">
            <div class="pc-tf-cj-question-context-left">
              <div class="pc-tf-cj-question-context-left-small" @click="consult">
                全新申请要求汇总/解析
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-left-small" @click="consult">
                背景提升如何准备？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-left-small" @click="consult">
                如何准备文书/推荐信？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-left-small" @click="consult">
                不同专业/院校雅思要求？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-left-small" @click="consult">
                笔试面试如何准备？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
            </div>
            <div class="pc-tf-cj-question-context-middle">
              <div class="pc-tf-cj-question-context-middle-small" @click="consult">
                申请英国大学的主流方式？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-middle-small" @click="consult">
                通过严格AL课程如何申请？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-middle-small" @click="consult">
                AL成绩能申请英国大学吗？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-middle-small" @click="consult">
                如何选专业选学校？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
              <div class="pc-tf-cj-question-context-middle-small" @click="consult">
                申请规划如何准备？
                <img class="pc-tf-cj-question-but" src="../../pc-imgs/tf-cj-question-but.png" alt="" />
              </div>
            </div>
            <div class="pc-tf-cj-question-context-right">
              <img class="pc-tf-cj-question-context-wx" src="../../pc-imgs/g5-wx.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col
      ></el-row>
    </div>
    <!-- 托福报名流程很复杂 -->
    <div class="tf-bm-lc">
      <img class="tf-bm-lc" src="../../pc-imgs/tf-bm-lc.png" alt="" />
      <div class="tf-bm-lc-but1" @click="consult"></div>
      <div class="tf-bm-lc-but2" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="com-teacher">
      <img class="com-teacher-img" src="../../pc-imgs/teacher.png" alt="" />
    </div>
    <!-- 细致入微贴心服务 -->
    <div class="new-al-xz-fw">
      <el-row type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="new-al-xz-fw-title">
            <img class="new-al-xz-fw-title-img" src="../../pc-imgs/new-al-xz-fw-title.png" alt="" />
          </div>
          <div class="new-al-xz-fw-div">
            <div class="new-al-xz-fw-div1">
              <div class="new-al-xz-fw-div1-small">
                <div class="new-al-xz-fw-div1-small-text">到离校通知家长</div>
              </div>
              <div class="new-al-xz-fw-div2-small">
                <div class="new-al-xz-fw-div1-small-text">测试问题必反馈</div>
              </div>
              <div class="new-al-xz-fw-div3-small">
                <div class="new-al-xz-fw-div1-small-text">上课收手机专心学习</div>
              </div>
            </div>
            <div class="new-al-xz-fw-div1">
              <div class="new-al-xz-fw-div4-small">
                <div class="new-al-xz-fw-div1-small-text">课堂内容详细反馈全面了解</div>
              </div>
              <div class="new-al-xz-fw-div5-small">
                <div class="new-al-xz-fw-div1-small-text">学习记录实时跟踪</div>
              </div>
              <div class="new-al-xz-fw-div6-small">
                <div class="new-al-xz-fw-div1-small-text">课后任务细化快乐学习</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <!-- 校区环境 -->
    <div class="test-xq-hj">
      <img class="test-xq-hj-img" src="../../pc-imgs/home-xq-hj.png" alt="" />
    </div>
    <!--底部导航栏  -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Footer from '../../components/PcFooter.vue';
import Header from '../../components/PcHeader.vue';
import Narbar from '../../components/Narbar.vue';

export default {
  components: {
    Header,
    Narbar,
    Footer
  },
  methods: {
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.query();
    },

    submitForm() {
      const regex = /^1[3-9]\d{9}$/;
      if (!this.form.name || !this.form.mobile) {
        this.$message({
          showClose: true,
          message: '姓名和手机号必须填写',
          type: 'error'
        });
      } else if (!regex.test(this.form.mobile) && this.form.mobile) {
        this.$message({
          showClose: true,
          message: '手机号格式错误',
          type: 'error'
        });
      } else {
        const url = 'https://dabei-api.landwave.cn/api/web/outerChain/addRecord';
        let data = {
          variables: {
            ig1g5jw_maminput_3vkn: this.form.name,
            i6j2lq7_maminput_xu3u: this.form.mobile,
            formId: 'f6b9e1d75eb14657af8afdbbe6cb1669',
            id: ''
          },
          ignoreValidateFields: [],
          token: '12345'
        };
        const headers = {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'zh-CN,zh;q=0.9,en;q=0.8,zh-TW;q=0.7',
          appid: 'e1cf486d846146e18d605619e4941a70',
          'content-type': 'application/json',
          itemid: 'e1cf486d846146e18d605619e4941a70',
          'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          tenant_id: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          TenantId: 'wpwdXvCgAA8TuXXEPcGB2etDVkcnl6mA',
          token: '12345'
        };
        axios
          .post(url, data, {headers})
          .then(() => {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            });
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="less">
.tf-bj {
  padding-top: 0.352857rem;
  height: 3.714286rem /* 520/140 */;
  width: 10.285714rem /* 1440/140 */;
  cursor: pointer;
}
.tf-why-select-bh {
  height: auto;
  margin-top: 0.352857rem;
  width: 100%;
  padding-bottom: 0.214286rem /* 30/140 */;
  .tf-why-select-bh-title {
    height: 0.571429rem /* 80/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .tf-why-select-bh-title-img {
      height: 0.192857rem /* 27/140 */;
    }
  }
  .tf-why-select-bh-context {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.142857rem /* 20/140 */;
    .tf-why-select-bh-context-small {
      width: 25%;
      height: auto;
      transition: transform 0.3s ease; /* 添加过渡效果 */
    }
    .tf-why-select-bh-context-small:hover {
      transform: translateY(-0.142857rem /* 20/140 */); /* 向上浮动20像素 */
    }
  }
  .tf-why-select-bh-but {
    height: 0.285714rem /* 40/140 */;
    width: 100%;
    position: relative;
    .tf-why-select-bh-but1 {
      height: 0.257143rem /* 36/140 */;
      width: 1.285714rem /* 180/140 */;
      position: absolute;
      top: 0;
      left: 16%;
      cursor: pointer;
    }
    .tf-why-select-bh-but2 {
      height: 0.257143rem /* 36/140 */;
      width: 1.285714rem /* 180/140 */;
      position: absolute;
      top: 0;
      left: 56%;
      cursor: pointer;
    }
  }
}
.tf-study-question {
  position: relative;
  width: 100%;
  height: auto;
  .tf-study-question-but1 {
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    position: absolute;
    top: 87.6%;
    left: 35.1%;
    cursor: pointer;
    border-radius: 0.142857rem /* 20/140 */;
  }
  .tf-study-question-but2 {
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    position: absolute;
    top: 87.6%;
    left: 54.6%;
    cursor: pointer;
    border-radius: 0.142857rem /* 20/140 */;
  }
}
.tf-study-jt {
  height: auto;
  width: 100%;
  background-color: #e9f4ffff;
  padding-bottom: 0.428571rem /* 60/140 */;
  .tf-study-jt-title {
    height: 0.614286rem /* 86/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .tf-study-jt-title-img {
      height: 0.185714rem /* 26/140 */;
    }
  }
  .tf-study-jt-context {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .tf-study-jt-context-top {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      .tf-study-jt-context-top-small {
        width: 23%;
        height: auto;
        cursor: pointer;
        transition: transform 0.3s ease; /* 添加过渡效果 */
      }
      .tf-study-jt-context-top-small:hover {
        transform: translateY(-0.142857rem /* 20/140 */); /* 向上浮动20像素 */
      }
    }
    .tf-study-jt-context-bottom {
      width: 100%;
      height: 1.264286rem /* 177/140 */;
      margin-top: 0.1rem /* 14/140 */;
      margin-bottom: 0.142857rem /* 20/140 */;
    }
    .tf-study-jt-but {
      width: 100%;
      position: relative;
      .tf-study-jt-but1 {
        height: 0.257143rem /* 36/140 */;
        width: 1.285714rem /* 180/140 */;
        position: absolute;
        top: 100%;
        left: 18%;
        cursor: pointer;
      }
      .tf-study-jt-but2 {
        height: 0.257143rem /* 36/140 */;
        width: 1.285714rem /* 180/140 */;
        position: absolute;
        top: 100%;
        left: 56%;
        cursor: pointer;
      }
    }
  }
}
.tf-bh-fw {
  width: 100%;
  height: auto;
}
.pc-tf-cj-question {
  height: 2.857143rem /* 400/140 */;
  width: 100%;
  .pc-tf-cj-question-title {
    height: 0.614286rem /* 86/140 */;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .pc-tf-cj-question-title-img {
      height: 0.192857rem /* 27/140 */;
    }
  }
  .pc-tf-cj-question-context {
    height: 1.55rem /* 217/140 */;
    width: 100%;
    background-color: #e9f4ffff;
    display: flex;
    padding-top: 0.185714rem /* 26/140 */;
    padding-bottom: 0.185714rem /* 26/140 */;
    .pc-tf-cj-question-context-left {
      flex: 1.6;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-left: 0.071429rem /* 10/140 */;
      padding-right: 0.214286rem /* 30/140 */;

      .pc-tf-cj-question-context-left-small {
        display: flex;
        height: 0.157143rem /* 22/140 */;
        font-size: 0.1rem /* 14/140 */;
        line-height: 0.157143rem /* 22/140 */;
        justify-content: space-between;
        cursor: pointer;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 0.1rem /* 14/140 */;
        color: #333333;
        .pc-tf-cj-question-but {
          height: 0.157143rem /* 22/140 */;
        }
      }
      .pc-tf-cj-question-context-left-small:hover {
        color: #017effff;
      }
    }

    .pc-tf-cj-question-context-middle {
      flex: 1.7;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-right: 0.107143rem /* 15/140 */;
      .pc-tf-cj-question-context-middle-small {
        display: flex;
        height: 0.157143rem /* 22/140 */;
        font-size: 0.1rem /* 14/140 */;
        line-height: 0.157143rem /* 22/140 */;
        justify-content: space-between;
        cursor: pointer;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 0.1rem /* 14/140 */;
        color: #333333;
        .pc-tf-cj-question-but {
          height: 0.157143rem /* 22/140 */;
        }
      }
      .pc-tf-cj-question-context-middle-small:hover {
        color: #017effff;
      }
    }
    .pc-tf-cj-question-context-right {
      flex: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
      .pc-tf-cj-question-context-wx {
        height: 1.214286rem /* 170/140 */;
      }
    }
  }
}
.tf-bm-lc {
  height: auto;
  width: 100%;
  position: relative;
  .tf-bm-lc-but1 {
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    position: absolute;
    top: 82.7%;
    left: 35%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
  .tf-bm-lc-but2 {
    height: 0.257143rem /* 36/140 */;
    width: 1.285714rem /* 180/140 */;
    position: absolute;
    top: 82%;
    left: 54.4%;
    border-radius: 0.142857rem /* 20/140 */;
    cursor: pointer;
  }
}
.test-xq-hj {
  height: 1.807143rem /* 253/140 */;
  display: flex;
  justify-content: center;

  .test-xq-hj-img {
    height: 1.592857rem /* 223/140 */;
  }
}
.new-al-xz-fw {
  height: 4.471429rem /* 626/140 */;
  width: 100%;
  font-size: 0.114286rem /* 16/140 */;
  .new-al-xz-fw-title {
    height: 0.642857rem /* 90/140 */;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-al-xz-fw-title-img {
    height: 0.221429rem /* 31/140 */;
  }
  .new-al-xz-fw-div {
    height: 3.571429rem /* 500/140 */;
    width: 100%;
    .new-al-xz-fw-div1 {
      display: flex;
      width: 100%;
      height: 1.75rem /* 245/140 */;
      margin-bottom: 0.057143rem /* 8/140 */;
      .new-al-xz-fw-div1-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img1.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .new-al-xz-fw-div1-small:hover {
        color: #57a0eeff;
      }
      .new-al-xz-fw-div2-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img2.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .new-al-xz-fw-div2-small:hover {
        color: #57a0eeff;
      }
      .new-al-xz-fw-div3-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img3.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 23%;
        }
      }
      .new-al-xz-fw-div3-small:hover {
        color: #57a0eeff;
      }
      .new-al-xz-fw-div4-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img4.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 11%;
        }
      }
      .new-al-xz-fw-div4-small:hover {
        color: #57a0eeff;
      }
      .new-al-xz-fw-div5-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img5.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 26%;
        }
      }
      .new-al-xz-fw-div5-small:hover {
        color: #57a0eeff;
      }
      .new-al-xz-fw-div6-small {
        position: relative;
        flex: 1;
        height: 1.75rem /* 245/140 */;
        background-image: url(../../pc-imgs/new-al-xz-fw-img6.png);
        background-repeat: no-repeat;
        background-size: cover;
        .new-al-xz-fw-div1-small-text {
          position: absolute;
          top: 84%;
          left: 21%;
        }
      }
      .new-al-xz-fw-div6-small:hover {
        color: #57a0eeff;
      }
    }
  }
}
</style>
