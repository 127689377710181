<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <!-- 背景图 -->
    <div class="g5-bg">
      <img class="g5-bg" src="../../assets/g5-bj.png" alt="" @click="consult" />
    </div>
    <!-- 英国g5超级精英大学盘点 -->
    <div class="g5-jy">
      <img class="g5-jy-title" src="../../assets/g5-jy-title.png" alt="" />
      <van-swipe class="g5-jy-swp" autoplay="3000">
        <van-swipe-item>
          <img class="g5-jy-swp-item" src="../../assets/g5-jy-img1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="g5-jy-swp-item" src="../../assets/g5-jy-img2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="g5-jy-swp-item" src="../../assets/g5-jy-img3.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="g5-jy-swp-item" src="../../assets/g5-jy-img4.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="g5-jy-swp-item" src="../../assets/g5-jy-img5.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- G5大学申请条件和优势专业 -->
    <div class="g5-dx-sqtj">
      <img class="g5-dx-sqtj-title" src="../../assets/g5-dx-sqtj-title.png" alt="" />
      <div class="g5-dx-sqtj-but">
        <div class="g5-dx-sqtj-but-top">
          <div
            @click="sqFun1"
            class="g5-dx-sqtj-but-top-button"
            :style="{backgroundColor: sq1 ? '#144eedFF' : '', color: sq1 ? '#fff' : '#144eedFF'}"
          >
            剑桥大学
          </div>
          <div
            @click="sqFun2"
            class="g5-dx-sqtj-but-top-button"
            :style="{backgroundColor: sq2 ? '#144eedFF' : '', color: sq2 ? '#fff' : '#144eedFF'}"
          >
            牛津大学
          </div>
          <div
            @click="sqFun3"
            class="g5-dx-sqtj-but-top-button"
            :style="{backgroundColor: sq3 ? '#144eedFF' : '', color: sq3 ? '#fff' : '#144eedFF'}"
          >
            伦敦大学学院
          </div>
        </div>
        <div class="g5-dx-sqtj-but-bottom">
          <div
            @click="sqFun4"
            class="g5-dx-sqtj-but-bottom-button"
            :style="{backgroundColor: sq4 ? '#144eedFF' : '', color: sq4 ? '#fff' : '#144eedFF'}"
          >
            帝国理工学院
          </div>
          <div
            @click="sqFun5"
            class="g5-dx-sqtj-but-bottom-button"
            :style="{backgroundColor: sq5 ? '#144eedFF' : '', color: sq5 ? '#fff' : '#144eedFF'}"
          >
            伦敦政经学院
          </div>
        </div>
      </div>
      <img v-show="sq1" class="g5-sq-img1" src="../../assets/g5-sq-img1.png" alt="" />
      <img v-show="sq2" class="g5-sq-img1" src="../../assets/g5-sq-img2.png" alt="" />
      <img v-show="sq3" class="g5-sq-img1" src="../../assets/g5-sq-img3.png" alt="" />
      <img v-show="sq4" class="g5-sq-img1" src="../../assets/g5-sq-img4.png" alt="" />
      <img v-show="sq5" class="g5-sq-img1" src="../../assets/g5-sq-img5.png" alt="" />
      <div class="g5-dx-sqtj-bottom-but-div">
        <img class="g5-mobile-button" src="../../assets/g5-mobile-button.png" alt="" @click="callMobile" />
        <img class="g5-mobile-button" src="../../assets/g5-zx-button.png" alt="" @click="consult" />
      </div>
    </div>
    <!-- G5大学申请必看四大法则 -->
    <div class="g5-fz">
      <img class="g5-fz" src="../../assets/g5-fz.png" alt="" />
      <div class="g5-fz-but1" @click="callMobile"></div>
      <div class="g5-fz-but2" @click="consult"></div>
    </div>
    <!-- 背景提升热门竞赛 -->
    <div class="g5-js">
      <img class="g5-js-img" src="../../assets/g5-js.png" alt="" />
      <div class="g5-js-but1" @click="callMobile"></div>
      <div class="g5-js-but2" @click="consult"></div>
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 英国大学时间规划线 -->
    <div class="g5-ygdx-plan">
      <img class="g5-dx-plan" src="../../assets/g5-dx-plan.png" alt="" />
      <div class="g5-dx-plan-button">
        <div
          @click="planFun1"
          class="g5-dx-plan-button1"
          :style="{backgroundColor: plan1 ? '#144eedFF' : '', color: plan1 ? '#fff' : '#144eedFF'}"
        >
          本科申请规划
        </div>
        <div
          @click="planFun2"
          class="g5-dx-plan-button2"
          :style="{backgroundColor: plan2 ? '#144eedFF' : '', color: plan2 ? '#fff' : '#144eedFF'}"
        >
          研究生申请规划
        </div>
      </div>
      <img v-show="plan1" class="g5-paln-img" src="../../assets/g5-paln-img1.png" alt="" />
      <img v-show="plan2" class="g5-paln-img" src="../../assets/g5-paln-img2.png" alt="" />
      <div class="g5-dx-plan-but-div">
        <img class="g5-mobile-button-plan1" src="../../assets/g5-mobile-button.png" alt="" @click="callMobile" />
        <img class="g5-mobile-button-plan2" src="../../assets/g5-zx-button.png" alt="" @click="consult" />
      </div>
    </div>
    <!-- 申请热门专业分布 -->
    <div class="g5-rm">
      <img class="g5-rm" src="../../assets/g5-rmzy.png" alt="" />
      <div class="g5-mobile-button-rm" @click="callMobile"></div>
      <div class="g5-zx-button-rm" @click="consult"></div>
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      showRouter: false,
      show: false,
      sq1: true,
      sq2: false,
      sq3: false,
      sq4: false,
      sq5: false,
      plan1: true,
      plan2: false,
      numberToCopy: 19821616277
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    sqFun1() {
      this.sq1 = true;
      this.sq2 = false;
      this.sq3 = false;
      this.sq4 = false;
      this.sq5 = false;
    },
    sqFun2() {
      this.sq1 = false;
      this.sq2 = true;
      this.sq3 = false;
      this.sq4 = false;
      this.sq5 = false;
    },
    sqFun3() {
      this.sq1 = false;
      this.sq2 = false;
      this.sq3 = true;
      this.sq4 = false;
      this.sq5 = false;
    },
    sqFun4() {
      this.sq1 = false;
      this.sq2 = false;
      this.sq3 = false;
      this.sq4 = true;
      this.sq5 = false;
    },
    sqFun5() {
      this.sq1 = false;
      this.sq2 = false;
      this.sq3 = false;
      this.sq4 = false;
      this.sq5 = true;
    },
    planFun1() {
      this.plan1 = true;
      this.plan2 = false;
    },
    planFun2() {
      this.plan1 = false;
      this.plan2 = true;
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.g5-bg {
  height: 11.081081rem /* 410/37 */;
  width: 10.135135rem /* 375/37 */;
}
.g5-jy {
  width: 10.135135rem /* 375/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.810811rem /* 30/37 */;

  .g5-jy-title {
    width: 9.324324rem /* 345/37 */;
    height: 3.783784rem /* 140/37 */;
    margin-bottom: 0.459459rem /* 17/37 */;
  }
  .g5-jy-swp {
    width: 9.540541rem /* 353/37 */;
    padding-left: 0.27027rem /* 10/37 */;
    height: 7.837838rem /* 290/37 */;
  }
  .van-swipe__indicator {
    height: 0.27027rem /* 10/37 */ !important;
    width: 0.540541rem /* 20/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: rgb(213, 214, 215) !important;
  }
  .van-swipe__indicator--active {
    height: 0.27027rem /* 10/37 */ !important;
    width: 1.351351rem /* 50/37 */ !important;
    border-radius: 0.540541rem /* 20/37 */ !important;
    background-color: #fec235ff !important;
  }
  .g5-jy-swp-item {
    width: 9.324324rem /* 345/37 */;
    height: 7.054054rem /* 261/37 */;
  }
}
.g5-dx-sqtj {
  width: 10.135135rem /* 375/37 */;
  background-color: rgb(237, 239, 243);
  display: flex;
  flex-direction: column;
  padding-top: 0.27027rem /* 10/37 */;
  .g5-dx-sqtj-title {
    width: 6.351351rem /* 235/37 */;
    height: 0.621622rem /* 23/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
    margin-left: 1.864865rem /* 69/37 */;
  }

  .g5-dx-sqtj-but {
    .g5-dx-sqtj-but-top {
      display: flex;
      justify-content: space-between;
      padding-left: 0.405405rem /* 15/37 */;
      padding-right: 0.405405rem /* 15/37 */;
      .g5-dx-sqtj-but-top-button {
        width: 2.864865rem /* 106/37 */;
        height: 0.702703rem /* 26/37 */;
        border-radius: 0.540541rem /* 20/37 */;
        color: #144eedff;
        border: 0.027027rem /* 1/37 */ solid #144eedff;
        font-size: 0.324324rem /* 12/37 */;
        text-align: center;
        line-height: 0.702703rem /* 26/37 */;
        margin-bottom: 0.27027rem /* 10/37 */;
      }
    }
    .g5-dx-sqtj-but-bottom {
      display: flex;
      justify-content: space-between;
      padding-left: 1.891892rem /* 70/37 */;
      padding-right: 1.891892rem /* 70/37 */;
    }
    .g5-dx-sqtj-but-bottom-button {
      width: 2.864865rem /* 106/37 */;
      height: 0.702703rem /* 26/37 */;
      border-radius: 0.540541rem /* 20/37 */;
      color: #144eedff;
      border: 0.027027rem /* 1/37 */ solid #144eedff;
      font-size: 0.324324rem /* 12/37 */;
      text-align: center;
      line-height: 0.702703rem /* 26/37 */;
      margin-bottom: 0.27027rem /* 10/37 */;
    }
  }
}
.g5-sq-img1 {
  width: 9.324324rem /* 345/37 */;
  margin-left: 0.405405rem /* 15/37 */;
}
.g5-dx-sqtj-bottom-but-div {
  height: 2.324324rem /* 86/37 */;
  padding-left: 0.675676rem /* 25/37 */;
  padding-right: 0.540541rem /* 20/37 */;
  display: flex;

  align-items: center;
  .g5-mobile-button {
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    margin-right: 0.810811rem /* 30/37 */;
  }
}
.g5-fz {
  position: relative;
  width: 10.135135rem /* 375/37 */;
  height: 42.486486rem /* 1572/37 */;
}
.g5-fz-but1 {
  position: absolute;
  top: 44.5%;
  left: 7%;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  border-radius: 0.540541rem /* 20/37 */;
}
.g5-fz-but2 {
  position: absolute;
  top: 44.5%;
  left: 53%;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  border-radius: 0.540541rem /* 20/37 */;
}
.g5-js {
  position: relative;
  height: 11.891892rem /* 440/37 */;
  padding-left: 0.540541rem /* 20/37 */;
  display: flex;
  align-items: center;
  background-color: rgb(237, 239, 243);
  .g5-js-img {
    width: 9.324324rem /* 345/37 */;
    height: 10.243243rem /* 379/37 */;
  }
  .g5-js-but1 {
    position: absolute;
    top: 85%;
    left: 7.8%;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
  }
  .g5-js-but2 {
    position: absolute;
    top: 85%;
    left: 55%;
    width: 4.054054rem /* 150/37 */;
    height: 0.972973rem /* 36/37 */;
    border-radius: 0.540541rem /* 20/37 */;
  }
}
.g5-ygdx-plan {
  width: 10.135135rem /* 375/37 */;
  background-color: #f1f5ffff;
  display: flex;
  align-items: center;
  padding-top: 0.540541rem /* 20/37 */;
  justify-content: center;
  flex-direction: column;
  .g5-dx-plan {
    width: 6rem /* 222/37 */;
    height: 1.243243rem /* 46/37 */;
  }
  .g5-dx-plan-button {
    height: 1.081081rem /* 40/37 */;
    padding-bottom: 0.540541rem /* 20/37 */;
    display: flex;
    padding-left: 1rem /* 37/37 */;
    padding-right: 0.810811rem /* 30/37 */;
    justify-content: space-between;
  }
  .g5-dx-plan-button1 {
    width: 3.783784rem /* 140/37 */;
    height: 0.810811rem /* 30/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    border: 0.027027rem /* 1/37 */ solid #265ceeff;
    color: #265ceeff;
    font-size: 0.378378rem /* 14/37 */;
    line-height: 0.810811rem /* 30/37 */;
    text-align: center;
    margin-top: 0.540541rem /* 20/37 */;
    margin-right: 0.540541rem /* 20/37 */;
  }
  .g5-dx-plan-button2 {
    width: 3.783784rem /* 140/37 */;
    height: 0.810811rem /* 30/37 */;
    border-radius: 0.540541rem /* 20/37 */;
    border: 0.027027rem /* 1/37 */ solid #265ceeff;
    color: #265ceeff;
    font-size: 0.378378rem /* 14/37 */;
    line-height: 0.810811rem /* 30/37 */;
    text-align: center;
    margin-top: 0.540541rem /* 20/37 */;
  }
}
.g5-paln-img {
  width: 9.378378rem /* 347/37 */;
  margin-top: 0.540541rem /* 20/37 */;
}
.g5-dx-plan-but-div {
  height: 2.162162rem /* 80/37 */;
  display: flex;
  align-items: center;
}
.g5-mobile-button-plan1 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  margin-right: 0.810811rem /* 30/37 */;
}
.g5-mobile-button-plan2 {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
}
.g5-rm {
  position: relative;
  height: 11.027027rem /* 408/37 */;
}
.g5-mobile-button-rm {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  top: 86.5%;
  left: 6.8%;
}
.g5-zx-button-rm {
  position: absolute;
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
  border-radius: 0.540541rem /* 20/37 */;
  top: 86.5%;
  left: 53.5%;
}
</style>
