<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <!-- 背景图 -->
    <div class="sy-bg">
      <img class="sy-bg" src="../../assets/sy-bj.png" alt="" @click="consult" />
    </div>
    <!-- 导航栏 -->
    <div class="sy-dh">
      <div class="sy-dh-top">
        <img class="sy-dh-img" src="../../assets/sy-dh-img1.png" alt="" @click="toAllDay" />
        <img class="sy-dh-img2" src="../../assets/sy-dh-img2.png" alt="" @click="toAl" />
        <img class="sy-dh-img3" src="../../assets/sy-dh-img3.png" alt="" @click="toIg" />
        <img class="sy-dh-img3" src="../../assets/sy-dh-img4.png" alt="" @click="toG5" />
        <img class="sy-dh-img3" src="../../assets/sy-dh-img5.png" alt="" @click="toIb" />
      </div>
      <div class="sy-dh-bottom">
        <img class="sy-dh-img3-bottom" src="../../assets/sy-dh-img6.png" alt="" @click="toAp" />
        <img class="sy-dh-img4-bottom" src="../../assets/sy-dh-img7.png" alt="" @click="toCom" />
        <img class="sy-dh-img3-bottom" src="../../assets/sy-dh-img8.png" alt="" @click="toYs" />
        <img class="sy-dh-img3-bottom" src="../../assets/sy-dh-img9.png" alt="" @click="toTf" />
        <img class="sy-dh-img4-bottom" src="../../assets/sy-dh-img10.png" alt="" @click="toAbout" />
      </div>
    </div>
    <!-- 关于半海 -->
    <div class="sy-about-bh">
      <img class="sy-about-bh-img" src="../../assets/sy-about-bh.png" alt="" />
    </div>
    <!-- 了解更多关于半海 -->
    <div class="sy-more-about-bh">
      <img class="sy-but1" src="../../assets/sy-but1.png" alt="" @click="consult" />
    </div>
    <!-- 我们提供哪些服务 -->
    <div class="sy-tg-fw">
      <img class="sy-tg-fw-title" src="../../assets/sy-tg-fw-title.png" alt="" />
      <div class="sy-tg-fw-context">
        <div class="sy-tg-fw-context-button">
          <div class="sy-tg-fw-context-button-top">
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw1 ? '#fdc526FF' : '', color: tgFw1 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun1"
            >
              A-Level课程
            </div>
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw2 ? '#fdc526FF' : '', color: tgFw2 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun2"
            >
              IGCSE课程
            </div>
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw3 ? '#fdc526FF' : '', color: tgFw3 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun3"
            >
              IB课程
            </div>
          </div>
          <div class="sy-tg-fw-context-button-bottom">
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw4 ? '#fdc526FF' : '', color: tgFw4 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun4"
            >
              AP课程
            </div>
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw5 ? '#fdc526FF' : '', color: tgFw5 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun5"
            >
              国际竞赛
            </div>
            <div
              class="sy-tg-fw-context-button-but"
              :style="{backgroundColor: tgFw6 ? '#fdc526FF' : '', color: tgFw6 ? '#fff' : '#fdc526FF'}"
              @click="tgFwFun6"
            >
              英国本科申请
            </div>
          </div>
        </div>
        <img v-show="tgFw1" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom1.png" alt="" />
        <img v-show="tgFw2" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom2.png" alt="" />
        <img v-show="tgFw3" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom3.png" alt="" />
        <img v-show="tgFw4" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom4.png" alt="" />
        <img v-show="tgFw5" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom5.png" alt="" />
        <img v-show="tgFw6" class="sy-tg-fw-context-bottom" src="../../assets/sy-tg-fw-context-bottom6.png" alt="" />
      </div>
    </div>
    <!-- 教学团队 -->
    <div class="ig-teacher">
      <img class="ig-teacher-logo" src="../../assets/teacher-logo.png" alt="" />
      <van-swipe class="ig-teacher-swp" autoplay="3000">
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="teacher-swp-item-ig" src="../../assets/teacher3.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 半海教育优势 -->
    <img class="sy-bh-ys" src="../../assets/sy-bh-ys.png" alt="" />
    <!-- 细致入微服务 -->
    <img class="sy-xz-fw" src="../../assets/sy-xz-fw.png" alt="" />
    <!-- 按钮 -->
    <div class="sy-but">
      <img class="sy-mobile-but" src="../../assets/sy-mobile-but.png" alt="" @click="callMobile" />
      <img class="sy-zx-but" src="../../assets/sy-zx-but.png" alt="" @click="consult" />
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>
    <Popup :show="show" @showFun="showFun"></Popup>
    <er></er>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';

import {Toast} from 'vant';
export default {
  components: {
    Header,
    Popup,
    RouterNarbar
  },
  data() {
    return {
      showRouter: false,
      show: false,
      numberToCopy: 19821616277,
      tgFw1: true,
      tgFw2: false,
      tgFw3: false,
      tgFw4: false,
      tgFw5: false,
      tgFw6: false
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    tgFwFun1() {
      this.tgFw1 = true;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun2() {
      this.tgFw1 = false;
      this.tgFw2 = true;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun3() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = true;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun4() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = true;
      this.tgFw5 = false;
      this.tgFw6 = false;
    },
    tgFwFun5() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = true;
      this.tgFw6 = false;
    },
    tgFwFun6() {
      this.tgFw1 = false;
      this.tgFw2 = false;
      this.tgFw3 = false;
      this.tgFw4 = false;
      this.tgFw5 = false;
      this.tgFw6 = true;
    },
    toAllDay() {
      this.$router.push('/allday');
    },
    toAl() {
      this.$router.push('/al');
    },
    toIg() {
      this.$router.push('/ig');
    },
    toG5() {
      this.$router.push('/g5');
    },
    toIb() {
      this.$router.push('/ib');
    },
    toAp() {
      this.$router.push('/ap');
    },
    toCom() {
      this.$router.push('/competition');
    },
    toYs() {
      this.$router.push('/english');
    },
    toTf() {
      this.$router.push('/language');
    },
    toAbout() {
      this.$router.push('/about');
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style lang="less">
.sy-bg {
  width: 10.135135rem /* 375/37 */;
  height: 11.081081rem /* 410/37 */;
}
.sy-dh {
  height: 3.918919rem /* 145/37 */;
  padding: 0.675676rem /* 25/37 */ 0.513514rem /* 19/37 */ 0.540541rem /* 20/37 */ 0.513514rem /* 19/37 */;
  .sy-dh-top {
    height: 1.621622rem /* 60/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
    display: flex;
    justify-content: space-between;
    .sy-dh-img {
      width: 1.405405rem /* 52/37 */;
      height: 1.567568rem /* 58/37 */;
    }
    .sy-dh-img2 {
      width: 1.162162rem /* 43/37 */;
      height: 1.567568rem /* 58/37 */;
    }
    .sy-dh-img3 {
      width: 1.081081rem /* 40/37 */;
      height: 1.567568rem /* 58/37 */;
    }
  }
  .sy-dh-bottom {
    height: 1.621622rem /* 60/37 */;
    display: flex;
    justify-content: space-between;

    .sy-dh-img3-bottom {
      padding-left: 0.27027rem /* 10/37 */;
      width: 1.081081rem /* 40/37 */;
      height: 1.567568rem /* 58/37 */;
    }
    .sy-dh-img4-bottom {
      padding-left: 0.27027rem /* 10/37 */;
      width: 1.297297rem /* 48/37 */;
      height: 1.567568rem /* 58/37 */;
    }
  }
}
.sy-about-bh {
  display: flex;
  justify-content: center;
  height: 7.945946rem /* 294/37 */;
  width: 10.135135rem /* 375/37 */;
  .sy-about-bh-img {
    width: 9.702703rem /* 359/37 */;
    height: 7.945946rem /* 294/37 */;
  }
}
.sy-more-about-bh {
  height: 1.891892rem /* 70/37 */;
  display: flex;
  justify-content: center;
  .sy-but1 {
    width: 5.405405rem /* 200/37 */;
    height: 0.810811rem /* 30/37 */;
    margin-top: 0.540541rem /* 20/37 */;
  }
}
.sy-tg-fw {
  background-color: #60b36dff;
  width: 10.135135rem /* 375/37 */;
  padding-top: 0.540541rem /* 20/37 */;
  padding-bottom: 0.540541rem /* 20/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sy-tg-fw-title {
    width: 7.486486rem /* 277/37 */;
    height: 1.702703rem /* 63/37 */;
    margin-bottom: 0.540541rem /* 20/37 */;
  }
  .sy-tg-fw-context {
    width: 9.324324rem /* 345/37 */;
    padding-top: 0.540541rem /* 20/37 */;
    padding-bottom: 0.540541rem /* 20/37 */;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sy-tg-fw-context-button {
      height: 2.648649rem /* 98/37 */;
      width: 8.783784rem /* 325/37 */;
      .sy-tg-fw-context-button-top {
        display: flex;
        height: 0.810811rem /* 30/37 */;
        justify-content: space-between;
        margin-bottom: 0.27027rem /* 10/37 */;
        .sy-tg-fw-context-button-but {
          width: 2.702703rem /* 100/37 */;
          height: 0.810811rem /* 30/37 */;
          border: 0.027027rem /* 1/37 */ solid #fca934ff;
          font-size: 0.324324rem /* 12/37 */;
          text-align: center;
          line-height: 0.810811rem /* 30/37 */;
        }
      }
      .sy-tg-fw-context-button-bottom {
        display: flex;
        height: 0.810811rem /* 30/37 */;
        justify-content: space-between;
      }
      .sy-tg-fw-context-button-but {
        width: 2.702703rem /* 100/37 */;
        height: 0.810811rem /* 30/37 */;
        border: 0.027027rem /* 1/37 */ solid #fca934ff;
        font-size: 0.324324rem /* 12/37 */;
        text-align: center;
        line-height: 0.810811rem /* 30/37 */;
      }
    }
    .sy-tg-fw-context-bottom {
      width: 8.432432rem /* 312/37 */;
    }
  }
}
.sy-bh-ys {
  width: 10.135135rem /* 375/37 */;
  height: 14.513514rem /* 537/37 */;
}
.sy-xz-fw {
  width: 10.135135rem /* 375/37 */;
  height: 18rem /* 666/37 */;
}
.sy-but {
  height: 1.783784rem /* 66/37 */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.675676rem /* 25/37 */;
  padding-right: 0.675676rem /* 25/37 */;
}
.sy-mobile-but {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
}
.sy-zx-but {
  width: 4.054054rem /* 150/37 */;
  height: 0.972973rem /* 36/37 */;
}
</style>
