<template>
  <div class="total">
    <!-- 顶部logo -->
    <div class="navbar">
      <Header @showPopupFun="showPopupFun"></Header>
    </div>
    <!-- 导航栏 -->
    <div class="router-fixede">
      <RouterNarbar></RouterNarbar>
    </div>
    <!-- 背景图 -->
    <div class="about-bg">
      <img class="about-bg" src="../../assets/about-img1.png" alt="" />
    </div>
    <!-- 我们的特色 -->
    <div class="about-ts">
      <img class="about-ts-img" src="../../assets/about-ts.png" alt="" />
    </div>
    <!-- 联系我们 -->
    <div class="about-lx">
      <div class="about-lx-our">联系我们</div>
      <div class="about-lx-dz" v-show="aboutLx === aboutLx1">
        <img class="about-dz" src="../../assets/about-dz1.png" alt="" />
        <img class="about-dt" src="../../assets/about-dt1.png" alt="" />
        <img class="about-left" src="../../assets/about-left.png" alt="" @click="toNext1" />
        <img class="about-right" src="../../assets/about-right1.png" alt="" @click="todown1" />
      </div>
      <div class="about-lx-dz" v-show="aboutLx === aboutLx2">
        <img class="about-dz" src="../../assets/about-dz2.png" alt="" />
        <img class="about-dt" src="../../assets/about-dt2.png" alt="" />
        <img class="about-left" src="../../assets/about-left.png" alt="" @click="toNext2" />
        <img class="about-right" src="../../assets/about-right1.png" alt="" @click="todown2" />
      </div>
      <div class="about-lx-dz" v-show="aboutLx === aboutLx3">
        <img class="about-dz" src="../../assets/about-dz3.png" alt="" />
        <img class="about-dt" src="../../assets/about-dt3.png" alt="" />
        <img class="about-left" src="../../assets/about-left.png" alt="" @click="toNext3" />
        <img class="about-right" src="../../assets/about-right1.png" alt="" @click="todown3" />
      </div>
      <div class="about-lx-dz" v-show="aboutLx === aboutLx4">
        <img class="about-dz" src="../../assets/about-dz4.png" alt="" />
        <img class="about-dt" src="../../assets/about-dt4.png" alt="" />
        <img class="about-left" src="../../assets/about-left.png" alt="" @click="toNext4" />
        <img class="about-right" src="../../assets/about-right1.png" alt="" @click="todown4" />
      </div>
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <div>上海半海教育科技有限公司，沪ICP备2023021288号-1</div>
    </div>

    <Popup :show="show" @showFun="showFun"></Popup>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Popup from '../../components/Popup.vue';
import RouterNarbar from '../../components/RouterNarbar.vue';
import {Toast} from 'vant';
export default {
  components: {Header, Popup, RouterNarbar},
  data() {
    return {
      showRouter: false,
      show: false,
      numberToCopy: 19821616277,
      aboutLx: 'aboutLx1',
      aboutLx1: 'aboutLx1',
      aboutLx2: 'aboutLx2',
      aboutLx3: 'aboutLx3',
      aboutLx4: 'aboutLx4'
    };
  },

  methods: {
    showPopupFun(value) {
      this.show = value;
    },
    showFun(value) {
      this.show = value;
    },
    consult() {
      const $53 = window.$53;
      let api = $53.createApi();
      api.push('cmd', 'kfclient');
      api.push('type', 'popup');
      api.query();
    },
    callMobile() {
      const phoneNumber = '4000685018';
      // 使用<a>标签创建电话链接
      const phoneLink = document.createElement('a');
      phoneLink.href = 'tel:' + phoneNumber;

      // 模拟点击这个链接
      document.body.appendChild(phoneLink);
      phoneLink.click();
      document.body.removeChild(phoneLink);
    },
    copyToClipboard() {
      // 获取输入框元素
      const inputElement = this.$refs.numberInput;
      // 选择输入框内容
      inputElement.select();
      // 将选择的内容复制到剪贴板
      document.execCommand('copy');
      // 取消选择
      window.getSelection().removeAllRanges();
      // 在实际应用中，你可能想要给用户提供一些反馈，表明复制已成功
      Toast.success('复制成功');
    },
    toNext1() {
      this.aboutLx = this.aboutLx2;
    },
    todown1() {
      this.aboutLx = this.aboutLx4;
    },
    toNext2() {
      this.aboutLx = this.aboutLx3;
    },
    todown2() {
      this.aboutLx = this.aboutLx1;
    },
    toNext3() {
      this.aboutLx = this.aboutLx4;
    },
    todown3() {
      this.aboutLx = this.aboutLx2;
    },
    toNext4() {
      this.aboutLx = this.aboutLx1;
    },
    todown4() {
      this.aboutLx = this.aboutLx3;
    }
    // console.log(this.$store.state.user, 'store');
  }
};
</script>

<style>
.about-bg {
  width: 10.135135rem /* 375/37 */;
  height: 22.27027rem /* 824/37 */;
}
.about-ts {
  height: 21.027027rem /* 778/37 */;
  width: 10.135135rem /* 375/37 */;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-ts-img {
  height: 19.405405rem /* 718/37 */;
  width: 9.324324rem /* 345/37 */;
}
.about-lx {
  width: 9.324324rem /* 345/37 */;
  height: 8.378378rem /* 310/37 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.540541rem /* 20/37 */;
  padding-top: 0.27027rem /* 10/37 */;
}
.about-lx-our {
  font-size: 0.486486rem /* 18/37 */;
  font-family: HelloFont, HelloFont;
  font-weight: bold;
  color: #333333;
}
.about-lx-dz {
  position: relative;
  width: 9.324324rem /* 345/37 */;
  height: 8.189189rem /* 303/37 */;
  display: flex;
  flex-direction: column;
}

.about-dz {
  position: absolute;
  width: 8.513514rem /* 315/37 */;
  height: 2.216216rem /* 82/37 */;
  margin-bottom: 0.432432rem /* 16/37 */;
  left: 54%;
  transform: translateX(-50%);
}
.about-dt {
  position: absolute;
  width: 8.540541rem /* 316/37 */;
  height: 4.540541rem /* 168/37 */;
  top: 35%;
  left: 54%;
  transform: translateX(-50%);
}
.about-left {
  position: absolute;
  width: 1.135135rem /* 42/37 */;
  height: 1.135135rem /* 42/37 */;
  top: 55%;
  left: 3%;
}
.about-right {
  position: absolute;
  width: 1.135135rem /* 42/37 */;
  height: 1.135135rem /* 42/37 */;
  top: 55%;
  left: 92%;
}
</style>
