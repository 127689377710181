import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/index.vue';
import IG from '../views/ig/index.vue';
import AL from '../views/al/index.vue';
import G5 from '../views/g5/index.vue';
import AllDay from '../views/allday/index.vue';
import AP from '../views/ap/index.vue';
import IB from '../views/ib/index.vue';
import English from '../views/english/index.vue';
import Language from '../views/language/index.vue';
import Competition from '../views/competition/index.vue';
import About from '../views/about/index.vue';
import Material from '../views/material/index.vue';
import PcHome from '../views/pc-home/index.vue';
import PcAl from '../views/pc-al/index.vue';
import PcAllday from '../views/pc-allday/index.vue';
import PcIg from '../views/pc-ig/index.vue';
import PcG5 from '../views/pc-g5/index.vue';
import PcIb from '../views/pc-ib/index.vue';
import PcAp from '../views/pc-ap/index.vue';
import PcCompetition from '../views/pc-competition/index.vue';
import PcTf from '../views/pc-tf/index.vue';
import PcYs from '../views/pc-ys/index.vue';
import PcAbout from '../views/pc-about/index.vue';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/pc',
    component: PcHome
  },
  {
    path: '/pc/al',
    component: PcAl
  },
  {
    path: '/pc/allday',
    component: PcAllday
  },
  {
    path: '/pc/ig',
    component: PcIg
  },
  {
    path: '/pc/g5',
    component: PcG5
  },
  {
    path: '/pc/ib',
    component: PcIb
  },
  {
    path: '/pc/ap',
    component: PcAp
  },
  {
    path: '/pc/competition',
    component: PcCompetition
  },
  {
    path: '/pc/tf',
    component: PcTf
  },
  {
    path: '/pc/ys',
    component: PcYs
  },
  {
    path: '/pc/about',
    component: PcAbout
  },

  {
    path: '/al',
    component: AL
  },
  {
    path: '/ig',
    component: IG
  },
  {
    path: '/g5',
    component: G5
  },
  {
    path: '/allday',
    component: AllDay
  },
  {
    path: '/ap',
    component: AP
  },
  {
    path: '/ib',
    component: IB
  },
  {
    path: '/english',
    component: English
  },
  {
    path: '/language',
    component: Language
  },
  {
    path: '/competition',
    component: Competition
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/material',
    component: Material
  }
];

const router = new VueRouter({
  routes
}); // 导航守卫，每次切换页面前都会执行
router.beforeEach((to, from, next) => {
  // 滚动到页面顶部
  window.scrollTo(0, 0);
  next();
});

export default router;
